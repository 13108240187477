import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { ApiError, getExchangeTickerData } from '@/apiServices';

function useGetExchangeTickerData(exchangeName) {
  const { showAlert } = useContext(ErrorContext);
  const [tickerData, setTickerData] = useState({});
  const [tickerDataLoading, setTickerDataLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setTickerDataLoading(true);
      try {
        const result = await getExchangeTickerData({
          exchangeName,
        });
        const tickerDataMap = result.reduce((acc, ticker) => {
          acc[ticker.pair] = ticker;
          return acc;
        }, {});
        setTickerData(tickerDataMap);
      } catch (e) {
        if (e instanceof ApiError) {
          showAlert({
            severity: 'error',
            message: `Failed to fetch exchange ticker data: ${e.message}`,
          });
        }
      } finally {
        setTickerDataLoading(false);
      }
    };

    fetchData();
  }, [exchangeName]);

  return { tickerData, tickerDataLoading };
}

export default useGetExchangeTickerData;
