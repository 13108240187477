import React, { useState, useContext } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabScrollButton from '@mui/material/TabScrollButton';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Star } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { set } from 'lodash';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ErrorContext } from '../../../shared/context/ErrorProvider';

const MyTabScrollButton = styled(TabScrollButton)({
  '&.Mui-disabled': {
    width: 0,
  },
  overflow: 'hidden',
  transition: 'width 0.5s',
  width: 28,
});

function FavoritePairTabs({ FormAtoms, exchangeName }) {
  const [favouritePairs, setFavouritePairs] = useAtom(FormAtoms.favouritePairsAtom);
  const [selectedPair, setSelectedPair] = useAtom(FormAtoms.selectedPairAtom);
  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);
  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const { tokenPairs } = initialLoadValue;

  const handleTabChange = (event, newValue) => {
    const foundTokenPair = tokenPairs.find((tokenPair) => tokenPair.id === newValue);
    if (!foundTokenPair) {
      setErrorContent({
        severity: 'error',
        message: 'Token pair no longer supplied, favorite pair removed',
      });
      setHasError(true);
      const { [newValue]: _, ...otherFavorites } = favouritePairs;
      setFavouritePairs(otherFavorites);
      return;
    }
    setSelectedPair(tokenPairs.find((tokenPair) => tokenPair.id === newValue));
  };

  const pairExistsFavorite = (pair) => {
    return pair && Object.keys(favouritePairs).includes(pair.id);
  };

  return (
    <Stack direction='row'>
      <IconButton sx={{ color: 'primary.main' }}>
        <Star sx={{ fontSize: '0.8rem' }} />
      </IconButton>
      {Object.keys(favouritePairs).length > 0 ? (
        <Tabs
          aria-label='scrollable tabs'
          ScrollButtonComponent={MyTabScrollButton}
          scrollButtons='auto'
          sx={{
            minHeight: '30px',
            height: '30px',
          }}
          value={pairExistsFavorite(selectedPair) ? selectedPair.id : false}
          variant='scrollable'
          onChange={(e, newValue) => handleTabChange(e, newValue)}
        >
          {Object.keys(favouritePairs).map((pair) => {
            if (favouritePairs[pair]) {
              return (
                <Tab
                  key={`${pair}-fav-tab`}
                  label={pair}
                  sx={{
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    minHeight: '30px',
                    height: '30px',
                  }}
                  value={pair}
                />
              );
            }
            return null;
          })}
        </Tabs>
      ) : (
        <Box alignItems='center' display='flex' height='30px' justifyContent='center'>
          <Typography color='grey.main' variant='body2'>
            No favorite pairs
          </Typography>
        </Box>
      )}
    </Stack>
  );
}

export default FavoritePairTabs;
