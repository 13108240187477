import React from 'react';
import { useTheme } from '@emotion/react';
import { Typography } from '@mui/material';

function ProgressBar({
  progress,
  orderStatus,
  isPov,
  isDark = false,
  fullWidth = false,
  containerStyleOverride = {},
  barStyleOverride = {},
}) {
  const theme = useTheme();

  const formatPctFilled = () => {
    if (isPov && progress <= 100) {
      return [theme.palette.success.main, theme.palette.success.dark2];
    }
    if (progress < 99 && orderStatus === 'ACTIVE') {
      return [theme.palette.success.main, theme.palette.success.dark2];
    }
    if (progress <= 101 && progress >= 95) {
      return [theme.palette.success.main, theme.palette.success.dark2];
    }
    if (progress > 101 || orderStatus === 'COMPLETE') {
      return [theme.palette.error.main, theme.palette.error.dark2];
    }
    return [theme.palette.primary.main, theme.palette.primary.transparent];
  };

  const color = formatPctFilled();

  const containerStyle = {
    position: 'relative',
    height: '15px',
    width: fullWidth ? '120px' : '80px',
    backgroundColor: isDark ? 'inherit' : color[1],
    borderRadius: '5px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...containerStyleOverride,
    ...(isDark && {
      border: `1px solid ${color[1]}`,
    }),
  };

  const barContainerStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    backgroundColor: 'inherit',
    overflow: 'hidden',
  };

  const barStyle = {
    height: '15px',
    width: `${progress}%`,
    backgroundColor: isDark ? color[1] : color[0],
    borderRadius: '0px',
    transition: 'width 0.5s ease-in-out',
    zIndex: 0,
    ...barStyleOverride,
  };

  return (
    <div style={containerStyle}>
      <div style={barContainerStyle}>
        <div style={barStyle} />
      </div>
      <Typography
        color={progress < 49 || isDark ? color[0] : 'text.offBlack'}
        style={{ zIndex: 1 }}
        variant='body2'
      >{`${progress}%`}</Typography>
    </div>
  );
}

export default ProgressBar;
