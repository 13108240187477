// TODO: use more of @treadfi/contracts to get the attestation address & access address
import { SUPPORTED_CHAINS, getContract, ContractName } from '@treadfi/contracts';

export const BLOCK_STEP_SIZE = 1_000;
export const MAX_EMPTY_BATCHES = 200;
// export const LATEST_BLOCK = 3_711_386; // known, hard-coded block for local dev
export const LATEST_BLOCK = null; // latest block for prod

/**
 * Configuration object for Basescan RPC endpoints and contract addresses
 * @type {{
 *   development: {
 *     rpcUrl: string,
 *     explorerUrl: string,
 *     attestationAddress: string,
 *     accessAddress: string
 *   },
 *   production: {
 *     rpcUrl: string,
 *     explorerUrl: string,
 *     attestationAddress: string,
 *     accessAddress: string
 *   },
 *   defaults: {
 *     numberOfBlocks: number,
 *     retry: number
 *   }
 * }}
 */
const BASESCAN_CONFIG = {
  development: {
    rpcUrl: 'https://sepolia.base.org',
    explorerUrl: 'https://sepolia.basescan.org',
    // 0xB4f9A1f1347b7D8eb97dC70672576BB96E0510e0
    attestationAddress: await getContract(ContractName.Attestations, SUPPORTED_CHAINS.baseSepolia).getAddress(),
    // 0x40D245668ab0df4619Af6467e3036Cb68404083b
    accessAddress: await getContract(ContractName.Access, SUPPORTED_CHAINS.baseSepolia).getAddress(),
  },
  production: {
    rpcUrl: 'https://mainnet.base.org',
    explorerUrl: 'https://basescan.org',
    // 0xC31c7663873d36bC63bA28df4D40D0102F73D1B5
    attestationAddress: await getContract(ContractName.Attestations, SUPPORTED_CHAINS.base).getAddress(),
    // 0x40D245668ab0df4619Af6467e3036Cb68404083b
    accessAddress: await getContract(ContractName.Access, SUPPORTED_CHAINS.base).getAddress(),
  },
  defaults: {
    numberOfBlocks: 50000,
    retry: 3,
  },
};

/**
 * Selects the appropriate Basescan RPC URL based on development environment
 * @param {boolean} isDev - Whether the app is running in development environment
 * @returns {Object} The Basescan config object with properties:
 *   @property {string} rpcUrl - The RPC URL for either testnet or mainnet
 *   @property {string} explorerUrl - The explorer URL for either testnet or mainnet
 *   @property {string} attestationAddress - The contract address
 *   @property {number} numberOfBlocks - Number of blocks to query
 *   @property {number} retry - Number of retry attempts
 *   @property {number} paginationNumber - Pagination offset
 */
export const selectBasescanConfig = (isDev = false, paginationNumber = 0) => {
  const env = isDev ? 'development' : 'production';
  const config = BASESCAN_CONFIG[env];

  console.debug(
    '[selectBasescanConfig]',
    `Environment: ${env}`,
    `RPC URL: ${config.rpcUrl}`,
    `Explorer URL: ${config.explorerUrl}`,
    `Contract: ${config.attestationAddress}`,
    `Pagination: ${paginationNumber}`
  );

  return {
    ...config,
    ...BASESCAN_CONFIG.defaults,
    paginationNumber,
  };
};

/**
 * Configuration object for Monad RPC endpoints and contract addresses.
 * Similar interface to BASESCAN_CONFIG.
 * @type {{
 *   devnet: {
 *     rpcUrl: string,
 *     explorerUrl: string,
 *     attestationAddress: string,
 *     accessAddress: string,
 *     vaultAddress: string,
 *     mockErc20Address: string
 *   },
 *   testnet: {
 *     rpcUrl: string,
 *     explorerUrl: string,
 *     attestationAddress: string,
 *     accessAddress: string,
 *     vaultAddress: string,
 *     mockErc20Address: string
 *   },
 *   defaults: {
 *     numberOfBlocks: number,
 *     retry: number
 *   }
 * }}
 */
const MONAD_CONFIG = {
  devnet: {
    // Chain ID 20143 from SUPPORTED_CHAINS.monadDevnet
    rpcUrl: 'https://rpc-devnet.monadinfra.com/rpc/3fe540e310bbb6ef0b9f16cd23073b0a ',
    explorerUrl: 'https://explorer.monad-devnet.devnet101.com',
    // Using contract addresses from @treadfi/contracts
    // 0x7959445debb193f2a5ca79bd879ce3fa56607fb7
    attestationAddress: await getContract(ContractName.Attestations, SUPPORTED_CHAINS.monadDevnet).getAddress(),
    // 0x0b903f0a4b53d6f8dd6c9042b748eff90affa53c
    accessAddress: await getContract(ContractName.Access, SUPPORTED_CHAINS.monadDevnet).getAddress(),
    vaultAddress: await getContract(ContractName.Vault, SUPPORTED_CHAINS.monadDevnet).getAddress(),
    mockErc20Address: await getContract(ContractName.MockErc20, SUPPORTED_CHAINS.monadDevnet).getAddress(),
  },
  testnet: {
    // Chain ID 10143 from SUPPORTED_CHAINS.monadTestnet
    rpcUrl: 'https://testnet-rpc2.monad.xyz/52227f026fa8fac9e2014c58fbf5643369b3bfc6',
    explorerUrl: 'https://testnet.monadexplorer.com',
    // Using contract addresses from @treadfi/contracts
    // 0x7959445debb193f2a5ca79bd879ce3fa56607fb7
    attestationAddress: await getContract(ContractName.Attestations, SUPPORTED_CHAINS.monadTestnet).getAddress(),
    // 0x0b903f0a4b53d6f8dd6c9042b748eff90affa53c
    accessAddress: await getContract(ContractName.Access, SUPPORTED_CHAINS.monadTestnet).getAddress(),
    vaultAddress: await getContract(ContractName.Vault, SUPPORTED_CHAINS.monadTestnet).getAddress(),
    mockErc20Address: await getContract(ContractName.MockErc20, SUPPORTED_CHAINS.monadTestnet).getAddress(),
  },
  defaults: {
    numberOfBlocks: 50000,
    retry: 3,
  },
};

/**
 * Selects the appropriate Monad RPC URL and settings based on the environment.
 * Uses Monad endpoints for devnet (when isDev is true) and testnet (when isDev is false).
 * @param {boolean} isDev - Whether the app is running in development mode.
 * @param {number} paginationNumber - Pagination offset.
 * @returns {Object} The Monad config object with properties similar to selectBasescanConfig.
 */
export const selectMonadConfig = (isDev = false, paginationNumber = 0) => {
  const env = isDev ? 'devnet' : 'testnet';
  const config = MONAD_CONFIG[env];
  console.debug(
    '[selectMonadConfig]',
    `Environment: ${env}`,
    `RPC URL: ${config.rpcUrl}`,
    `Explorer URL: ${config.explorerUrl}`,
    `Contract: ${config.attestationAddress}`,
    `Pagination: ${paginationNumber}`
  );
  return {
    ...config,
    ...MONAD_CONFIG.defaults,
    paginationNumber,
  };
};

export const selectConfig = (isDev = false, paginationNumber = 0) => {
  // Always use monadTestnet, monadDevnet is no longer relevant
  return selectMonadConfig(false, paginationNumber);
};
