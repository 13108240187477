import { AccountsProvider } from '@/shared/context/AccountsProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ExplorerProofsTable from './ExplorerProofsTable';
import ProofLogoImg from './proofUtils/ProofLogoImg';

/**
 * Page component for displaying proof events
 */
export default function ExplorerProofsPage() {
  const navigate = useNavigate();

  return (
    <Box
      spacing={2}
      sx={{
        height: '95vh',
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box spacing={2} sx={{ padding: '2rem 0', flexShrink: 0 }}>
        <Button startIcon={<ArrowBackIcon />} sx={{ mb: 4 }} onClick={() => navigate('/explorer')}>
          Back to Explorer
        </Button>
        <Stack alignItems='center' direction='row' spacing={2} style={{ flexShrink: 0 }}>
          <ProofLogoImg height='3rem' />
          <Typography fontFamily='Jost' fontSize={32} fontWeight={400} variant='h2'>
            Oracle
          </Typography>
        </Stack>
        <Typography sx={{ mt: 3 }} variant='subtitle1'>
          Proofs are securely stored on Monad for permanent and transparent record-keeping.
        </Typography>
        <Divider sx={{ mt: 4 }} />
      </Box>
      <AccountsProvider>
        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ExplorerProofsTable isPreviewOnly={false} />
        </Box>
      </AccountsProvider>
    </Box>
  );
}
