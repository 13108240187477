/* eslint-disable class-methods-use-this */
const { getTradingViewDataFeed, getLatestBars } = require('@/apiServices');

class CustomDatafeed {
  constructor() {
    this.subscribers = {};
  }

  onReady(callback) {
    setTimeout(() => {
      callback({
        supports_search: true,
        supports_group_request: false,
        supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D'],
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
      });
    }, 1000);
  }

  async resolveSymbol(symbolName, onSymbolResolvedCallback, onErrorCallback) {
    if (!symbolName) {
      onErrorCallback('Error: Symbol name is missing.');
      return;
    }

    const symbol = {
      name: symbolName,
      ticker: symbolName,
      description: `${symbolName} Price`,
      type: 'crypto',
      session: '24x7',
      exchange: 'Custom',
      minmov: 1,
      timezone: 'Etc/UTC',
      pricescale: 100,
      has_intraday: true,
      supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D'],
      volume_precision: 2,
      data_status: 'streaming',
    };

    // resolveSymbol needs to called asynchronously
    setTimeout(() => onSymbolResolvedCallback(symbol), 0);
  }

  getBars(symbolInfo, resolution, from, onHistoryCallback, onErrorCallback) {
    const apiBaseUrl = getTradingViewDataFeed();
    const url = `${apiBaseUrl}?symbol=${symbolInfo.name}&resolution=${resolution}&to=${from.to}&from=${from.from}`;
    if (!symbolInfo || !symbolInfo.name) {
      onErrorCallback('Invalid symbol data');
      return;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.length) {
          const bars = data.map((bar) => ({
            time: bar.time,
            open: bar.open,
            high: bar.high,
            low: bar.low,
            close: bar.close,
            volume: bar.volume,
          }));

          onHistoryCallback(bars, { noData: false });
        } else {
          onHistoryCallback([], { noData: true });
        }
      })
      .catch((error) => {
        onErrorCallback('Error loading data');
      });
  }

  // Convert this to websocket
  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    if (this.subscribers[subscriberUID]) {
      return;
    }

    this.subscribers[subscriberUID] = setInterval(async () => {
      const currentTime = Math.floor(Date.now() / 1000); // Current UNIX time in seconds
      const barSize = this.getResolutionInSeconds(resolution); // Convert resolution to seconds

      const from = currentTime - barSize;
      const to = currentTime;

      const apiBaseUrl = getTradingViewDataFeed();

      const response = await fetch(
        `${apiBaseUrl}?symbol=${symbolInfo.name}&resolution=${resolution}&from=${from}&to=${to}`
      );
      const data = await response.json();

      if (data && data.length > 0) {
        const aggregatedBar = this.aggregateBars(data);

        onRealtimeCallback({
          time: aggregatedBar.time,
          open: aggregatedBar.open,
          high: aggregatedBar.high,
          low: aggregatedBar.low,
          close: aggregatedBar.close,
          volume: aggregatedBar.volume,
        });
      }
    }, 5000);
  }

  unsubscribeBars(subscriberUID) {
    if (this.subscribers[subscriberUID]) {
      clearInterval(this.subscribers[subscriberUID]);
      delete this.subscribers[subscriberUID];
    }
  }

  getResolutionInSeconds(resolution) {
    const resMap = {
      1: 60, // 1 minute
      5: 300, // 5 minutes
      15: 900, // 15 minutes
      30: 1800, // 30 minutes
      60: 3600, // 1 hour
      240: 14400, // 4 hours
      '1D': 86400, // 1 day
    };
    return resMap[resolution] || 60; // Default to 1 min if unknown resolution
  }

  // use interval stat within getIntevalBars to get aggregated bars for the given interval
  aggregateBars(bars) {
    if (!bars || bars.length === 0) return null;

    return bars.reduce(
      (agg, bar, index) => ({
        time: bars[bars.length - 1].time,
        open: bars[0].open,
        high: Math.max(agg.high, bar.high),
        low: Math.min(agg.low, bar.low),
        close: bar.close,
        volume: agg.volume + bar.volume,
      }),
      {
        high: -Infinity,
        low: Infinity,
        volume: 0,
      }
    );
  }
}

export default CustomDatafeed;
