import { useState, useCallback } from 'react';
import { decryptArweaveTransaction } from '@/apiServices';
import { matchesTraderId } from '@/shared/cryptoUtil';

/**
 * Reusable hook for handling trade decryption logic
 * @param {string} txId - Transaction ID to decrypt
 * @param {string} traderId - Trader ID to decrypt
 * @param {string} rawData - Raw transaction data to decrypt
 * @returns {Object} Decryption state and function { decryptedData, isAuthorized, loading, error, decrypt }
 */
export function useDecryptTrade(txId, traderId, rawData, accounts) {
  const [decryptedData, setDecryptedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const decrypt = useCallback(async () => {
    if (!traderId || !rawData) return;

    setLoading(true);
    setError(null);

    try {
      // Check authorization
      const userHasAccess = accounts?.some((account) => matchesTraderId(account.hashed_api_key, traderId)) ?? false;
      setIsAuthorized(userHasAccess);

      if (userHasAccess) {
        const decryptResponse = await decryptArweaveTransaction(txId, {
          trader_id: traderId,
          raw_data: rawData,
        });

        if (decryptResponse?.decrypted_data) {
          // Handle both array and single object responses
          const data = Array.isArray(decryptResponse.decrypted_data)
            ? decryptResponse.decrypted_data
            : [decryptResponse.decrypted_data];
          setDecryptedData(data);
        } else {
          throw new Error('Invalid decryption response format');
        }
      }
    } catch (err) {
      setError(err.message || 'Failed to decrypt trade details');
    } finally {
      setLoading(false);
    }
  }, [txId, traderId, rawData, accounts]);

  return { decryptedData, isAuthorized, loading, error, decrypt };
}
