import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { isEmpty, numberWithCommas, smartRound } from '@/util';
import { Paper } from '@mui/material';
import { balanceToRow } from '../util';
import AssetAreaChart from './AssetAreaChart';
import { MarketTypeTable } from './MarketTypeTable';
import CloseBalanceButton from '../CloseBalanceButton';

function SubTitleTypography(props) {
  return <Typography color='text.subtitle' fontWeight={300} variant='h6' {...props} />;
}

function CurrencyTitleTypography(props) {
  return <Typography fontWeight={300} variant='h6' {...props} />;
}

function pnlDisplayValue(value) {
  let color = 'text.primary';
  if (value > 0) {
    color = 'success.main';
  }
  if (value < 0) {
    color = 'error.main';
  }

  return (
    <Stack alignItems='baseline' direction='row' spacing={4}>
      <Typography color={color} fontWeight={300} variant='h3'>
        {value > 0 ? '+' : ''}
        {numberWithCommas(smartRound(value, 2))}
      </Typography>
      <CurrencyTitleTypography color={color}>USDT</CurrencyTitleTypography>
    </Stack>
  );
}

function pnlDisplayPercentage(value) {
  if (!value) {
    return null;
  }

  let color = 'text.primary';
  let transform = 'none';
  if (value > 0) {
    color = 'success.main';
  } else if (value < 0) {
    color = 'error.main';
    transform = 'rotate(180deg) translate(-30%, 0)';
  }

  return (
    <Stack alignItems='baseline' direction='row' spacing={2}>
      <ArrowOutwardIcon
        sx={{
          alignSelf: 'center',
          color,
          transform,
        }}
      />
      <Typography color={color} variant='subtitle1'>
        {`${Number(value).toFixed(2)}%`}
      </Typography>
      <Typography variant='subtitle1'>7d</Typography>
    </Stack>
  );
}

const calculatePnl = (assets) => {
  return assets.reduce((acc, asset) => {
    if (asset.unrealized_profit) {
      return acc + asset.unrealized_profit;
    }
    return acc;
  }, 0);
};

function AccountOverviewComponent({ accountBalances, selectedAccount, assets, pastSnapshots, balanceHistory }) {
  const selectedBalance = selectedAccount[0] ? accountBalances.find((a) => a.account_id === selectedAccount[0]) : {};
  const selectedAccountObj = accountBalances.find((obj) => obj.account_id === selectedAccount[0]);

  const row = balanceToRow(selectedAccountObj, pastSnapshots);
  const totalPnl = calculatePnl(selectedAccountObj.assets);
  const exchange = selectedAccountObj.exchange_name;

  const { weekAgoDiffPercentage } = row;

  return (
    <Stack direction='column' margin={4} spacing={4}>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Stack direction='column' spacing={2}>
          <SubTitleTypography>Total Balance</SubTitleTypography>
          <Stack alignItems='baseline' direction='row' spacing={4}>
            <Typography fontWeight={300} variant='h1'>
              {numberWithCommas(smartRound(selectedAccountObj?.totalValue, 2))}
            </Typography>
            <CurrencyTitleTypography>USDT</CurrencyTitleTypography>
            {pnlDisplayPercentage(weekAgoDiffPercentage)}
          </Stack>
        </Stack>
      </Paper>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Stack direction='column' spacing={4}>
          <Stack direction='column' spacing={2}>
            <Stack alignItems='center' direction='row' spacing={1}>
              <SubTitleTypography>Unrealized PnL</SubTitleTypography>
            </Stack>
            {pnlDisplayValue(totalPnl)}
          </Stack>
          <SubTitleTypography>Notional Exposure</SubTitleTypography>
          <AssetAreaChart balanceData={balanceHistory} selectedAccount={selectedAccount} />
        </Stack>
      </Paper>

      <Stack direction='column' spacing={4}>
        {isEmpty(assets) ? (
          <Box alignItems='center' display='flex' justifyContent='center' width='100%'>
            <Typography variant='h5'>No assets found</Typography>
          </Box>
        ) : (
          <>
            <CloseBalanceButton selectedAccount={selectedAccount} selectedBalance={selectedBalance} />
            <MarketTypeTable assets={assets} exchange={exchange} />
          </>
        )}
      </Stack>
    </Stack>
  );
}

export default AccountOverviewComponent;
