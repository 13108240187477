import { useTheme } from '@emotion/react';
import { Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useAtom } from 'jotai';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { atomWithStorage } from 'jotai/utils';
import { ApiError, cancelMultiOrder, submitCancel } from '../../apiServices';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../constants';
import { BasicModal } from '../Modal';
import { ErrorContext } from '../context/ErrorProvider';
import { useUserMetadata } from '../context/UserMetadataProvider';
import CollapsedRow from './CollapsedRow';
import TableOrderConfirmationModel from './TableOrderConfirmationModel';
import { StyledHeaderTableCellWithLine, StyledPaddingTableCell, StyledTableCell, getOrderPath } from './util';
import CollapsedChildsRow from './CollapsedChildsRow';
import CollapsedChainedRow from './CollapsedChainedRow';
import DisplayRowDetails from './DisplayRowDetails';

import { OrderTableColumnFilterButton } from './OrderTableColumnFilter';
import {
  ResubmitOrderButton,
  ResubmitRemainingOrderButton,
  ViewOrderButton,
  PauseOrderButton,
  ResumeOrderButton,
  CancelOrderButton,
} from './tableActions';
import { reSubmitAction, reSubmitRemainingAction } from '../orderDetail/util/orderActionUtils';
import useViewport from '../hooks/useViewport';

const DEFAULT_VISIBLE_COLUMNS = {
  arrival_cost: false,
  duration: false,
  executed_notional: false,
  executed_price: false,
  executed_qty: false,
  pair: true,
  pct_filled: true,
  pov: false,
  side: true,
  status: true,
  super_strategy: true,
  target_qty: true,
  time_start: true,
  unique_venues: true,
  vwap_cost: false,
};
const visibleColumnsAtom = atomWithStorage('visibleOrderTableColumns', DEFAULT_VISIBLE_COLUMNS);

const getColumns = (dashboardView) => {
  return [
    ...(!dashboardView
      ? [
          {
            id: 'account_names',
            label: 'Accounts',
            width: 100,
            align: 'left',
            showDefault: true,
          },
        ]
      : [
          {
            id: 'unique_venues',
            label: 'Exchanges',
            width: 20,
            align: 'right',
            showDefault: true,
          },
        ]),
    {
      id: 'pair' || 'pairs',
      label: 'Pair',
      width: 160,
      align: 'left',
      showDefault: true,
    },
    {
      id: 'side',
      label: 'Side',
      width: 60,
      align: 'left',
      showDefault: true,
    },
    {
      id: 'executed_notional',
      label: 'Executed Notional',
      width: 150,
      align: 'right',
      showDefault: !dashboardView,
    },
    {
      id: 'target_qty',
      label: 'Target Quantity',
      width: 150,
      align: 'right',
      showDefault: true,
    },
    {
      id: 'executed_qty',
      label: 'Executed Quantity',
      width: 150,
      align: 'right',
      showDefault: false,
    },
    {
      id: 'executed_price',
      label: 'Average Executed Price',
      width: 100,
      align: 'right',
      showDefault: !dashboardView,
    },
    {
      id: 'pct_filled',
      label: 'Filled',
      width: 30,
      align: 'center',
      showDefault: true,
    },
    {
      id: 'time_start',
      label: 'Time Start',
      width: 170,
      align: 'left',
      showDefault: true,
    },
    {
      id: 'super_strategy',
      label: 'Strategy',
      width: 140,
      align: 'left',
      showDefault: true,
    },
    {
      id: 'status',
      label: 'Status',
      width: 30,
      align: 'left',
      showDefault: true,
    },
    {
      id: 'duration',
      label: 'Duration',
      width: 100,
      align: 'right',
      showDefault: false,
    },
    {
      id: 'pov',
      label: 'Participation Rate',
      width: 100,
      align: 'right',
      showDefault: false,
    },
    {
      id: 'vwap_cost',
      label: 'VWAP Cost',
      width: 140,
      align: 'right',
      showDefault: false,
    },
    {
      id: 'arrival_cost',
      label: 'Arrival Cost',
      width: 140,
      align: 'right',
      showDefault: false,
    },
  ];
};

const getOrderType = (row) => {
  if (row.side === 'Multi') {
    return 'Multi';
  }
  if (row.side === 'Chained') {
    return 'Chained';
  }
  return 'Single';
};

function DisplayRow({
  row,
  columns,
  visibleColumns,
  setCancelModalData,
  dashboardView,
  setIsResubmit,
  setRowData,
  setOpenModal,
  theme,
}) {
  const orderRow = row;

  const [open, setOpen] = useState(false);
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const StyledCell = dashboardView ? StyledPaddingTableCell : StyledTableCell;

  const isMultiChainedOrder = orderRow.side === 'Multi' || orderRow.side === 'Chained';

  let isTerminalStatus = ['COMPLETE', 'CANCELED'].includes(orderRow.status);
  if (isMultiChainedOrder && orderRow.calculated_status) {
    isTerminalStatus = ['COMPLETE', 'CANCELED'].includes(orderRow.calculated_status);
    orderRow.status = orderRow.calculated_status;
  }
  const handleClick = useCallback(() => {
    if (isMobile) {
      const url = getOrderPath(orderRow);
      navigate(url);
    } else {
      setOpen(!open);
    }
  }, [isMobile, open, setOpen, orderRow]);

  const orderType = getOrderType(orderRow);

  const renderCollapsable = () => {
    if (orderType === 'Multi') {
      return (
        <CollapsedChildsRow
          childOrders={orderRow.child_order_ids}
          columns={columns}
          open={open}
          row={orderRow}
          StyledCell={StyledCell}
          ViewOrderTooltip={ViewOrderButton}
          visibleColumns={visibleColumns}
        />
      );
    }
    if (orderType === 'Chained') {
      return (
        <CollapsedChainedRow
          columns={columns}
          open={open}
          ordersInChain={orderRow.orders_in_chain}
          StyledCell={StyledCell}
          ViewOrderTooltip={ViewOrderButton}
          visibleColumns={visibleColumns}
        />
      );
    }
    return <CollapsedRow dashboardView={dashboardView} open={open} row={orderRow} style={{ padding: 0 }} />;
  };

  return (
    <>
      <TableRow
        hover
        key={`table row${orderRow.id}`}
        sx={{
          // already a border on collapseable row
          '& .MuiTableCell-root': {
            borderBottom: 0,
          },
        }}
        onClick={handleClick}
      >
        {columns.map(
          (column) =>
            visibleColumns[column.id] &&
            DisplayRowDetails({
              row: orderRow,
              column,
              StyledCell,
              theme,
            })
        )}
        <StyledCell
          sx={{
            minWidth: 124,
            height: 32,
            justifyContent: 'flex-end', // Aligns the content to the right
            alignItems: 'center', // Aligns the content to the center
            textAlign: 'end',
          }}
        >
          <ResubmitRemainingOrderButton
            disabled={!isTerminalStatus || isMultiChainedOrder || orderRow.pct_filled > 99}
            onClick={(event) => {
              event.stopPropagation();
              setIsResubmit(false);
              setRowData(orderRow);
              setOpenModal(true);
            }}
          />

          <ResubmitOrderButton
            disabled={!isTerminalStatus || isMultiChainedOrder}
            onClick={(event) => {
              event.stopPropagation();
              setIsResubmit(true);
              setRowData(orderRow);
              setOpenModal(true);
            }}
          />

          <ViewOrderButton orderRow={orderRow} />

          {orderRow.status === 'PAUSED' ? (
            <PauseOrderButton orderRow={orderRow} orderType={orderType} />
          ) : (
            <ResumeOrderButton orderRow={orderRow} orderType={orderType} />
          )}

          <CancelOrderButton
            disabled={isTerminalStatus}
            onClick={(event) => {
              event.stopPropagation();
              setCancelModalData({
                open: true,
                orderId: orderRow.id,
                orderType,
              });
            }}
          />
        </StyledCell>
      </TableRow>
      <TableRow>
        {/* + 1 for colSpan is for actions */}
        <TableCell colSpan={getColumns(dashboardView).length + 1} style={{ padding: 0 }}>
          {renderCollapsable()}
        </TableCell>
      </TableRow>
    </>
  );
}

function SharedOrderTable({
  orderData = [],
  orderRefresh,
  dashboardView = false,
  FormAtoms,
  page = 0,
  totalPages = -1,
  setPage = () => {},
}) {
  const { user, isRetail } = useUserMetadata();

  const [initialLoadValue] = useAtom(FormAtoms.initialLoadValueAtom);

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [cancelModalData, setCancelModalData] = useState({
    open: false,
    orderId: null,
    orderType: null,
  });
  const { setHasError, setErrorContent } = useContext(ErrorContext);

  const [openModal, setOpenModal] = useState(false);
  const [submitModalMessage, setSubmitModalMessage] = useState('');

  const [rowData, setRowData] = useState({ side: 'buy' });

  // refactor this to reducers
  const [isResubmit, setIsResubmit] = useState(true);

  const theme = useTheme();
  const cancelModalOpen = cancelModalData.open;
  const openNewTabOnSubmit = user.preferences ? user.preferences[OPEN_NEW_TAB_ON_SUBMIT] : false;

  const handleCancel = async (rowId, orderType) => {
    setCancelModalData({ open: false, orderId: null, orderType: null });
    try {
      if (orderType === 'Multi') {
        await cancelMultiOrder(rowId);
      } else {
        await submitCancel(rowId);
      }
      setErrorContent({
        severity: 'success',
        message: 'Successfully canceled the specified order.',
      });
      await orderRefresh();
    } catch (e) {
      if (e instanceof ApiError) {
        setErrorContent({ severity: 'error', message: e.message });
      } else {
        throw e;
      }
    }
    setHasError(true);
  };

  const reSubmit = async (row) => {
    reSubmitAction({
      row,
      openNewTabOnSubmit,
      showAlert: setErrorContent,
    });
    setOpenModal(false);
    setHasError(true);
  };

  const reSubmitRemaining = async (row) => {
    reSubmitRemainingAction({
      row,
      openNewTabOnSubmit,
      showAlert: setErrorContent,
    });
    setOpenModal(false);
    setHasError(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const columns = getColumns(dashboardView);

  const [visibleColumns, setVisibleColumns] = useAtom(visibleColumnsAtom);

  const tableContainerHeight = dashboardView ? '100%' : 'calc(100% - 60px)';

  return (
    <Box sx={{ height: '100%' }}>
      <TableContainer style={{ height: tableContainerHeight }}>
        <Table stickyHeader aria-label='sticky table' size='small'>
          <TableHead>
            <TableRow>
              {columns.map(
                (column) =>
                  visibleColumns[column.id] && (
                    <StyledHeaderTableCellWithLine
                      align={column.align}
                      key={`main header${column.id}`}
                      style={{
                        minWidth: column.minWidth,
                        width: column.width || undefined,
                      }}
                    >
                      {column.id === 'unique_venues' ? '' : column.label}
                    </StyledHeaderTableCellWithLine>
                  )
              )}
              <StyledHeaderTableCellWithLine align='right' key='actions' style={{ width: 190 }}>
                <OrderTableColumnFilterButton
                  columns={columns}
                  dashboardView={dashboardView}
                  setVisibleColumns={setVisibleColumns}
                  visibleColumns={visibleColumns}
                />
              </StyledHeaderTableCellWithLine>
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflow: 'auto' }}>
            {orderData.map((row) => (
              <DisplayRow
                columns={columns}
                dashboardView={dashboardView}
                key={`displayed row${row.id}`}
                row={row}
                setCancelModalData={setCancelModalData}
                setIsResubmit={setIsResubmit}
                setOpenModal={setOpenModal}
                setRowData={setRowData}
                theme={theme}
                visibleColumns={visibleColumns}
              />
            ))}
          </TableBody>
        </Table>
        {orderData.length === 0 && (
          <Box alignItems='center' display='flex' height='calc(100% - 60px)' justifyContent='center'>
            {user && user.is_authenticated ? (
              <Typography variant='h6'>No orders found</Typography>
            ) : (
              <Stack direction='row' gap={1}>
                <Button
                  href='account/login/'
                  size='small'
                  sx={{ backgroundColor: theme.palette.primary.dark2 }}
                  variant='contained'
                >
                  <Typography variant='h6'>Log in</Typography>
                </Button>
                {isRetail && (
                  <>
                    <Typography sx={{ paddingTop: '4px' }} variant='h6'>
                      {' '}
                      or{' '}
                    </Typography>
                    <Button color='primary' href='account/signup/' size='small' variant='contained'>
                      <Typography color={theme.palette.text.offBlack} variant='h6'>
                        Sign up
                      </Typography>
                    </Button>
                  </>
                )}
                <Typography sx={{ paddingTop: '4px' }} variant='h6'>
                  {' '}
                  to see orders
                </Typography>
              </Stack>
            )}
          </Box>
        )}
      </TableContainer>

      {!dashboardView ? (
        <TablePagination
          component='div'
          count={totalPages * rowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          sx={{ height: '60px' }}
          onPageChange={handleChangePage}
        />
      ) : null}
      <BasicModal
        confirmButtonText='Yes'
        handleConfirm={() => handleCancel(cancelModalData.orderId, cancelModalData.orderType)}
        message='Are you sure you want to cancel this order?'
        open={cancelModalOpen}
        setOpen={(e) =>
          setCancelModalData((prev) => {
            return { ...prev, open: e };
          })
        }
      />
      <TableOrderConfirmationModel
        dashboardView={dashboardView}
        data={rowData}
        FormAtoms={FormAtoms}
        handleResubmit={reSubmit}
        handleResubmitRemaining={reSubmitRemaining}
        initialLoadValue={initialLoadValue}
        isBuy={rowData.side === 'buy'}
        isResubmit={isResubmit}
        modalText={submitModalMessage}
        open={openModal}
        setOpen={setOpenModal}
      />
    </Box>
  );
}

export { SharedOrderTable };
