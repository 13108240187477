import { useTheme } from '@mui/system';
import { smartRound } from '@/util';

function usePreTradeAnalytics(preTradeEstimationData) {
  const theme = useTheme();

  const { pov, volatility, warning } = preTradeEstimationData;
  const displayPov = pov ? `${smartRound(pov)}%` : 'N/A';
  const displayVolatility = volatility ? `±${smartRound(volatility)}%` : 'N/A';

  let displayPovColor;
  if (!pov) {
    displayPovColor = '';
  } else if (pov < 0.5) {
    displayPovColor = theme.palette.success.main;
  } else if (pov < 1) {
    displayPovColor = theme.palette.warning.main;
  } else {
    displayPovColor = theme.palette.error.main;
  }

  return { displayPov, displayPovColor, displayVolatility, warning };
}

export default usePreTradeAnalytics;
