import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useTheme } from '@mui/material';

Highcharts.setOptions({
  chart: {
    backgroundColor: 'transparent',
  },
});

const secondary = '#5925DC';

const splineOptions = (theme) => ({
  chart: {
    type: 'areaspline',
    height: '400px',
    backgroundColor: 'transparent',
  },

  title: {
    text: null,
  },

  xAxis: {
    title: {
      text: null,
    },
    type: 'datetime',
    gridLineWidth: 0,
    lineWidth: 1,
    tickInterval: 24 * 3600 * 1000,
    labels: {
      formatter() {
        return Highcharts.dateFormat('%A %e', this.value);
      },
      style: { color: theme.palette.text.primary },
    },
    gridLineColor: 'rgba(255, 255, 255, 0.2)',
  },
  yAxis: {
    title: {
      text: null,
    },
    gridLineWidth: 0,
    lineWidth: 1,
    labels: {
      formatter() {
        return `${this.value / 1000000}M`;
      },
      style: { color: theme.palette.text.primary },
    },
    gridLineColor: 'rgba(255, 255, 255, 0.2)',
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
    areaspline: {
      fillOpacity: 0.3,
    },
  },
  series: [
    {
      name: 'Series A',
      data: [
        [Date.UTC(2024, 2, 1), 1000000],
        [Date.UTC(2024, 2, 2), 3000000],
        [Date.UTC(2024, 2, 3), 2000000],
        [Date.UTC(2024, 2, 4), 5000000],
        [Date.UTC(2024, 2, 5), 4000000],
        [Date.UTC(2024, 2, 6), 7000000],
        [Date.UTC(2024, 2, 7), 6000000],
        [Date.UTC(2024, 2, 8), 9000000],
        [Date.UTC(2024, 2, 9), 8000000],
      ],
      color: theme.palette.primary.main,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, `${theme.palette.primary.main}80`],
          [1, `${theme.palette.primary.main}00`],
        ],
      },
    },
    {
      name: 'Series B',
      data: [
        [Date.UTC(2024, 2, 1), 900000],
        [Date.UTC(2024, 2, 2), 2800000],
        [Date.UTC(2024, 2, 3), 1800000],
        [Date.UTC(2024, 2, 4), 4500000],
        [Date.UTC(2024, 2, 5), 3800000],
        [Date.UTC(2024, 2, 6), 6500000],
        [Date.UTC(2024, 2, 7), 5500000],
        [Date.UTC(2024, 2, 8), 8700000],
        [Date.UTC(2024, 2, 9), 7500000],
      ],
      color: secondary,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, `${secondary}80`],
          [1, `${secondary}00`],
        ],
      },
    },
  ],
});

function SplineChart() {
  const theme = useTheme();
  return (
    <HighchartsReact highcharts={Highcharts} options={splineOptions(theme)} />
  );
}

export default SplineChart;
