import monadLight from '@images/logos/monad-light.png';

/**
 * Reusable component for displaying the Base Scan logo
 * @param {string} height - CSS height value for the image (default: '3rem')
 */
function ProofLogoImg({ height = '3rem' }) {
  return (
    <img
      alt='Monad'
      src={monadLight}
      style={{
        height,
        objectFit: 'contain',
        verticalAlign: 'middle',
      }}
    />
  );
}

export default ProofLogoImg;
