import { theme } from '@/theme/theme';
import { Button, Divider, Stack, Tab, Tabs, ThemeProvider } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { fetchAccountData } from '@/apiServices';
import { isEmpty } from '@/util';
import DataComponent from '@/shared/DataComponent';
import { Loader } from '@/shared/Loader';
import useViewport from '@/shared/hooks/useViewport';
import AccountSummaryTable from './AccountSummaryTable';
import { AccountBalanceLayout, MobileAccountBalanceLayout } from './AccountBalanceLayout';

import AccountOverviewComponent from './portfolio/AccountOverviewComponent';
import { calculateTotalValue } from './util';
import AccountRebalance from './AccountRebalance';
import LOGOS from '../../../images/logos';

Chart.register(
  ArcElement,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

Chart.defaults.color = 'rgb(230, 230, 230)';
Chart.defaults.font.family = 'IBM PLEX MONO';

function EmptyAccountBalancePage() {
  return (
    <Stack alignItems='center' direction='column' justifyContent='center' spacing={6} sx={{ height: '100%' }}>
      <img alt='Tread Logo' src={LOGOS.treadRoundedSvg} style={{ height: '64px' }} />
      <Typography sx={{ textAlign: 'center' }} variant='h6'>
        No exchange accounts connected yet. Link your accounts now to get started.
      </Typography>
      <Button href='/key_management' size='large' variant='contained'>
        Link API Keys
      </Button>
    </Stack>
  );
}

function AccountBalancePage() {
  const [accountBalances, setAccountBalances] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(['', '', '']);
  const [pageToggle, setPageToggle] = useState('overview');
  const [pastSnapshots, setPastSnapshots] = useState({});
  const [balanceHistory, setBalanceHistory] = useState([]);
  const [assetOrdering, setAssetOrdering] = useState({});
  const [loading, setLoading] = useState(true);
  const { showAlert } = useContext(ErrorContext);
  const { isDev } = useUserMetadata();
  const { isMobile } = useViewport();
  const [timeActiveButton, setTimeActiveButton] = useState('1M');

  const isAccountSelected = Boolean(selectedAccount[0]);
  const getAccounts = async (initial = true) => {
    const startTime = moment.utc().subtract(8, 'days');
    const endTime = moment.utc();
    try {
      const response = await fetchAccountData({
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      });
      const sortedAccountBalances = response.account_balances.sort((a, b) =>
        a.account_name.localeCompare(b.account_name)
      );

      const parsedAccountBalancesWithTotal = sortedAccountBalances.map((balance) => {
        const newBalance = balance;
        newBalance.totalValue = calculateTotalValue(balance);
        return newBalance;
      });

      setBalanceHistory(response.balance_history);

      setAccountBalances(parsedAccountBalancesWithTotal);
      setPastSnapshots(response.past_snapshots);

      if (response.account_balances.length > 0 && initial && !isMobile) {
        setSelectedAccount(
          [sortedAccountBalances[0].account_id, sortedAccountBalances[0].account_name],
          sortedAccountBalances[0].account_name
        );
      }

      const orderingByAccount = {};

      sortedAccountBalances.forEach((balance) => {
        balance.assets.sort((a, b) => Math.abs(b.notional) - Math.abs(a.notional));
        orderingByAccount[balance.account_name] = balance.assets.map((a) => a.symbol);
      });

      setAssetOrdering(orderingByAccount);
      setLoading(false);
    } catch (error) {
      showAlert({ severity: 'error', message: error.message });
    }
  };

  useEffect(() => {
    getAccounts();
  }, []);

  const selectedAssets = selectedAccount[0]
    ? accountBalances.find((a) => a.account_id === selectedAccount[0]).assets
    : [];
  const selectedAccountFull = selectedAccount[0]
    ? accountBalances.find((a) => a.account_id === selectedAccount[0])
    : [];
  const selectedAssetsWithAccountId = accountBalances.reduce((acc, account) => {
    acc[account.account_id] = account.assets.reduce((assetMap, asset) => {
      const updatedAssetMap = { ...assetMap };
      updatedAssetMap[asset.symbol] = updatedAssetMap[asset.symbol] || [];
      updatedAssetMap[asset.symbol].push(asset);
      return updatedAssetMap;
    }, {});
    return { ...acc };
  }, {});

  const Layout = isMobile ? MobileAccountBalanceLayout : AccountBalanceLayout;
  return (
    <Layout
      isAccountSelected={isAccountSelected}
      leftPanel={
        <DataComponent
          emptyComponent={<EmptyAccountBalancePage />}
          isEmpty={isEmpty(accountBalances)}
          isLoading={loading}
          loadingComponent={<Loader />}
        >
          <Stack direction='column' spacing={4} sx={{ p: 4 }}>
            <Typography variant='h5'>Accounts</Typography>
            <Divider />
            <AccountSummaryTable
              balances={accountBalances}
              getAccounts={getAccounts}
              pastSnapshots={pastSnapshots}
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              showAlert={showAlert}
            />
          </Stack>
        </DataComponent>
      }
      onClose={() => setSelectedAccount(['', '', ''])}
    >
      <DataComponent isLoading={loading} loadingComponent={<Loader />}>
        {isAccountSelected && (
          <>
            {isDev && (
              <>
                <Tabs
                  aria-label='scrollable tabs'
                  scrollButtons='auto'
                  value={pageToggle}
                  variant='scrollable'
                  onChange={(e, newValue) => setPageToggle(newValue)}
                >
                  <Tab label='Overview' value='overview' />
                  <Tab label='Rebalance' value='rebalance' />
                </Tabs>
                <Divider />
              </>
            )}
            {pageToggle === 'overview' && (
              <AccountOverviewComponent
                accountBalances={accountBalances}
                assets={selectedAssets}
                balanceHistory={balanceHistory}
                getAccounts={getAccounts}
                pastSnapshots={pastSnapshots}
                selectedAccount={selectedAccount}
                setTimeActiveButton={setTimeActiveButton}
                timeActiveButton={timeActiveButton}
              />
            )}
            {pageToggle === 'rebalance' && (
              <AccountRebalance
                assetOrdering={assetOrdering}
                balanceData={selectedAssetsWithAccountId}
                currentAssets={selectedAssets}
                selectedAccount={selectedAccount}
                selectedAccountFull={selectedAccountFull}
                showAlert={showAlert}
              />
            )}
          </>
        )}
      </DataComponent>
    </Layout>
  );
}

export default AccountBalancePage;
