import moment from 'moment';
import React, { createContext, useContext, useState, useEffect, useMemo, useRef } from 'react';
import { useOrderForm } from '@/shared/context/OrderFormProvider';
import {
  sendKeepAlive,
  subscribeToOrderBook,
  subscribeToPairPrice,
  unsubscribeToOrderBook,
  unsubscribeToPairPrice,
} from '../websocketActions';

const PriceDataContext = createContext();

export function PriceDataProvider({ children, pair, exchangeName }) {
  const [orderBookData, setOrderBookData] = useState({});
  const [livePairPrice, setLivePairPrice] = useState('');
  const [isL2DataLoading, setIsL2DataLoading] = useState(false);
  const [noL2Data, setNoL2Data] = useState(false);

  const priceLastUpdatedAtRef = useRef(moment.utc());
  const livePairPriceResubscribeTriesRef = useRef(0);
  const orderbookLastUpdatedAtRef = useRef(moment.utc());
  const orderbookResubscribeTriesRef = useRef(0);
  const socketRef = useRef();

  const { relevantExchangePairs } = useOrderForm();
  const ep = relevantExchangePairs.find((p) => p.exchange === exchangeName && p.pair === pair);

  const contractValue = ep?.contract_value || 1;
  const isInverse = ep?.is_inverse;

  useEffect(() => {
    const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
    const socket = new WebSocket(`${protocol}://${window.location.host}/ws/prices/`);

    if (socketRef.current) {
      subscribeToOrderBook(socket, [exchangeName], pair);
    }
    socketRef.current = socket;

    socket.onopen = () => {
      setIsL2DataLoading(true);
      setOrderBookData({});
      orderbookResubscribeTriesRef.current = 0;
      subscribeToOrderBook(socket, [exchangeName], pair);

      setLivePairPrice('');
      livePairPriceResubscribeTriesRef.current = 0;
      subscribeToPairPrice(socket, exchangeName, pair);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === 'order_book_update') {
        orderbookLastUpdatedAtRef.current = moment.utc();
        setOrderBookData({ ...orderBookData, [data.exchange]: data.book });
        orderbookResubscribeTriesRef.current = 0;
      } else if (data.type === 'price_update') {
        priceLastUpdatedAtRef.current = moment.utc();
        setLivePairPrice(data.price);
        livePairPriceResubscribeTriesRef.current = 0;
      }
      setIsL2DataLoading(false);
      setNoL2Data(false);
    };

    const keepAliveIntervalId = setInterval(() => {
      sendKeepAlive(socket);

      const priceLastUpdated = priceLastUpdatedAtRef.current;

      if (
        socket.readyState === WebSocket.OPEN &&
        priceLastUpdated.isBefore(moment.utc().subtract(6, 'seconds')) &&
        livePairPriceResubscribeTriesRef.current < 3
      ) {
        subscribeToPairPrice(socket, exchangeName, pair);
        livePairPriceResubscribeTriesRef.current += 1;
      }

      const orderBookLastUpdated = orderbookLastUpdatedAtRef.current;
      if (
        socket.readyState === WebSocket.OPEN &&
        orderBookLastUpdated.isBefore(moment.utc().subtract(6, 'seconds')) &&
        orderbookResubscribeTriesRef.current < 3
      ) {
        subscribeToOrderBook(socket, [exchangeName], pair);
        orderbookResubscribeTriesRef.current += 1;
      } else if (orderbookResubscribeTriesRef.current >= 3) {
        setNoL2Data(true);
        setIsL2DataLoading(false);
      }
    }, 1000);

    return () => {
      if (socketRef.current) {
        unsubscribeToOrderBook(socket, [exchangeName], pair);
        unsubscribeToPairPrice(socket, exchangeName, pair);
        setOrderBookData({});
        setLivePairPrice('');
        setIsL2DataLoading(true);
      }

      clearInterval(keepAliveIntervalId);
    };
  }, [exchangeName, pair]);

  const memoizedPriceData = useMemo(
    () => ({
      orderBookData,
      livePairPrice,
      isL2DataLoading,
      noL2Data,
      contractValue,
      isInverse,
    }),
    [orderBookData, livePairPrice, isL2DataLoading, noL2Data, contractValue, isInverse]
  );

  return <PriceDataContext.Provider value={memoizedPriceData}>{children}</PriceDataContext.Provider>;
}

export const usePriceDataContext = () => useContext(PriceDataContext);
