import { Tooltip, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import QueryStatsIcon from '@mui/icons-material/QueryStatsOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { useNavigate } from 'react-router-dom';
import { ApiError, pauseMultiOrder, pauseOrder, resumeMultiOrder, resumeOrder } from '@/apiServices';
import { getOrderPath } from './util';
import { BASEURL, removeFalsyAndEmptyKeys } from '../../util';

const handlePause = async (id, orderType, setErrorContent, setHasError, orderRefresh) => {
  try {
    if (orderType === 'Multi') {
      await pauseMultiOrder(id);
    } else {
      await pauseOrder(id);
    }
    setErrorContent({
      severity: 'success',
      message: 'Successfully paused the specified order.',
    });
    orderRefresh();
  } catch (e) {
    if (e instanceof ApiError) {
      setErrorContent({ severity: 'error', message: e.message });
    } else {
      throw e;
    }
  }
  setHasError(true);
};

const handleResume = async (id, orderType, setErrorContent, setHasError, orderRefresh) => {
  try {
    if (orderType === 'Multi') {
      await resumeMultiOrder(id);
    } else {
      await resumeOrder(id);
    }
    setErrorContent({
      severity: 'success',
      message: 'Successfully resumed the specified order.',
    });
    orderRefresh();
  } catch (e) {
    if (e instanceof ApiError) {
      setErrorContent({ severity: 'error', message: e.message });
    } else {
      throw e;
    }
  }

  setHasError(true);
};

export function ResubmitRemainingOrderButton({ disabled, onClick }) {
  const [resubmitRemainingTooltipOpen, setResubmitRemainingTooltipOpen] = useState(false);

  const theme = useTheme();

  return (
    <Tooltip disableHoverListener open={resubmitRemainingTooltipOpen} placement='top' title='Resubmit Remaining Order'>
      <span>
        <IconButton
          aria-label='resubmit_remaining'
          color='main.info2'
          disabled={disabled}
          size='small'
          sx={{
            marginRight: '3px',
            padding: '0',
            ':hover': {
              color: theme.palette.primary.main,
            },
          }}
          variant='contained'
          onClick={(event) => {
            onClick(event);
          }}
          onMouseEnter={() => setResubmitRemainingTooltipOpen(true)}
        >
          <CallMissedOutgoingIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
export function ResubmitOrderButton({ disabled, onClick }) {
  const [resubmitTooltipOpen, setResubmitTooltipOpen] = useState(false);
  const theme = useTheme();

  return (
    <Tooltip disableHoverListener open={resubmitTooltipOpen} placement='top' title='Resubmit Order'>
      <span>
        <IconButton
          aria-label='resubmit'
          color='main.info2'
          disabled={disabled}
          size='small'
          sx={{
            marginRight: '3px',
            padding: '0',
            ':hover': {
              color: theme.palette.primary.main,
            },
          }}
          variant='contained'
          onClick={(event) => {
            onClick(event);
          }}
          onMouseEnter={() => setResubmitTooltipOpen(true)}
          onMouseLeave={() => setResubmitTooltipOpen(false)}
        >
          <ReplayIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
export function ViewOrderButton({ orderRow }) {
  const [viewTooltipOpen, setViewTooltipOpen] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();

  const handleViewOrder = (order, isMiddleClick = false) => {
    const url = getOrderPath(order);

    if (isMiddleClick) {
      window.open(`${BASEURL}${url}`, '_blank');
    } else {
      navigate(url);
    }
  };

  return (
    <Tooltip disableHoverListener open={viewTooltipOpen} placement='top' title='View Order'>
      <span>
        <IconButton
          aria-label='view'
          color='main.info2'
          size='small'
          sx={{
            marginRight: '3px',
            padding: '0',
            ':hover': {
              color: theme.palette.primary.main,
            },
          }}
          variant='outlined'
          onAuxClick={(event) => {
            event.stopPropagation();
            handleViewOrder(orderRow, true);
          }}
          onClick={(event) => {
            event.stopPropagation();
            handleViewOrder(orderRow);
          }}
          onMouseEnter={() => setViewTooltipOpen(true)}
          onMouseLeave={() => setViewTooltipOpen(false)}
        >
          <QueryStatsIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function PauseOrderButton({ orderRow, orderType }) {
  const [pauseTooltipOpen, setPauseTooltipOpen] = useState(false);
  const theme = useTheme();

  return (
    <Tooltip disableHoverListener open={pauseTooltipOpen} placement='top' title='Resume Order'>
      <span>
        <IconButton
          aria-label='resume'
          color='main.info2'
          size='small'
          sx={{
            marginRight: '3px',
            padding: '0',
            ':hover': {
              color: theme.palette.primary.main,
            },
          }}
          variant='contained'
          onClick={(event) => {
            event.stopPropagation();
            handlePause(orderRow.id, orderType);
          }}
          onMouseEnter={() => setPauseTooltipOpen(true)}
          onMouseLeave={() => setPauseTooltipOpen(false)}
        >
          <PlayCircleOutlineIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function ResumeOrderButton({ orderRow, orderType }) {
  const [resumeTooltipOpen, setResumeTooltipOpen] = useState(false);

  const theme = useTheme();

  return (
    <Tooltip disableHoverListener open={resumeTooltipOpen} placement='top' title='Pause Order'>
      <span>
        <IconButton
          aria-label='pause'
          color='main.info2'
          disabled={!['ACTIVE'].includes(orderRow.status) || orderRow.is_simple}
          size='small'
          sx={{
            marginRight: '3px',
            padding: '0',
            ':hover': {
              color: theme.palette.primary.main,
            },
          }}
          variant='contained'
          onClick={(event) => {
            event.stopPropagation();
            handleResume(orderRow.id, orderType);
          }}
          onMouseEnter={() => setResumeTooltipOpen(true)}
          onMouseLeave={() => setResumeTooltipOpen(false)}
        >
          <PauseCircleOutlineIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export function CancelOrderButton({ disabled, onClick }) {
  const [cancelTooltipOpen, setCancelTooltipOpen] = useState(false);
  const theme = useTheme();

  return (
    <Tooltip disableHoverListener open={cancelTooltipOpen} title='Cancel Order'>
      <span>
        <IconButton
          aria-label='cancel'
          color='error'
          disabled={disabled}
          placement='top'
          size='small'
          sx={{
            padding: '0',
          }}
          variant='contained'
          onClick={(event) => {
            onClick(event);
          }}
          onMouseEnter={() => setCancelTooltipOpen(true)}
          onMouseLeave={() => setCancelTooltipOpen(false)}
        >
          <HighlightOffIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
}
