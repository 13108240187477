import { useTheme } from '@emotion/react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useContext, useState, useEffect } from 'react';
import { isEmpty } from '@/util';
import {
  cancelAllOrders,
  cancelOrdersWithParams,
  pauseAllOrders,
  resumeAllOrders,
  resumeOrder,
} from '../../apiServices';
import { ErrorContext } from '../context/ErrorProvider';
import { BasicModal } from '../Modal';
import { CancelOrderModal } from './CancelOrderModal';

export default function ChipStatusFilter({
  typeFilter,
  statusHighlight,
  dashboardView = false,
  setStatusHighlight,
  setTypeFilter,
  loadOrders = undefined,
  optionsView = false,
  paginationView = false,
  selectedPair,
  setPairFilter,
  pairFilter,
  isDev,
  isSuperUser,
  onClickTypeChipCallback = () => {},
  searchParams,
}) {
  const { showAlert } = useContext(ErrorContext);
  const theme = useTheme();

  const [cancelAllModalOpen, setCancelAllModalOpen] = useState(false);
  const [pauseAllModalOpen, setPauseAllModalOpen] = useState(false);
  const [resumeAllModalOpen, setResumeAllModalOpen] = useState(false);

  const handleCancelAllConfirm = async () => {
    setCancelAllModalOpen(false);
    try {
      const response = await cancelAllOrders();
      showAlert({ message: response.message, severity: 'success' });
      if (loadOrders) {
        await loadOrders();
      }
    } catch (e) {
      showAlert({ message: e.message, severity: 'error' });
    }
  };

  const handlePauseAllConfirm = async () => {
    setPauseAllModalOpen(false);
    try {
      const response = await pauseAllOrders();
      showAlert({ message: response.message, severity: 'success' });
      if (loadOrders) {
        await loadOrders();
      }
    } catch (e) {
      showAlert({ message: e.message, severity: 'error' });
    }
  };

  const handleResumeAllConfirm = async () => {
    setResumeAllModalOpen(false);
    try {
      const response = await resumeAllOrders();
      showAlert({ message: response.message, severity: 'success' });
      if (loadOrders) {
        await loadOrders();
      }
    } catch (e) {
      showAlert({ message: e.message, severity: 'error' });
    }
  };

  const handleStatusFilter = (statusType) => {
    onClickTypeChipCallback();

    if (statusHighlight.includes(statusType)) {
      setTypeFilter([]);
      setStatusHighlight([]);
    } else {
      if (statusType !== 'CONDITIONAL') {
        setTypeFilter(['SINGLE']);
      }
      setStatusHighlight([statusType]);
    }
  };

  const handleTypeFilter = (type) => {
    onClickTypeChipCallback();

    setStatusHighlight([]);

    if (typeFilter.includes(type)) {
      if (paginationView) {
        return;
      }
      setTypeFilter([]);
    } else {
      setTypeFilter([type]);
    }
  };

  const handleCancelFilteredConfirm = async () => {
    try {
      const response = await cancelOrdersWithParams(searchParams);
      showAlert({ message: response.message, severity: 'success' });
      if (loadOrders) {
        await loadOrders();
      }
    } catch (e) {
      showAlert({ message: e.message, severity: 'error' });
    } finally {
      setCancelAllModalOpen(false);
    }
  };

  const chipSxProps = {
    borderRadius: '3px',
    minWidth: '80px',
  };

  const multiChainedToggle = (type) => {
    if (type) {
      return type === 'MULTI' || type === 'CHAINED';
    }
    return false;
  };

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      spacing={1}
      style={{
        marginBottom: '10px',
        overflowX: 'auto',
      }}
    >
      <Stack direction='row' spacing={1} style={{ overflowX: 'auto' }}>
        {!optionsView && dashboardView && (
          <Chip
            disabled={!selectedPair}
            label={selectedPair && selectedPair.id ? selectedPair.id : 'BTC-USDT'}
            sx={{
              borderColor: pairFilter ? 'rgba(255, 181, 106, 0.7) ' : theme.palette.text.disabled,
              color: pairFilter ? theme.palette.primary.main : theme.palette.text.disabled,
              ...chipSxProps,
            }}
            variant='outlined'
            onClick={() => setPairFilter(!pairFilter)}
          />
        )}
        <Divider orientation='vertical' style={{ height: 'auto' }} variant='middle' />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('ACTIVE') ? 'primary' : 'info'}
          disabled={multiChainedToggle(typeFilter[0])}
          label='Active'
          sx={chipSxProps}
          variant='outlined'
          onClick={() => handleStatusFilter('ACTIVE')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('CANCELED') ? 'error' : 'info'}
          disabled={multiChainedToggle(typeFilter[0])}
          label='Canceled'
          sx={chipSxProps}
          variant='outlined'
          onClick={() => handleStatusFilter('CANCELED')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('COMPLETE') ? 'success' : 'info'}
          disabled={multiChainedToggle(typeFilter[0])}
          label='Finished'
          sx={chipSxProps}
          variant='outlined'
          onClick={() => handleStatusFilter('COMPLETE')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('SCHEDULED') ? 'secondary' : 'info'}
          disabled={multiChainedToggle(typeFilter[0])}
          label='Scheduled'
          sx={chipSxProps}
          variant='outlined'
          onClick={() => handleStatusFilter('SCHEDULED')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('PAUSED') ? 'secondary' : 'info'}
          disabled={multiChainedToggle(typeFilter[0])}
          label='Paused'
          sx={chipSxProps}
          variant='outlined'
          onClick={() => handleStatusFilter('PAUSED')}
        />
        <Chip
          color={statusHighlight.length > 0 && statusHighlight.includes('CONDITIONAL') ? 'primary' : 'info'}
          label='Conditional'
          sx={chipSxProps}
          variant='outlined'
          onClick={() => handleStatusFilter('CONDITIONAL')}
        />
        <Divider orientation='vertical' style={{ height: 'auto' }} variant='middle' />

        {!optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('SINGLE') ? 'primary' : 'info'}
            label='Single'
            sx={chipSxProps}
            variant='outlined'
            onClick={() => handleTypeFilter('SINGLE')}
          />
        )}
        {!optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('MULTI') ? 'primary' : 'info'}
            label='Multi'
            sx={chipSxProps}
            variant='outlined'
            onClick={() => handleTypeFilter('MULTI')}
          />
        )}
        {isDev && !optionsView && (
          <Chip
            color={typeFilter.length > 0 && typeFilter.includes('CHAINED') ? 'primary' : 'info'}
            label='Chained'
            sx={chipSxProps}
            variant='outlined'
            onClick={() => handleTypeFilter('CHAINED')}
          />
        )}
      </Stack>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {isSuperUser && (
          <>
            <Button
              color='info'
              sx={{
                height: '30px',
                whiteSpace: 'nowrap',
              }}
              variant='text'
              onClick={() => setResumeAllModalOpen(true)}
            >
              Resume All
            </Button>
            <Divider orientation='vertical' style={{ height: '30px' }} />
          </>
        )}
        {isSuperUser && (
          <>
            <Button
              color='warning'
              sx={{
                height: '30px',
                whiteSpace: 'nowrap',
              }}
              variant='text'
              onClick={() => setPauseAllModalOpen(true)}
            >
              Pause All
            </Button>
            <Divider orientation='vertical' style={{ height: '30px' }} />
          </>
        )}
        <Button
          color='error'
          sx={{
            height: '30px',
            whiteSpace: 'nowrap',
          }}
          variant='text'
          onClick={() => setCancelAllModalOpen(true)}
        >
          Cancel All
        </Button>

        <BasicModal
          confirmButtonText='Yes'
          handleConfirm={handleResumeAllConfirm}
          message='Are you sure you want to resume all paused orders?'
          open={resumeAllModalOpen}
          setOpen={setResumeAllModalOpen}
        />

        <BasicModal
          confirmButtonText='Yes'
          handleConfirm={handlePauseAllConfirm}
          message='Are you sure you want to pause all active orders?'
          open={pauseAllModalOpen}
          setOpen={setPauseAllModalOpen}
        />

        <CancelOrderModal
          confirmButtonText='Yes'
          handleCancelAll={handleCancelAllConfirm}
          handleCancelFiltered={handleCancelFilteredConfirm}
          isPaginatedView={paginationView}
          open={cancelAllModalOpen}
          setOpen={setCancelAllModalOpen}
        />
      </Box>
    </Stack>
  );
}
