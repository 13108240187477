import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import {
  AlphaTiltSlider,
  DiscretionSlider,
  ExposureToleranceSlider,
  PassivenessSlider,
} from '../../shared/fields/Sliders';
import { ConditionValidateField, StrategyParamSelect } from '../../shared/fields/StrategyParamSelect';
import TrajectoryDropdown from '../../shared/fields/TrajectoryDropdown';
import * as FormAtoms from '../dashboard/orderEntry/hooks/useFormReducer';
import DurationField from '../dashboard/orderEntry/AlgoOrderFieldsComponents/DurationField';
import MaxClipSizeField from '../dashboard/orderEntry/AlgoOrderFieldsComponents/MaxClipSizeField';

export default function MultiOrderSubmitForm({
  strategies,
  strategyParams,
  selectedStrategy,
  setSelectedStrategy,
  selectedStrategyParams,
  setSelectedStrategyParams,
  selectedDuration,
  setSelectedDuration,
  passiveness,
  setPassiveness,
  discretion,
  setDiscretion,
  exposureTolerance,
  setExposureTolerance,
  orderCondition,
  setOrderCondition,
  isOrderConditionValidated,
  setIsOrderConditionValidated,
  handleSubmit,
  showAlert,
  formState,
  alphaTilt,
  setAlphaTilt,
  maxClipSize,
  setMaxClipSize,
  submitLoading,
  timeStart,
  setTimeStart,
}) {
  const buyOrderItems = formState.buy;
  const sellOrderItems = formState.sell;
  const areOrderItemsValid = Object.values(formState).every((side) => {
    return side.every((item) => item.accounts.length > 0 && item.pair && item.qty);
  });

  const enoughItems = buyOrderItems.length + sellOrderItems.length > 1;

  const orderConditionValid = !orderCondition || isOrderConditionValidated;
  const isReadyToSubmit = areOrderItemsValid && enoughItems && orderConditionValid;

  const handleStrategyParamChange = (event) => {
    setSelectedStrategyParams({
      ...selectedStrategyParams,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <form height='100%' onSubmit={handleSubmit}>
      <Stack direction='row' height='100%' spacing={5}>
        <Box style={{ width: '34%' }}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <TrajectoryDropdown
                disabled={false}
                setTrajectory={setSelectedStrategy}
                trajectories={strategies}
                trajectory={selectedStrategy}
              />
            </Grid>
            <Grid xs={12}>
              <DurationField
                disabled={false}
                FormAtoms={FormAtoms}
                isCalculatingDuration={false}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
                setTimeStart={setTimeStart}
              />
            </Grid>
            <Grid xs={12}>
              <ConditionValidateField
                isOrderConditionValidated={isOrderConditionValidated}
                orderCondition={orderCondition}
                rows={2}
                setIsOrderConditionValidated={setIsOrderConditionValidated}
                setOrderCondition={setOrderCondition}
                showAlert={showAlert}
              />
            </Grid>
            <Grid xs={12}>
              {!submitLoading ? (
                <Button fullWidth disabled={!isReadyToSubmit} sx={{ height: '40px' }} type='submit' variant='contained'>
                  <Typography color={!isReadyToSubmit ? 'grey' : 'text.offBlack'} variant='h6'>
                    Submit Multi Order
                  </Typography>
                </Button>
              ) : (
                <Button disabled fullWidth sx={{ height: '40px' }} type='submit' variant='contained'>
                  <CircularProgress size={20} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box style={{ width: '33%', height: '100%' }}>
          <Stack marginTop='8px' spacing={0}>
            <ExposureToleranceSlider
              exposureTolerance={exposureTolerance}
              setExposureTolerance={setExposureTolerance}
              sx={{ marginBottom: '10px' }}
            />
            <PassivenessSlider
              passiveness={passiveness}
              setPassiveness={setPassiveness}
              sx={{ marginBottom: '10px' }}
            />
            <DiscretionSlider discretion={discretion} setDiscretion={setDiscretion} sx={{ marginBottom: '10px' }} />
            <AlphaTiltSlider alphaTilt={alphaTilt} setAlphaTilt={setAlphaTilt} sx={{ marginBottom: '10px' }} />
            <MaxClipSizeField maxClipSize={maxClipSize} setMaxClipSize={setMaxClipSize} />
          </Stack>
        </Box>
        <Box sx={{ width: '33%', height: '100%' }}>
          <StrategyParamSelect
            handleStrategyParamChange={handleStrategyParamChange}
            selectedStrategyParams={selectedStrategyParams}
            strategyParams={strategyParams}
          />
        </Box>
      </Stack>
    </form>
  );
}
