import { Alert, Snackbar, Typography } from '@mui/material';
import React, { useCallback, useState, createContext, useMemo } from 'react';

export const ErrorContext = createContext('');

export function ErrorProvider(props) {
  const { children } = props;

  const [hasError, setHasError] = useState(false);
  const [errorContent, setErrorContent] = useState({
    severity: 'info',
    message: '',
  });

  const showAlert = useCallback(({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setHasError(false);
  };

  const memomizedErrorStates = useMemo(() => ({ setHasError, setErrorContent, showAlert }), []);

  return (
    <div>
      <ErrorContext.Provider value={memomizedErrorStates}>
        <Snackbar autoHideDuration={10000} open={hasError} onClose={handleClose}>
          <Alert severity={errorContent.severity} sx={{ width: '100%' }} onClose={() => setHasError(false)}>
            <Typography>{errorContent.message}</Typography>
          </Alert>
        </Snackbar>
        {children}
      </ErrorContext.Provider>
    </div>
  );
}
