import React, { useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  ListItemButton,
  ListItemText,
  List,
  Collapse,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';

export function NavBarItem({ itemProps, children, disabled = false, isSubItem = false, onClick = null }) {
  if (children) {
    return <NavBarParentItem itemProps={itemProps}>{children}</NavBarParentItem>;
  }

  const { label, icon, path, openTab } = itemProps;

  let ClickComponent;
  if (isSubItem) {
    ClickComponent = MenuItem;
  } else if (icon) {
    ClickComponent = IconButton;
  } else {
    ClickComponent = Button;
  }

  const action = { to: path, target: openTab ? '_blank' : '', onClick };
  return (
    <ClickComponent component={Link} disabled={disabled} {...action}>
      {label && <Typography color={disabled ? 'text.disabled' : 'text.main'}>{label}</Typography>}
      {icon}
    </ClickComponent>
  );
}

function NavBarParentItem({ itemProps, children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const subItems = children.length > 1 ? children : [children];

  return (
    <>
      <NavBarItem itemProps={itemProps} onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {subItems.map((item) => {
          return item ? React.cloneElement(item, { onClick: handleClose }) : null;
        })}
      </Menu>
    </>
  );
}

const SIZE_TO_TEXT = {
  2: 'h5',
  1: 'h6',
  0: 'body1',
};

export function MobileNavBarItem({
  itemProps,
  children,
  disabled = false,
  size = 2,
  isSubItem = false,
  onClick = null,
  endIcon = null,
}) {
  if (children) {
    return (
      <MobileNavBarParentItem itemProps={itemProps} size={size}>
        {children}
      </MobileNavBarParentItem>
    );
  }

  const { label, path, openTab } = itemProps;
  const textVariant = isSubItem ? SIZE_TO_TEXT[Math.max(0, size - 1)] : SIZE_TO_TEXT[size];

  const action = { to: path, target: openTab ? '_blank' : '', onClick };
  return (
    <ListItemButton component={Link} disabled={disabled} sx={{ pl: isSubItem ? 4 : 2 }} {...action}>
      <ListItemText primary={<Typography variant={textVariant}>{label}</Typography>} />
      {endIcon}
    </ListItemButton>
  );
}

function MobileNavBarParentItem({ itemProps, size, children }) {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <MobileNavBarItem
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        itemProps={itemProps}
        size={size}
        onClick={handleClick}
      />
      <Collapse in={open} timeout='auto'>
        <List disablePadding>{children}</List>
      </Collapse>
    </>
  );
}
