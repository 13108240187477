import { useTheme } from '@emotion/react';
import { Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, Box, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { cancelChainedOrder, pauseChainedOrder, resumeChainedOrder } from '../../../apiServices';
import ProgressBar from '../../../shared/fields/ProgressBar/ProgressBar';
import { BasicModal } from '../../../shared/Modal';
import {
  StyledBenchmarkTableCell,
  StyledIBMTypography,
  StyledSummaryTableCell,
  formatDateTime,
} from '../../../shared/orderTable/util';
import { BASEURL, calculateDurationDisplay } from '../../../util';

function ChainedSummaryRender({ orderSummaryData, ordersInChain, accountNames }) {
  const theme = useTheme();

  if (!orderSummaryData) {
    return (
      <Box alignItems='center' display='flex' height='100%' justifyContent='center'>
        <ScaleLoader color={theme.palette.text.primary} />
      </Box>
    );
  }

  const {
    id,
    executed_notional,
    time_start,
    alpha_tilt,
    status,
    notes,
    pov_limit,
    pov_target,
    strategy_params,
    order_condition_normal,
  } = orderSummaryData;

  let maxPriority = 1;
  let orderDuration = 0;

  if (ordersInChain && ordersInChain.length > 0) {
    ordersInChain.forEach((order) => {
      if (order.priority && order.priority > maxPriority) {
        maxPriority = order.priority;
      }
      if (order) {
        orderDuration += order.duration || orderDuration;
      }
    });
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledSummaryTableCell colSpan='2'>
            <Typography color='primary' variant='h6'>
              {id}
            </Typography>
          </StyledSummaryTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <StyledSummaryTableCell>Accounts</StyledSummaryTableCell>
          <StyledSummaryTableCell>{accountNames?.join(' | ') || <i>No Account Data</i>}</StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell>Time Start</StyledSummaryTableCell>
          <StyledSummaryTableCell>{formatDateTime(time_start)}</StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell>Orders in Chain</StyledSummaryTableCell>
          <StyledSummaryTableCell>{maxPriority}</StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell>Total Duration</StyledSummaryTableCell>
          <StyledSummaryTableCell>{calculateDurationDisplay(orderDuration)}</StyledSummaryTableCell>
        </TableRow>
        <TableRow>
          <StyledSummaryTableCell>Executed Notional</StyledSummaryTableCell>
          <StyledSummaryTableCell>${executed_notional}</StyledSummaryTableCell>
        </TableRow>
        {notes && (
          <TableRow>
            <StyledSummaryTableCell>
              <Typography>Notes</Typography>
            </StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Typography>{notes}</Typography>
            </StyledSummaryTableCell>
          </TableRow>
        )}
        {order_condition_normal && (
          <TableRow>
            <StyledSummaryTableCell>
              <Typography>Order Condition</Typography>
            </StyledSummaryTableCell>
            <StyledSummaryTableCell>
              <Typography>{order_condition_normal}</Typography>
            </StyledSummaryTableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function ChainedOrderActions({ ordersInChain, orderSummaryData, showAlert, loadOrderData }) {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');
  const [handleConfirm, setHandleConfirm] = useState(() => {});

  const { id, status } = orderSummaryData || {};
  const isPaused = status === 'PAUSED';

  const isTerminated =
    Array.isArray(ordersInChain) &&
    ordersInChain.length > 0 &&
    ordersInChain.every((order) => ['COMPLETE', 'CANCELED'].includes(order.status));

  const handleAction = async (action) => {
    setConfirmModalOpen(false);
    try {
      await action(id);
      loadOrderData(id);
    } catch (error) {
      showAlert({ message: error.message, severity: 'error' });
    }
  };

  return (
    <Box alignItems='center' display='flex' justifyContent='center' sx={{ width: '100%', height: '100%' }}>
      <Stack direction='row' spacing={1} sx={{ width: '100%', marginX: '8px' }}>
        {/* <Button
          fullWidth
          color="secondary"
          disabled={isTerminated}
          variant="contained"
          onClick={() => {
            setHandleConfirm(() => isPaused ? () => handleAction(resumeChainedOrder) : () => handleAction(pauseChainedOrder));
            setConfirmModalText(`Are you sure you want to ${isPaused ? "resume" : "pause"} this order?`);
            setConfirmModalOpen(true);
          }}
        >
          {isPaused ? 'Resume' : 'Pause'}
        </Button> */}
        <Button
          fullWidth
          color='error'
          disabled={isTerminated}
          variant='contained'
          onClick={() => {
            setConfirmModalText('Are you sure you want to cancel this order?');
            setHandleConfirm(() => () => handleAction(cancelChainedOrder));
            setConfirmModalOpen(true);
          }}
        >
          Cancel
        </Button>
      </Stack>
      <BasicModal
        confirmButtonText='Yes'
        handleConfirm={handleConfirm}
        message={confirmModalText}
        open={confirmModalOpen}
        setOpen={setConfirmModalOpen}
      />
    </Box>
  );
}

function ChainedOrderBenchmark({ benchmarkData, chainedOrderDetailView = false }) {
  const theme = useTheme();

  if (!benchmarkData || Object.keys(benchmarkData).length === 0) {
    return (
      <Box alignItems='center' display='flex' height='100%' justifyContent='center'>
        <ScaleLoader color='#FFFFFF' />
      </Box>
    );
  }

  const { arrival_cost, notional_exposure, vwap_cost, fee_notional } = benchmarkData;

  return (
    <Stack direction='column' spacing={2}>
      <Typography variant='h4'>Benchmarks</Typography>
      <Table>
        <TableBody>
          <TableRow>
            <StyledBenchmarkTableCell>Arrival</StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell>
              <StyledIBMTypography
                color={arrival_cost > 0 ? theme.palette.charts.red : theme.palette.charts.green}
                style={{ textAlign: 'right' }}
              >
                {Number(arrival_cost).toFixed(6)}
              </StyledIBMTypography>
            </StyledBenchmarkTableCell>
          </TableRow>
          <TableRow>
            <StyledBenchmarkTableCell>VWAP</StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell>
              <StyledIBMTypography
                color={vwap_cost > 0 ? theme.palette.charts.red : theme.palette.charts.green}
                style={{ textAlign: 'right' }}
              >
                {Number(vwap_cost).toFixed(6)}
              </StyledIBMTypography>
            </StyledBenchmarkTableCell>
          </TableRow>
          <TableRow>
            <StyledBenchmarkTableCell>Exchange Fee</StyledBenchmarkTableCell>
            <StyledBenchmarkTableCell>
              <StyledIBMTypography style={{ textAlign: 'right' }}>
                ${Number(fee_notional).toFixed(6)}
              </StyledIBMTypography>
            </StyledBenchmarkTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}

export { ChainedOrderActions, ChainedOrderBenchmark, ChainedSummaryRender };
