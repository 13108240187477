import { useTheme } from '@emotion/react';
import { LinearProgress, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useAtom } from 'jotai';
import React from 'react';
import { formatPrice, msAndKs } from '../../../util';
import { limitPriceAtom } from '../orderEntry/hooks/useFormReducer';

function OrderBookChart({ orderBookDataByExchange, contractValue, isInverse }) {
  const theme = useTheme();
  const base = orderBookDataByExchange.pair.split('-')[0];
  const quote = orderBookDataByExchange.pair.split('-')[1];
  const [limitPrice, setLimitPrice] = useAtom(limitPriceAtom);

  const handleOnClick = (e, price) => {
    setLimitPrice(price);
  };

  const calculateCumulativeTotal = (orders, ascending = true) => {
    let cumulativeTotal = 0;
    const sortedOrders = [...orders].sort((a, b) => (ascending ? a.price - b.price : b.price - a.price));

    return sortedOrders.map((order) => {
      cumulativeTotal += order.y;
      return {
        ...order,
        total: cumulativeTotal,
      };
    });
  };

  const orderBookData = orderBookDataByExchange;

  orderBookData.bids = calculateCumulativeTotal(orderBookDataByExchange.bids, false);
  orderBookData.asks = calculateCumulativeTotal(orderBookDataByExchange.asks, true);

  const renderOrderRow = (type, data, maxSize) => {
    const { price, total } = data;
    const barWidth = (total / maxSize) * 100;
    const quoteValue = isInverse ? total * contractValue : total * price * contractValue;

    return (
      <Box alignItems='center' display='flex' key={price} marginBottom={1} position='relative' width='100%'>
        <Box
          alignItems='center'
          display='flex'
          left={0}
          position='absolute'
          width='100%'
          onClick={(e) => {
            handleOnClick(e, price);
          }}
        >
          <Typography
            color={type === 'bid' ? 'charts.green' : 'charts.red'}
            fontFamily='monospace'
            sx={{
              width: '70px',
              textAlign: 'left',
              zIndex: 1,
              left: '5%',
              position: 'absolute',
            }}
            variant='body1'
          >
            {formatPrice(price)}
          </Typography>
          <Box flexGrow={1}>
            <LinearProgress
              sx={{
                minHeight: '25px',
                width: '100%',
                marginTop: '0.2rem',
                backgroundColor: 'card.main',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: type === 'bid' ? 'orderBook.bid' : 'orderBook.ask',
                },
              }}
              value={barWidth}
              variant='determinate'
            />
          </Box>
          <Typography
            fontFamily='monospace'
            sx={{
              width: '50px',
              left: '48%',
              textAlign: 'right',
              position: 'absolute',
              zIndex: 1,
            }}
            variant='body1'
          >
            {msAndKs(total, 4)}
          </Typography>
          <Typography
            fontFamily='monospace'
            sx={{
              width: '70px',
              textAlign: 'right',
              right: 0,
              position: 'absolute',
              zIndex: 1,
            }}
            variant='body1'
          >
            {msAndKs(quoteValue, 4)}
          </Typography>
        </Box>
      </Box>
    );
  };

  const maxSize = Math.max(
    ...orderBookData.bids.map((bid) => bid.total),
    ...orderBookData.asks.map((ask) => ask.total)
  );

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Box>
        <Stack
          direction='column'
          spacing={3}
          sx={{
            width: '100%',
            height: '100%',
            paddingBottom: '2px',
          }}
        >
          <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={2}>
            <Typography sx={{ textAlign: 'right', paddingLeft: '5%' }} variant='body2'>
              Price
            </Typography>
            <Typography sx={{ textAlign: 'right', paddingLeft: '5%' }} variant='body2'>
              Total ({base})
            </Typography>
            <Typography align='center' variant='body2'>
              Total ({quote})
            </Typography>
          </Stack>

          {orderBookData.asks.reverse().map((ask) => renderOrderRow('ask', ask, maxSize))}
        </Stack>
      </Box>
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          backgroundColor: theme.palette.grey.dark,
          minHeight: '25px',
        }}
      >
        <Typography fontFamily='monospace' sx={{ left: '5%', position: 'absolute' }} variant='body1'>
          {orderBookData.mid}
        </Typography>

        <Typography
          fontFamily='monospace'
          sx={{
            position: 'absolute',
            textAlign: 'right',
            width: '90%',
          }}
          variant='body1'
        >
          Spread {Number(orderBookData.spread_bps).toFixed(2)}bps
        </Typography>
      </Stack>
      <Box>
        <Stack
          direction='column'
          spacing={3}
          sx={{
            width: '100%',
            height: '100%',
            paddingTop: '0px',
          }}
        >
          {orderBookData.bids.map((bid) => renderOrderRow('bid', bid, maxSize))}
        </Stack>
      </Box>
    </Stack>
  );
}

export default OrderBookChart;
