import ConsensusChip from '@/shared/components/ConsensusChip';
import { CopyableValue } from '@/shared/components/CopyableValue';
import { Loader } from '@/shared/Loader';
import { Alert, Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { insertEllipsis } from '../insertEllipsis';
import useDataConsensus from '../proofUtils/useDataConsensus';
import AttestationDetailRow from './components/AttestationDetailRow';

/**
 * Component that displays data attestation events for a specific trader epoch.
 * Shows details like merkle hash, CID, block number, transaction hash and attester address.
 * Aggregates data from multiple transactions to determine consensus.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array<string>} props.txHashes - Array of transaction hashes to fetch data for
 * @param {Object} props.config - Configuration object for blockchain connection
 * @param {string} [props.traderId] - Optional trader ID to use for navigation
 * @param {string|number} [props.epoch] - Optional epoch to use for navigation
 * @returns {React.ReactElement} Data attestation section
 */
export default function DataEventSection({ txHashes = [], config, traderId: propTraderId, epoch: propEpoch }) {
  const { dataEvents, dataConsensus, aggregatedConsensus, loading, error } = useDataConsensus(txHashes, config);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Try to get traderId and epoch from route params if not provided as props
  const routeParams = useParams();
  const routeTraderId = routeParams.traderId;
  const routeEpoch = routeParams.epoch;

  // Use props first, then route params, then try to get from first event
  const firstEvent = dataEvents && dataEvents.length > 0 ? dataEvents[0] : null;

  console.log('[DataEventSection] txHashes:', txHashes);
  console.log('[DataEventSection] dataEvents:', dataEvents);
  console.log('[DataEventSection] dataConsensus:', dataConsensus);
  console.log('[DataEventSection] aggregatedConsensus:', aggregatedConsensus);
  console.log('[DataEventSection] propTraderId:', propTraderId, 'propEpoch:', propEpoch);
  console.log('[DataEventSection] routeTraderId:', routeTraderId, 'routeEpoch:', routeEpoch);
  console.log('[DataEventSection] firstEvent:', firstEvent);

  const handleMatchedClick = (event) => {
    // Determine which traderId and epoch to use for navigation
    const navigateTraderId = propTraderId || routeTraderId || (firstEvent && firstEvent.traderId);
    const navigateEpoch = propEpoch || routeEpoch || (firstEvent && firstEvent.epoch);

    if (navigateTraderId && navigateEpoch) {
      console.log('[DataEventSection] Navigating to:', navigateTraderId, navigateEpoch);
      navigate(`/explorer/trader-epoch/${navigateTraderId}/${navigateEpoch}/data-consensus`);
    } else {
      console.error('[DataEventSection] Cannot navigate: missing traderId or epoch', {
        propTraderId,
        propEpoch,
        routeTraderId,
        routeEpoch,
        firstEvent,
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (txHash) => {
    navigate(`/explorer/proofs/${txHash}`);
    handleClose();
  };

  if (!txHashes || txHashes.length === 0) {
    console.log('[DataEventSection] No txHashes provided');
    return (
      <Box>
        <Typography variant='subtitle1'>Data Consensus</Typography>
        <Alert severity='info'>No data attestation available for this epoch.</Alert>
      </Box>
    );
  }

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      console.error('[DataEventSection] Error:', error);
      return <Alert severity='error'>Failed to load data attestation. Please try again later.</Alert>;
    }

    if (!dataEvents || dataEvents.length === 0) {
      console.log('[DataEventSection] No data events found');
      return <Alert severity='info'>No data attestation data found.</Alert>;
    }

    // Use the first event for display purposes
    const displayEvent = dataEvents[0];
    console.log('[DataEventSection] First event:', displayEvent);

    // Determine consensus status
    const hasConsensus = dataConsensus?.hasConsensus || false;
    const matchedCount = aggregatedConsensus?.count || 0;
    const totalCount = aggregatedConsensus?.total || 0;

    // Get the merkle root with detailed logging
    let merkleRoot;

    if (aggregatedConsensus?.merkleRoot) {
      // Prioritize using the aggregated merkle root
      merkleRoot = aggregatedConsensus.merkleRoot;
      console.log('[DataEventSection] Using aggregated merkleRoot:', merkleRoot);
      console.log('[DataEventSection] merkleRoot type:', typeof merkleRoot);
    } else if (hasConsensus && dataConsensus?.record?.merkleRoot) {
      // Fallback to consensus merkle root if aggregated is not available
      merkleRoot = dataConsensus.record.merkleRoot;
      console.log('[DataEventSection] Using consensus merkleRoot:', merkleRoot);
      console.log('[DataEventSection] merkleRoot type:', typeof merkleRoot);
    } else {
      // Fallback to the first event's merkle root if available
      merkleRoot = displayEvent.data?.merkleRoot;
      console.log('[DataEventSection] Using first event merkleRoot:', merkleRoot);
      console.log('[DataEventSection] merkleRoot type:', typeof merkleRoot);
    }

    // Check if merkleRoot is an object and needs special handling
    if (merkleRoot && typeof merkleRoot === 'object') {
      console.log('[DataEventSection] merkleRoot is an object:', merkleRoot);
      // Try to extract the actual value if it's an ethers BigNumber or similar
      if (merkleRoot.toString) {
        merkleRoot = merkleRoot.toString();
        console.log('[DataEventSection] Converted merkleRoot to string:', merkleRoot);
      } else if (merkleRoot.hex) {
        merkleRoot = merkleRoot.hex;
        console.log('[DataEventSection] Extracted hex value:', merkleRoot);
      } else {
        // Last resort: stringify the object
        merkleRoot = JSON.stringify(merkleRoot);
        console.log('[DataEventSection] Stringified merkleRoot:', merkleRoot);
      }
    }

    // Format the merkle root for display
    if (merkleRoot && merkleRoot.startsWith('0x')) {
      // If it's a hex string, ensure it's properly formatted
      // Remove '0x' prefix if needed for display
      const formattedRoot = merkleRoot.toLowerCase();
      console.log('[DataEventSection] Formatted hex merkleRoot:', formattedRoot);
      merkleRoot = formattedRoot;
    }

    // Ensure we have a string
    merkleRoot = String(merkleRoot || 'No merkle root available');
    console.log('[DataEventSection] Final merkleRoot to display:', merkleRoot);

    return (
      <Box>
        <Stack direction='row' mb={2} spacing={0}>
          <Box width='280px'>
            <Typography variant='subtitle1'>Data Consensus</Typography>
          </Box>
          <Box>
            <Stack alignItems='center' direction='row' spacing={2}>
              <Button
                size='small'
                sx={{
                  color: 'primary.main',
                  fontSize: '0.875rem',
                  fontWeight: 'normal',
                  padding: '0',
                  minWidth: 'auto',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                  },
                }}
                variant='text'
                onClick={handleMatchedClick}
              >
                {matchedCount}/{totalCount} Matched
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {txHashes.map((txHash) => (
                  <MenuItem key={txHash} onClick={() => handleMenuItemClick(txHash)}>
                    {insertEllipsis(txHash, 8, 6)}
                  </MenuItem>
                ))}
              </Menu>
              <ConsensusChip hasConsensus={hasConsensus} />
            </Stack>
          </Box>
        </Stack>
        <AttestationDetailRow content={<CopyableValue value={merkleRoot} />} labelValue='Merkle Root' />
      </Box>
    );
  };

  return renderContent();
}
