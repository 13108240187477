import ConsensusChip from '@/shared/components/ConsensusChip';
import { Loader } from '@/shared/Loader';
import { Alert, Box, Button, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertEllipsis } from '../insertEllipsis';
import useRiskConsensus from '../proofUtils/useRiskConsensus';
import AttestationDetailRow from './components/AttestationDetailRow';

/**
 * Determines the consensus title based on parameter ID and name
 * @param {string} parameterId - The ID of the parameter
 * @param {string|null} parameterName - The name of the parameter, if available
 * @returns {string} The formatted consensus title
 */
function getConsensusTitle(parameterId, parameterName) {
  if (parameterId === '1') {
    return 'Volume Consensus';
  }

  if (parameterName) {
    return `${parameterName.charAt(0).toUpperCase() + parameterName.slice(1)} Consensus`;
  }

  return `Parameter ${parameterId} Consensus`;
}

/**
 * Component that displays a simplified view of risk consensus for a parameter
 * @param {Object} props - Component props
 * @param {Array<string>} props.txHashes - Transaction hashes for this parameter
 * @param {Object} props.config - Configuration object
 * @param {string} props.parameterId - Parameter ID for this group
 * @param {Array<Object>} props.riskEvents - Risk events for this parameter
 * @param {string} props.traderId - Trader ID for navigation
 * @param {string|number} props.epoch - Epoch number for navigation
 * @returns {React.ReactElement} Simplified risk consensus view
 */
function ParameterConsensusView({ txHashes, config, parameterId, riskEvents, traderId, epoch }) {
  const { aggregatedConsensus, riskConsensus, loading, error, parameterName } = useRiskConsensus(
    txHashes,
    config,
    parameterId,
    riskEvents
  );
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMatchedClick = (event) => {
    if (txHashes.length === 1) {
      navigate(`/explorer/proofs/${txHashes[0]}`);
    } else if (txHashes.length > 1) {
      // If we have traderId and epoch, navigate to the risk consensus page
      if (traderId && epoch) {
        navigate(`/explorer/trader-epoch/${traderId}/${epoch}/risk-consensus`);
      } else {
        // Fall back to showing the menu if we don't have traderId and epoch
        setAnchorEl(event.currentTarget);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (txHash) => {
    navigate(`/explorer/proofs/${txHash}`);
    handleClose();
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity='error'>Failed to load risk consensus. Please try again later.</Alert>;
  }

  if (!riskEvents || riskEvents.length === 0) {
    return <Alert severity='info'>No risk attestation data found.</Alert>;
  }

  const hasConsensus = riskConsensus?.hasConsensus || false;
  const value = aggregatedConsensus?.value?.toLocaleString() || 'Unknown';
  const matchedCount = aggregatedConsensus?.count || 0;
  const totalCount = aggregatedConsensus?.total || 0;

  // Determine the consensus title before rendering
  const consensusTitle = getConsensusTitle(parameterId, parameterName);

  return (
    <Box mb={3}>
      <Stack direction='row' mb={2} spacing={0}>
        <Box width='280px'>
          <Typography variant='subtitle1'>{consensusTitle}</Typography>
        </Box>
        <Box>
          <Stack alignItems='center' direction='row' spacing={2}>
            <Button
              size='small'
              sx={{
                color: 'primary.main',
                fontSize: '0.875rem',
                fontWeight: 'normal',
                minWidth: 'auto',
                padding: '0',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
              variant='text'
              onClick={handleMatchedClick}
            >
              {matchedCount}/{totalCount} Matched
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {txHashes.map((txHash) => (
                <MenuItem key={txHash} onClick={() => handleMenuItemClick(txHash)}>
                  {insertEllipsis(txHash, 8, 6)}
                </MenuItem>
              ))}
            </Menu>
            <ConsensusChip hasConsensus={hasConsensus} />
          </Stack>
        </Box>
      </Stack>

      <AttestationDetailRow content={<Typography>{value}</Typography>} labelValue='Value' />
    </Box>
  );
}

/**
 * Component that displays risk attestation events grouped by parameter
 */
export default function RiskEventsSection({ config, riskEventsByParameterId, txHashes, traderId, epoch }) {
  console.log('[RiskEventsSection] riskEventsByParameterId:', riskEventsByParameterId);
  if (!txHashes?.length) {
    return (
      <Box>
        <Typography variant='h6'>Risk Attestations</Typography>
        <Alert severity='info'>No risk attestations available for this epoch.</Alert>
      </Box>
    );
  }

  if (!riskEventsByParameterId || Object.keys(riskEventsByParameterId).length === 0) {
    return (
      <Box>
        <Typography variant='h6'>Risk Attestations</Typography>
        <Alert severity='info'>No grouped risk attestations available.</Alert>
      </Box>
    );
  }

  return (
    <Box>
      {Object.entries(riskEventsByParameterId).map(([parameterId, events]) => {
        // Derive transaction hashes from the events
        const hashes = events.map((event) => event.transactionHash);

        return (
          <ParameterConsensusView
            config={config}
            epoch={epoch}
            key={parameterId}
            parameterId={parameterId}
            riskEvents={events}
            traderId={traderId}
            txHashes={hashes}
          />
        );
      })}
    </Box>
  );
}
