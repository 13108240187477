import React from 'react';
import { Paper, ThemeProvider, SwipeableDrawer, IconButton, Stack, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import { theme } from '@/theme/theme';

export function AccountBalanceLayout({ leftPanel, children }) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={1} style={{ height: '100%' }}>
        <Grid style={{ height: '100%' }} xs={4}>
          <Paper elevation={0} sx={{ height: '100%', overflowY: 'auto' }}>
            {leftPanel}
          </Paper>
        </Grid>
        <Grid sx={{ height: '100%' }} xs={8}>
          <Paper elevation={0} sx={{ height: '100%', overflowY: 'auto' }}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export function MobileAccountBalanceLayout({ leftPanel, children, isAccountSelected, onClose }) {
  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={0} sx={{ height: '100%', width: '100%' }}>
        {leftPanel}
      </Paper>
      <SwipeableDrawer
        anchor='right'
        elevation={0}
        ModalProps={{ keepMounted: false }}
        open={isAccountSelected}
        PaperProps={{
          sx: {
            width: '100%',
          },
        }}
        style={{ width: '100%' }}
        onClose={onClose}
      >
        <Stack direction='column'>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              bgcolor: '#141414',
              p: 2,
              display: 'flex',
              justifyContent: 'end',
              zIndex: 2,
            }}
          >
            <IconButton onClick={onClose}>
              <Close fontSize='small' />
            </IconButton>
          </Box>
          {children}
        </Stack>
      </SwipeableDrawer>
    </ThemeProvider>
  );
}
