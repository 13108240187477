import treadDark from './tread-logo-dark.png';
import treadFullDark from './full-tread-dark.png';
import treadFullDarkSvg from './tread-full-dark.svg';
import treadRoundedSvg from './tread-logo-rounded.svg';
import discord from './discord.svg';
import telegram from './telegram.png';

const LOGOS = {
  treadDark,
  treadFullDark,
  treadFullDarkSvg,
  treadRoundedSvg,
  discord,
  telegram,
};

export default LOGOS;
