import { AccountsContext } from '@/shared/context/AccountsProvider';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import { useTheme } from '@emotion/react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Link,
  NativeSelect,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { selectConfig } from '../proofUtils/ProofConfig';
import { useProofsCache } from '../proofUtils/useProofsCache';
import { useTradeBlobsPagination } from '../tradeUtils/useTradesPagination';
import ConsensusInfoSection from './ConsensusInfoSection';
import TradeHistoryTable from './TradeHistoryTable';

/**
 * Component that displays detailed information about a trader's epoch, including data and risk attestation events.
 * Shows transaction hashes and detailed event data if the user is authorized to view it.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string} props.traderId - ID of the trader to display details for
 * @param {number|string} props.epoch - Epoch number to display details for
 * @returns {React.ReactElement} Card containing trader epoch details or error message
 */
export default function TraderEpochDetailsTable({ traderId, epoch }) {
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);
  const { isDev } = useUserMetadata();
  const [selectedTradeIndex, setSelectedTradeIndex] = useState(0);
  const config = selectConfig(isDev);

  // Use proofs cache instead of trader epoch details
  const { proofs } = useProofsCache(); // proofs are now already sorted
  const proofDetails = useMemo(() => {
    if (!traderId || !epoch) return null;

    const proof = proofs.find((p) => p.traderId === traderId && Number(p.epoch) === Number(epoch));

    if (!proof) return null;

    return {
      dataEventTxHashes: proof.dataEvents?.map((e) => e.transactionHash) || [],
      riskEventTxHashes: proof.riskEvents?.map((e) => e.transactionHash) || [],
      loading: false,
      error: null,
    };
  }, [proofs, traderId, epoch]);

  const {
    trades,
    loading: tradesLoading,
    error: tradesError,
    retryFetch: fetchTrades,
  } = useTradeBlobsPagination({
    traderId,
    epoch,
    pageSize: 25,
  });

  const isAuthorized = useMemo(
    () => accounts?.some((acc) => matchesTraderId(acc.hashed_api_key, traderId)),
    [accounts, traderId]
  );

  // Handle trade selection change
  const handleTradeSelectionChange = (event) => {
    setSelectedTradeIndex(event.target.value);
  };

  // Get the selected trade blob
  const selectedTradeBlob = useMemo(() => {
    if (!trades || trades.length === 0) return null;
    return trades[selectedTradeIndex];
  }, [trades, selectedTradeIndex]);

  // Validate inputs
  if (!traderId) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' color='error' variant='h6'>
            Invalid trader ID
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!epoch || Number.isNaN(Number(epoch))) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' color='error' variant='h6'>
            Invalid epoch - must be a number
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!proofDetails) {
    return (
      <Card sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        <CardContent>
          <Typography align='center' variant='h6'>
            No transaction hashes found for this epoch
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const { dataEventTxHashes, riskEventTxHashes } = proofDetails;

  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      {/* Consensus Info Section */}
      <ConsensusInfoSection
        config={config}
        dataEventTxHashes={dataEventTxHashes}
        epoch={epoch}
        riskEventTxHashes={riskEventTxHashes}
        traderId={traderId}
      />

      {/* Trade Info Section */}
      <Paper elevation={1} sx={{ bgcolor: 'background.paper', mb: 4 }}>
        <Box sx={{ px: 8, py: 6 }}>
          {/* Title and Trade Selection in the same row */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 3,
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ mr: 5 }} variant='h5'>
                Trade History for
              </Typography>

              {trades && trades.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControl size='small' sx={{ minWidth: 200 }}>
                    <InputLabel htmlFor='blob-selection' variant='standard'>
                      Storage Entry
                    </InputLabel>
                    <NativeSelect
                      id='blob-selection'
                      inputProps={{
                        id: 'blob-entry-native-select',
                        name: 'blob-entry',
                      }}
                      sx={{ height: '36px' }}
                      value={selectedTradeIndex}
                      onChange={handleTradeSelectionChange}
                    >
                      {trades.map((trade, index) => (
                        <option key={trade.id} value={index}>
                          ID: {trade.id.substring(0, 8)}...
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                </Box>
              )}
            </Box>

            {selectedTradeBlob && (
              <Link
                component={RouterLink}
                sx={{ fontSize: '0.875rem' }}
                to={`/explorer/trades/${selectedTradeBlob.id}`}
                underline='hover'
              >
                View Details
              </Link>
            )}
          </Box>

          <TradeHistoryTable
            hideTitle
            error={tradesError}
            isAuthorized={isAuthorized}
            loading={tradesLoading}
            retryFetch={fetchTrades}
            tradeBlob={selectedTradeBlob}
          />
        </Box>
      </Paper>
    </Stack>
  );
}
