import { useTheme } from '@emotion/react';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { StyledBorderTableCell } from '@/shared/orderTable/util';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { refreshAccountBalanceCache } from '@/apiServices';
import { msAndKs, truncate } from '@/util';
import ExchangeIcons from '../../../images/exchange_icons';

import { balanceToRow } from './util';

export default function AccountSummaryTable({
  balances,
  pastSnapshots,
  setSelectedAccount,
  selectedAccount,
  showAlert,
  getAccounts,
}) {
  const [updatingAccountId, setUpdatingAccountId] = useState('');
  const theme = useTheme();
  const { user } = useUserMetadata();

  const sortedBalances = (balanceInput) => {
    if (balanceInput.length === 0) {
      return [];
    }

    const sortedOtherBalances = balanceInput.sort((a, b) => {
      const aName = a.account_name.toLowerCase();
      const bName = b.account_name.toLowerCase();
      return aName.localeCompare(bName);
    });

    return sortedOtherBalances;
  };

  const refreshCacheOnClick = async (event, accountId) => {
    event.stopPropagation();

    setUpdatingAccountId(accountId);

    try {
      await refreshAccountBalanceCache(accountId);
    } catch (e) {
      showAlert({ severity: 'error', message: e.message });
    }

    getAccounts(false);
    setUpdatingAccountId('');
  };

  const diffStyleByValue = (value) => {
    if (value >= 0) {
      return 'success.main';
    }

    return 'error.main';
  };

  const signByValue = (value) => {
    if (value >= 0) {
      return '+';
    }

    return '-';
  };

  const formatDiff = (value) => {
    const percentage = Number(Math.abs(value));

    return `${signByValue(value)}${percentage.toFixed(2)}%`;
  };

  const handleAccountClick = (account) => {
    const scopedAccName = user.user_id === account.userId ? account.name : `${account.username}/${account.name}`;
    setSelectedAccount([account.accountId, account.name, scopedAccName]);
  };
  const renderRow = (row) => {
    const displayAccName = user.user_id !== row.userId ? `${row.username} - ${row.name}` : row.name;

    return (
      <Table style={{ tableLayout: 'fixed' }} sx={{ marginY: 1 }}>
        <TableBody>
          <TableRow key={`${row.accountId} ${row.name} TableRow`}>
            <StyledBorderTableCell
              align='left'
              key={`${row.accountId} ${row.name} TableCell`}
              style={{
                whiteSpace: 'nowrap',
                padding: '4px 16px',
                width: '100%',
              }}
            >
              <Stack direction='column' spacing={4}>
                <Stack direction='row' spacing={2}>
                  <img
                    alt={row.exchange}
                    src={ExchangeIcons[row.exchange.toLowerCase()]}
                    style={{
                      height: '24px',
                      width: '24px',
                      borderRadius: '50%',
                    }}
                  />
                  <Typography overflow='hidden' textOverflow='ellipsis' variant='body1' whiteSpace='nowrap'>
                    {displayAccName}
                  </Typography>
                </Stack>
                <Stack alignItems='center' direction='row' spacing={1}>
                  <Typography gutterBottom color='text.dark' display='block' variant='caption'>
                    {`${row.lastUpdated}`}
                  </Typography>
                  <Tooltip title='Update to latest account balance'>
                    <IconButton
                      disabled={updatingAccountId === row.accountId}
                      onClick={(e) => refreshCacheOnClick(e, row.accountId)}
                    >
                      <SyncIcon
                        sx={{
                          fontSize: '1rem',
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </StyledBorderTableCell>
            <StyledBorderTableCell
              align='right'
              key='totalNotional'
              style={{
                whiteSpace: 'nowrap',
                padding: '4px 16px',
                width: '50%',
              }}
            >
              <Stack direction='column'>
                <Typography sx={{ fontFamily: 'IBM Plex Mono', marginBottom: '5px' }}>
                  {`$${msAndKs(truncate(row.totalValue || 0))}`}
                </Typography>
                {row.dayAgoDiffPercentage ? (
                  <Box
                    alignItems='center'
                    display='flex'
                    justifyContent='right'
                    marginBottom={row.weekAgoDiff ? '0px' : '5px'}
                    width='100%'
                  >
                    <Typography color={diffStyleByValue(row.dayAgoDiffPercentage)} variant='body2'>
                      {formatDiff(row.dayAgoDiffPercentage)}
                    </Typography>
                    <Typography color='text.subtitle' marginLeft='0.8rem' variant='body2' width='0.8rem'>
                      1d
                    </Typography>
                  </Box>
                ) : null}
                {row.weekAgoDiffPercentage ? (
                  <Box alignItems='center' display='flex' justifyContent='right' marginBottom='5px' width='100%'>
                    <Typography color={diffStyleByValue(row.weekAgoDiffPercentage)} variant='body2'>
                      {formatDiff(row.weekAgoDiffPercentage)}
                    </Typography>
                    <Typography color='text.subtitle' marginLeft='0.8rem' variant='body2' width='0.8rem'>
                      7d
                    </Typography>
                  </Box>
                ) : null}
              </Stack>
            </StyledBorderTableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  return (
    <TableContainer sx={{ maxHeight: '90%' }}>
      {sortedBalances(balances).map((x, i) => {
        const row = balanceToRow(x, pastSnapshots);
        const isSelected = selectedAccount[0] === row.accountId;

        return (
          <Button
            key={`${row.accountId} ${row.name}`}
            style={{
              width: '100%',
              border: `solid 1px ${isSelected ? theme.palette.primary.light : 'transparent'}`,
              backgroundColor: isSelected ? theme.palette.primary.dark : 'inherit',
            }}
            variant='text'
            onClick={() => handleAccountClick(row)}
          >
            {renderRow(row)}
          </Button>
        );
      })}
    </TableContainer>
  );
}
