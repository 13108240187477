/* eslint-disable react/no-danger */
import React from 'react';
import { Modal, Typography, Box, Button, Stack } from '@mui/material';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useViewport from './hooks/useViewport';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40.00rem',
  bgcolor: '#191919',
  boxShadow:
    '0px 9px 46px 8px rgba(0, 0, 0, 0.12),' +
    '0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
  borderRadius: '4px',
  overflow: 'auto',
  fontFamily: "'IBM Plex Mono', monospace",
  color: '#fff',
};

function ModalContainer({ open, onClose, children }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>{children}</Box>
    </Modal>
  );
}

function MobileModalContainer({ open, onClose, children }) {
  return (
    <SwipeableDrawer anchor='top' elevation={0} ModalProps={{ keepMounted: false }} open={open} onClose={onClose}>
      {children}
    </SwipeableDrawer>
  );
}

function VersionUpdateModal({ open, onClose, version, versionContent, onReadMore }) {
  const { isMobile } = useViewport();

  const buttonStyle = {
    fontSize: '0.9rem',
    backgroundColor: 'transparent',
    color: '#FFB56A',
    border: 'none',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#ffc88f',
    },
    '&:focus': {
      outline: 'none',
    },
  };

  const replaceVersionText = (content, v) => {
    return content.replaceAll(/{{TAAS_VERSION}}/g, v);
  };

  const Wrapper = isMobile ? MobileModalContainer : ModalContainer;

  return (
    <Wrapper open={open} onClose={onClose}>
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        <div dangerouslySetInnerHTML={{ __html: replaceVersionText(versionContent, version) }} />
      </Box>
      <Box sx={{ position: 'sticky', bottom: 0, display: 'flex', justifyContent: 'space-between', py: 2, px: 4 }}>
        <Button size='small' sx={buttonStyle} onClick={onClose}>
          Close
        </Button>
        <Button size='small' variant='contained' onClick={onReadMore}>
          <Typography color='inherit' variant='h6'>
            Read More
          </Typography>
        </Button>
      </Box>
    </Wrapper>
  );
}

export default VersionUpdateModal;
