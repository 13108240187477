import React, { createContext, useState, useEffect, useMemo, useCallback } from 'react';
import { getAccounts } from '../../apiServices';

export const AccountsContext = createContext();

export function AccountsProvider({ children }) {
  const [accounts, setAccounts] = useState([]);

  const loadAccounts = useCallback(async () => {
    try {
      const fetchedAccounts = await getAccounts();

      if (!Array.isArray(fetchedAccounts)) {
        console.error('[AccountsProvider] fetchedAccounts is not an array:', typeof fetchedAccounts);
        return;
      }

      // Sort accounts by name for consistency
      const sortedAccounts = fetchedAccounts.sort((a, b) => a.name.localeCompare(b.name));

      // Debug log with safe data only
      console.debug(
        '[AccountsProvider] Processed accounts:',
        JSON.stringify(
          sortedAccounts.map((acc) => ({
            name: acc.name,
            exchange: acc.exchange,
            traderId: acc.hashed_api_key ? `${acc.hashed_api_key.slice(0, 4)}...${acc.hashed_api_key.slice(-4)}` : null,
          })),
          null,
          2
        )
      );

      setAccounts(sortedAccounts);
    } catch (error) {
      console.error('[AccountsProvider] Error in loadAccounts:', error);
    }
  }, []);

  useEffect(() => {
    loadAccounts();
  }, [loadAccounts]);

  const value = useMemo(() => ({ accounts, loadAccounts }), [accounts, loadAccounts]);

  return <AccountsContext.Provider value={value}>{children}</AccountsContext.Provider>;
}
