import {
  ButtonGroup,
  Button,
  Stack,
  Paper,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import { useState } from 'react';
import { OrderInfoTypography } from '@/shared/orderDetail/OrderInfo';
import SingleSplineChart from './graphs/SingleSplineChart';

const marketButtonConstants = {
  market_pnl: 'PnL',
  market_exposure: 'Market Exposure',
  turnover: 'Turnover',
};

const vaultButtonContants = {
  tvl: 'TVL',
  pnl: 'PNL',
};

const timeButtonConstants = {
  '7D': '7D',
  '1M': '1M',
  '3M': '3M',
  '6M': '6M',
};

const renderButtonGroups = ({
  value,
  setter,
  buttonConstants,
  keyName,
  groupVariant,
  height = 'auto',
}) => {
  return (
    <ButtonGroup sx={{ height }} variant={groupVariant}>
      {Object.keys(buttonConstants).map((key) => (
        <Button
          key={`${keyName}_button_${key}`}
          sx={{
            backgroundColor: value === key ? 'primary.main' : 'transparent',
            color: value === key ? 'text.offBlack' : 'test.primary',
          }}
          onClick={() => setter(key)}
        >
          {buttonConstants[key]}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const hardcoded_data = {
  conentration_risk: '45.5%',
  max_drawdown: '4.35%',
  leverage: '3.5x',
  tvl: '$248.31M',
  pnl: '$12.4M',
  market_pnl: '$50.83M',
  market_exposure: '$100',
  turnover: '3.5x',
};

export function PerformancePaper() {
  const [timeButtonValue, setTimeButtonValue] = useState('7D');

  return (
    <Paper elevation={0} sx={{ p: 4 }}>
      <Stack direction='column' spacing={4}>
        <Stack direction='row' justifyContent='space-between' spacing={16}>
          <Stack direction='row' spacing={16}>
            <OrderInfoTypography
              header='My Deposit'
              value='$0.00'
              valueVariant='h3'
            />
            <OrderInfoTypography
              header='All-time Earned'
              value='$0.00'
              valueVariant='h3'
            />
          </Stack>
          {renderButtonGroups({
            value: timeButtonValue,
            setter: setTimeButtonValue,
            buttonConstants: timeButtonConstants,
            keyName: 'performance_time',
            groupVariant: 'outlined',
            height: '34px',
          })}
        </Stack>
        <SingleSplineChart />
      </Stack>
    </Paper>
  );
}

export function MarketDataComponent() {
  const [marketButtonValue, setMarketButtonValue] = useState('market_pnl');
  const [timeButtonValue, setTimeButtonValue] = useState('7D');

  return (
    <Paper elevation={0} sx={{ p: 4 }}>
      <Stack direction='column' spacing={4}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          sx={{ height: 'auto', width: '100%' }}
        >
          {renderButtonGroups({
            value: marketButtonValue,
            setter: setMarketButtonValue,
            buttonConstants: marketButtonConstants,
            keyName: 'market',
            groupVariant: 'text',
          })}
          {renderButtonGroups({
            value: timeButtonValue,
            setter: setTimeButtonValue,
            buttonConstants: timeButtonConstants,
            keyName: 'time',
            groupVariant: 'outlined',
          })}
        </Stack>
        <Typography variant='h2'>
          {hardcoded_data[marketButtonValue]}
        </Typography>

        <Stack direction='row' spacing={2}>
          <Box sx={{ width: '70%' }}>
            <SingleSplineChart />
          </Box>
          <Box sx={{ width: '30%' }}>
            <Paper sx={{ p: 4, height: '350px', borderRadius: '16px' }}>
              <Stack direction='column' spacing={4}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <Typography variant='body3'>Concentration Risk</Typography>
                  <Typography variant='body3'>
                    {hardcoded_data.conentration_risk}
                  </Typography>
                </Stack>
                <Divider fullWidth orientation='horizontal' />

                <Stack
                  direction='row'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <Typography variant='body3'>Max Dropdown</Typography>
                  <Typography variant='body3'>
                    {hardcoded_data.max_drawdown}
                  </Typography>
                </Stack>
                <Divider fullWidth orientation='horizontal' />

                <Stack
                  direction='row'
                  justifyContent='space-between'
                  spacing={2}
                >
                  <Typography variant='body3'>Leverage</Typography>
                  <Typography variant='body3'>
                    {hardcoded_data.leverage}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
}

export function VaultDataComponent() {
  const [vaultButtonValue, setVaultButtonValue] = useState('tvl');
  const [timeButtonValue, setTimeButtonValue] = useState('7D');

  return (
    <Paper elevation={0} sx={{ p: 4 }}>
      <Stack direction='column' spacing={4}>
        <Stack
          alignItems='center'
          direction='row'
          justifyContent='space-between'
          sx={{ height: 'auto', width: '100%' }}
        >
          {renderButtonGroups({
            value: vaultButtonValue,
            setter: setVaultButtonValue,
            buttonConstants: vaultButtonContants,
            keyName: 'market',
            groupVariant: 'text',
          })}
          {renderButtonGroups({
            value: timeButtonValue,
            setter: setTimeButtonValue,
            buttonConstants: timeButtonConstants,
            keyName: 'time',
            groupVariant: 'outlined',
          })}
        </Stack>
        <Typography variant='h2'>{hardcoded_data[vaultButtonValue]}</Typography>
        <SingleSplineChart />
      </Stack>
    </Paper>
  );
}
