import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import LabelTooltip from '@/shared/components/LabelTooltip';

import { StyledIBMTypography } from './orderTable/util';

function PreTradeAnalyticsComponent({ data, loading, dataError }) {
  const theme = useTheme();

  const marketVolume = data.market_volume;

  const generatePredictedPov = () => {
    if (data.pov === null || data.pov === undefined) {
      return <Typography style={{ display: 'inline' }}>N/A</Typography>;
    }
    let color;

    if (data.pov < 0.5) {
      color = theme.palette.success.main;
    } else if (data.pov < 1) {
      color = theme.palette.warning.main;
    } else {
      color = theme.palette.error.main;
    }

    return (
      <StyledIBMTypography color={color} style={{ display: 'inline' }}>
        {data.pov !== null ? Number(data.pov).toFixed(4) : null}%
      </StyledIBMTypography>
    );
  };

  // TODO: remove unused pov code
  const generateGuideline = () => {
    if (data.pov === null || data.pov === undefined) {
      return 'Fill in valid order parameters to see analytics.';
    }

    if (data.pov < 0.5) {
      return 'Minimum impact expected.';
    }
    if (data.pov < 1) {
      return 'Moderate impact expected, consider increasing duration or adding more venues.';
    }

    return 'High impact expected, increasing duration and adding more venues is recommended.';
  };

  const marketVolatilityTooltip = 'Expected price movement during order based on realized volatility.';

  const marketVolumeComponent = () => {
    let message = '';
    let color = '';
    let tag = '';

    if (marketVolume === null || marketVolume === undefined) {
      return (
        <Box display='flex' justifyContent='space-between'>
          <LabelTooltip
            label='Market Volume'
            link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
            placement='left'
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                  Shows market activity over the past 24 hours relative to expected seasonal volume.
                </Typography>
                <Typography>Market volume data not available.</Typography>
              </div>
            }
          />
          <Typography style={{ display: 'inline' }}>N/A</Typography>
        </Box>
      );
    }

    if (marketVolume < 0.5) {
      message = 'Market volume is much lower than expected, trades may experience higher impact.';
      color = theme.palette.error.main;
      tag = 'Low';
    } else if (marketVolume < 0.75) {
      message = 'Market volume is lower than expected.';
      color = theme.palette.warning.main;
      tag = 'Below Average';
    } else if (marketVolume < 1) {
      message = 'Market volume is as expected.';
      color = theme.palette.text.primary;
      tag = 'Normal';
    } else if (marketVolume < 1.5) {
      message = 'Market volume is elevated, favorable market conditions.';
      color = theme.palette.success.main;
      tag = 'Above Average';
    } else {
      message =
        'Market volume is very elevated, favorable market conditions to trade quickly, ' +
        'but be mindful of volatility.';
      color = theme.palette.success.main;
      tag = 'High';
    }

    return (
      <Box display='flex' justifyContent='space-between'>
        <LabelTooltip
          label='Market Volume'
          link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
          placement='left'
          title={
            <div>
              <Typography sx={{ marginBottom: 1.5 }}>
                Shows market activity over the past 24 hours relative to expected seasonal volume.
              </Typography>
              <Typography>{message}</Typography>
            </div>
          }
        />
        <StyledIBMTypography color={color} style={{ display: 'inline' }}>
          {tag} {marketVolume !== null ? Number(marketVolume).toFixed(2) : null}x
        </StyledIBMTypography>
      </Box>
    );
  };

  const isDataAvailable = Object.keys(data).length > 0;

  const renderContent = () => {
    if (loading) {
      return (
        <Box alignItems='center' display='flex' flexDirection='column' minHeight='110px'>
          <Typography my={1} textAlign='left' variant='h6' width='100%'>
            Pre-Trade Analytics
          </Typography>
          <Box
            alignItems='center'
            display='flex'
            flexGrow={1}
            height='100%'
            justifyContent='center'
            sx={{ marginTop: '12px' }}
          >
            <CircularProgress />
          </Box>
        </Box>
      );
    }

    const dataUnavailableMessage = () => {
      if (dataError) {
        return 'Market data unavailable for selected exchange and pair.';
      }

      return 'Input a valid order to view pre-trade analytics.';
    };

    if (!isDataAvailable) {
      return (
        <Stack flexDirection='column' justifyContent='space-between' minHeight='110px' spacing={0.5}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6'>Pre-Trade Analytics</Typography>
            <Box
              sx={{
                marginTop: '12px',
                visibility: dataError ? 'visible' : 'hidden',
              }}
            >
              <Typography color={theme.palette.error.main} sx={{ display: 'inline' }}>
                Unavailable
              </Typography>
            </Box>
          </Box>
          <Box alignItems='center' display='flex' justifyContent='space-between'>
            <LabelTooltip
              label='Participation Rate'
              link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
              placement='left'
              title={
                <div>
                  <Typography sx={{ marginBottom: 1.5 }}>
                    Shows the proportion of the predicted market volume your order is expected to take up during its
                    duration.
                  </Typography>
                  <Typography>{dataUnavailableMessage()}</Typography>
                </div>
              }
            />
            <Typography style={{ display: 'inline' }}>N/A</Typography>
          </Box>

          <Box alignItems='center' display='flex' justifyContent='space-between'>
            <LabelTooltip
              label='Order Volatility'
              link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
              placement='left'
              title={
                <div>
                  <Typography>Expected price movement during order based on realized volatility.</Typography>
                </div>
              }
            />
            <Typography style={{ display: 'inline' }}>N/A</Typography>
          </Box>
          <Box alignItems='center' display='flex' justifyContent='space-between'>
            <LabelTooltip
              label='Market Volume'
              link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
              placement='left'
              title={
                <div>
                  <Typography sx={{ marginBottom: 1.5 }}>
                    Shows market activity over the past 24 hours relative to expected seasonal volume.
                  </Typography>
                  <Typography>{dataUnavailableMessage()}</Typography>
                </div>
              }
            />
            <Typography style={{ display: 'inline' }}>N/A</Typography>
          </Box>
        </Stack>
      );
    }

    return (
      <Stack flexDirection='column' justifyContent='space-between' minHeight='110px' spacing={0.5}>
        <Typography variant='h6'>Pre-Trade Analytics</Typography>
        <Box alignItems='center' display='flex' justifyContent='space-between' sx={{ marginTop: '12px' }}>
          <LabelTooltip
            label='Participation Rate'
            link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
            placement='left'
            title={
              <div>
                <Typography sx={{ marginBottom: 1.5 }}>
                  Shows the proportion of the predicted market volume your order is expected to take up during its
                  duration.
                </Typography>
                <Typography>{generateGuideline()}</Typography>
              </div>
            }
          />
          {generatePredictedPov()}
        </Box>

        <Box alignItems='center' display='flex' justifyContent='space-between'>
          <LabelTooltip
            label='Order Volatility'
            link='https://tread-labs.gitbook.io/api-docs/pre-trade-analytics'
            placement='left'
            title={
              <div>
                <Typography>{marketVolatilityTooltip}</Typography>
              </div>
            }
          />
          <StyledIBMTypography style={{ display: 'inline' }}>
            &plusmn;
            {data.volatility !== null && data.volatility !== undefined ? Number(data.volatility).toFixed(4) : 'N/A'}%
          </StyledIBMTypography>
        </Box>
        {marketVolumeComponent()}
      </Stack>
    );
  };

  return renderContent();
}

export default PreTradeAnalyticsComponent;
