import { AccountsProvider } from '@/shared/context/AccountsProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import { Typography, FormGroup, FormControlLabel, Checkbox, Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useState } from 'react';
import ExplorerProofsTable from './ExplorerProofsTable';
import ExplorerTradesTable from './ExplorerTradesTable';

const columns = [
  { id: 'id', label: 'Transaction ID', width: 260, align: 'left' },
  { id: 'exchange_name', label: 'Exchange', width: 50, align: 'left' },
  { id: 'trader_id', label: 'User', width: 60, align: 'left' },
  { id: 'epoch', label: 'Epoch', width: 30, align: 'left' },
  { id: 'merkle_root', label: 'Hash', width: 80, align: 'left' },
  { id: 'usd_volume', label: 'Volume', width: 30, align: 'right' },
];

function insertEllipsis(str, startSlice = 4, endSlice = 3) {
  if (!str) {
    return '';
  }

  if (str.length <= 7) {
    return str; // No need to shorten
  }

  const start = str.slice(0, startSlice);
  const end = str.slice(-1 * endSlice);

  return `${start}...${end}`;
}

function ExplorerPageContent() {
  const isPreviewOnly = true; // because we are not showing the full table
  const [selectedTables, setSelectedTables] = useState({
    proofs: true,
    trades: false,
  });

  const handleTableSelectionChange = (event) => {
    setSelectedTables({
      ...selectedTables,
      [event.target.name]: event.target.checked,
    });
  };

  // Determine which content to render based on selected tables
  const renderContent = () => {
    if (selectedTables.proofs && selectedTables.trades) {
      // Both tables selected - show side by side
      return (
        <Stack direction='row' height='calc(100% - 100px)' spacing={2}>
          <Box
            sx={{
              textAlign: 'left',
              width: 'calc(50% - 16px)',
            }}
          >
            <ExplorerTradesTable isPreviewOnly={isPreviewOnly} />
          </Box>
          <Box
            sx={{
              textAlign: 'right',
              width: 'calc(50% - 16px)',
            }}
          >
            <ExplorerProofsTable isPreviewOnly={isPreviewOnly} />
          </Box>
        </Stack>
      );
    }

    if (selectedTables.proofs) {
      // Only Proofs table selected - show full width
      return (
        <Box
          sx={{
            height: 'calc(100% - 100px)',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <ExplorerProofsTable isPreviewOnly={isPreviewOnly} />
        </Box>
      );
    }

    if (selectedTables.trades) {
      // Only Trades table selected - show full width
      return (
        <Box
          sx={{
            height: 'calc(100% - 100px)',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <ExplorerTradesTable isPreviewOnly={isPreviewOnly} />
        </Box>
      );
    }

    // No tables selected - show message
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: 'calc(100% - 100px)',
          justifyContent: 'center',
        }}
      >
        <Typography color='text.secondary' variant='h6'>
          Please select at least one table to display
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: '100%',
        marginX: 1,
        width: '100%',
      }}
    >
      <div style={{ padding: '1rem 0' }}>
        <Box>
          <Typography fontFamily='Jost' fontSize={28} fontWeight={400} variant='h2'>
            On-Chain Explorer
          </Typography>
        </Box>
        <Box sx={{ mb: 2, mt: 2 }}>
          <FormGroup row>
            <Typography sx={{ alignSelf: 'center', mr: 2 }} variant='subtitle1'>
              Tables to display:
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={selectedTables.trades} name='trades' onChange={handleTableSelectionChange} />}
              label='Trades'
            />
            <FormControlLabel
              control={<Checkbox checked={selectedTables.proofs} name='proofs' onChange={handleTableSelectionChange} />}
              label='Proofs'
            />
          </FormGroup>
        </Box>
        <Divider />
      </div>

      {renderContent()}
    </Box>
  );
}

export default function ExplorerPage() {
  return (
    <AccountsProvider>
      <ExplorerPageContent />
    </AccountsProvider>
  );
}
