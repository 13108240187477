import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { AccountBalanceWalletRounded, AccountCircleRounded, Info, LogoutOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  SwipeableDrawer,
  List,
  Divider,
  ListItemButton,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import useViewport from '@/shared/hooks/useViewport';
import LOGOS from '../../images/logos';
import { getPlantStatus, logout } from '../apiServices';
import { NavBarItem, MobileNavBarItem } from '../shared/LinkMenu';
import { useUserMetadata } from '../shared/context/UserMetadataProvider';

const loginUrl = '/account/login/';
const signupUrl = '/account/signup/';

const NAV_BAR_LINKS = Object.freeze({
  TRADE: { label: 'Trade' },
  MAIN: { label: 'Spot & Futures', path: '/' },
  MULTI: { label: 'Portfolio & Basis', path: '/enter_multi_order' },
  OPTIONS: { label: 'Options', path: '/enter_option_order' },
  CHAINED: { label: 'Chained Orders [BETA]', path: '/enter_chained_order' },
  ORDERS: { label: 'Orders', path: '/view_orders' },
  ANALYTICS: { label: 'Analytics', path: '/transaction_costs' },
  POINTS: { label: 'Points', path: '/points' },
  REFERRAL: { label: 'Referral', path: '/referral' },
  VAULT: { label: 'Vault', path: '/vault' },
  EXPLORER: { label: 'Explorer', path: '/explorer' },
  DICY: { label: 'Dicy', path: '/dicy' },
  PORTFOLIO: { label: 'Portfolio', path: '/account_balances' },
  API_KEYS: {
    icon: <AccountBalanceWalletRounded sx={{ fontSize: '1.5rem' }} />,
    path: '/key_management',
  },
  API_KEYS_MOBILE: { label: 'Add Exchange', path: '/key_management' },
  INFO: { icon: <Info sx={{ fontSize: '1.5rem' }} /> },
  HELP: { label: 'Help' },
  DOCUMENTATION: {
    label: 'Documentation',
    path: 'https://tread-labs.gitbook.io/api-docs',
    openTab: true,
  },
  RELEASE_NOTES: {
    label: 'Release Notes',
    path: 'https://foul-wavelength-9af.notion.site/TaaS-Release-Notes-2a2ca754c9e24e7e9ee71fc2d00f64ad',
    openTab: true,
  },
  TOS: {
    label: 'Terms of Service',
    path: 'https://foul-wavelength-9af.notion.site/Terms-of-Service-7eeb5222e8c845c2b411601ccc729f99',
    openTab: true,
  },
  PRIVACY_POLICY: {
    label: 'Privacy Policy',
    path: 'https://foul-wavelength-9af.notion.site/Privacy-Policy-29f3010cd06141929ddbd6bfc2ab98a7',
    openTab: true,
  },
  DISCORD: {
    icon: <img alt='Discord' src={LOGOS.discord} style={{ width: '1.5rem' }} />,
    path: 'https://discord.gg/wEf45wF6pU',
    openTab: true,
  },
  TELEGRAM: {
    icon: <img alt='Telegram' src={LOGOS.telegram} style={{ width: '1.4rem', opacity: 0.8 }} />,
    path: 'https://t.me/+_DptCuarEKM0YWVl',
    openTab: true,
  },
  PROFILE: { icon: <AccountCircleRounded sx={{ fontSize: '1.5rem' }} /> },
  SETTINGS: { label: 'Account & Settings', path: '/settings' },
  ADMIN: { label: 'Admin Panel', path: '/admin_panel' },
});

function NavBar({ version }) {
  const [plantStatus, setPlantStatus] = useState('N/A');
  const [open, setOpen] = useState(false);
  const { isMobile } = useViewport();

  const toggleDrawer = (_open) => {
    setOpen(_open);
  };

  const closeDrawer = () => toggleDrawer(false);

  const theme = useTheme();
  const location = useLocation();

  const { isRetail, isDev, user, noAccountsFlag, isDicyEnabled } = useUserMetadata();

  const isAuthenticated = user && user.is_authenticated;

  const showNewUserKeyTooltip = noAccountsFlag && location.pathname !== '/key_management';

  const handleLogout = async () => {
    if (isAuthenticated) {
      try {
        await logout();
      } catch (error) {
        return;
      }
    }

    localStorage.setItem('user', JSON.stringify({}));
    // replace with navigate when login page is re-written to react
    window.location.href = '/account/login/';
  };

  useEffect(() => {
    const loadPlantStatus = async () => {
      let status = null;
      try {
        status = await getPlantStatus();
      } catch (error) {
        return;
      }
      setPlantStatus(status.status);
    };

    if (isAuthenticated) {
      loadPlantStatus();
    }
  }, []);

  const statusStyles = {
    online: {
      orb: { backgroundColor: theme.palette.success.main },
      text: { color: theme.palette.success.main },
    },
    degraded: {
      orb: { backgroundColor: theme.palette.warning.main },
      text: { color: theme.palette.warning.main },
    },
    offline: {
      orb: { backgroundColor: theme.palette.error.main },
      text: { color: theme.palette.error.main },
    },
    na: {
      orb: { backgroundColor: theme.palette.text.disabled },
      text: { color: theme.palette.text.disabled },
    },
  };

  const renderStatusIndicator = (status) => {
    const getStatusStyles = () => {
      switch (status) {
        case 'Online':
          return statusStyles.online;
        case 'Partially Degraded':
          return statusStyles.degraded;
        case 'Offline':
          return statusStyles.offline;
        default:
          return statusStyles.na;
      }
    };

    if (status === 'N/A') {
      return null;
    }

    const { orb, text } = getStatusStyles();

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '0.5rem',
        }}
      >
        <Box
          sx={{
            width: 9,
            height: 9,
            borderRadius: '50%',
            marginRight: 0.8,
            ...orb,
          }}
        />
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '0.8rem',
            ...text,
          }}
        >
          {status}
        </Typography>
      </Box>
    );
  };

  return (
    <AppBar
      position='sticky'
      sx={{
        backgroundColor: isDev ? 'exchangeBalance.3' : '#000000',
        height: '60px',
      }}
    >
      <Toolbar>
        <IconButton
          disableFocusRipple
          disableRipple
          color='inherit'
          component={Link}
          edge='start'
          sx={{ marginRight: '2rem' }}
          to='/'
        >
          <img alt='Tread Logo' src={LOGOS.treadFullDarkSvg} style={{ height: 30 }} />
        </IconButton>

        {isMobile ? (
          <>
            <Box sx={{ flexGrow: 1 }} /> {/* Acts as spacer  */}
            <IconButton onClick={() => toggleDrawer(true)}>
              <MenuIcon sx={{ fontSize: '1.5rem' }} />
            </IconButton>
            <SwipeableDrawer anchor='right' elevation={0} open={open} onClose={closeDrawer}>
              <List sx={{ width: 240 }}>
                <MobileNavBarItem itemProps={NAV_BAR_LINKS.TRADE}>
                  <MobileNavBarItem isSubItem itemProps={NAV_BAR_LINKS.MAIN} onClick={closeDrawer} />
                  <MobileNavBarItem
                    isSubItem
                    disabled={!isAuthenticated}
                    itemProps={NAV_BAR_LINKS.MULTI}
                    onClick={closeDrawer}
                  />
                  <MobileNavBarItem
                    isSubItem
                    disabled={!isAuthenticated}
                    itemProps={NAV_BAR_LINKS.OPTIONS}
                    onClick={closeDrawer}
                  />
                  <MobileNavBarItem
                    isSubItem
                    disabled={!isAuthenticated}
                    itemProps={NAV_BAR_LINKS.CHAINED}
                    onClick={closeDrawer}
                  />
                </MobileNavBarItem>
                <MobileNavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.ORDERS} onClick={closeDrawer} />
                <MobileNavBarItem
                  disabled={!isAuthenticated}
                  itemProps={NAV_BAR_LINKS.ANALYTICS}
                  onClick={closeDrawer}
                />
                {isRetail && (
                  <MobileNavBarItem
                    disabled={!isAuthenticated}
                    itemProps={NAV_BAR_LINKS.POINTS}
                    onClick={closeDrawer}
                  />
                )}
                {isRetail && (
                  <MobileNavBarItem
                    disabled={!isAuthenticated}
                    itemProps={NAV_BAR_LINKS.REFERRAL}
                    onClick={closeDrawer}
                  />
                )}
                {isDev && (
                  <MobileNavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.VAULT} onClick={closeDrawer} />
                )}
                {isDev && (
                  <MobileNavBarItem
                    disabled={!isAuthenticated}
                    itemProps={NAV_BAR_LINKS.EXPLORER}
                    onClick={closeDrawer}
                  />
                )}
                {isDicyEnabled && (
                  <MobileNavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.DICY} onClick={closeDrawer} />
                )}
                <MobileNavBarItem
                  disabled={!isAuthenticated}
                  itemProps={NAV_BAR_LINKS.PORTFOLIO}
                  onClick={closeDrawer}
                />

                <Divider />

                <MobileNavBarItem
                  disabled={!isAuthenticated}
                  itemProps={NAV_BAR_LINKS.API_KEYS_MOBILE}
                  size={1}
                  onClick={closeDrawer}
                />
                <MobileNavBarItem itemProps={NAV_BAR_LINKS.HELP} size={1}>
                  <MobileNavBarItem isSubItem itemProps={NAV_BAR_LINKS.DOCUMENTATION} size={1} onClick={closeDrawer} />
                  <MobileNavBarItem isSubItem itemProps={NAV_BAR_LINKS.RELEASE_NOTES} size={1} onClick={closeDrawer} />
                  <MobileNavBarItem isSubItem itemProps={NAV_BAR_LINKS.TOS} size={1} onClick={closeDrawer} />
                  <MobileNavBarItem isSubItem itemProps={NAV_BAR_LINKS.PRIVACY_POLICY} size={1} onClick={closeDrawer} />
                </MobileNavBarItem>
                {isAuthenticated && (
                  <MobileNavBarItem itemProps={NAV_BAR_LINKS.SETTINGS} size={1} onClick={closeDrawer} />
                )}
              </List>

              <Box sx={{ px: 2 }}>
                {isAuthenticated ? (
                  <Button fullWidth sx={{ borderColor: 'text.disabled' }} variant='outlined' onClick={handleLogout}>
                    <Typography color='text'>Logout</Typography>
                  </Button>
                ) : (
                  <Stack direction='column' spacing={2}>
                    <Button
                      href={loginUrl}
                      size='small'
                      sx={{ backgroundColor: theme.palette.primary.dark2 }}
                      variant='contained'
                    >
                      <Typography color='text'>Login</Typography>
                    </Button>
                    {isRetail && (
                      <Button color='primary' href={signupUrl} size='small' variant='contained'>
                        <Typography color={theme.palette.text.offBlack}>Sign up</Typography>
                      </Button>
                    )}
                  </Stack>
                )}
              </Box>
            </SwipeableDrawer>
          </>
        ) : (
          <>
            <NavBarItem itemProps={NAV_BAR_LINKS.TRADE}>
              <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.MAIN} />
              <NavBarItem isSubItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.MULTI} />
              <NavBarItem isSubItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.OPTIONS} />
              <NavBarItem isSubItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.CHAINED} />
            </NavBarItem>
            <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.ORDERS} />
            <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.ANALYTICS} />
            {isRetail && <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.POINTS} />}
            {isRetail && <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.REFERRAL} />}
            {isDev && <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.VAULT} onClick={closeDrawer} />}
            {isDev && <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.EXPLORER} />}
            {isDicyEnabled && <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.DICY} />}
            <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.PORTFOLIO} />
            <Box sx={{ flexGrow: 1 }} /> {/* Acts as spacer  */}
            <Typography
              sx={{
                fontSize: '0.75rem',
                color: 'text.disabled',
                marginLeft: '0.5rem',
                paddingRight: '0.85rem',
                opacity: '0.6',
              }}
            >
              v{version}
            </Typography>
            {renderStatusIndicator(plantStatus)}
            <Tooltip
              arrow
              open={showNewUserKeyTooltip}
              placement='left'
              slotProps={{
                tooltip: {
                  sx: {
                    typography: 'h6', // Adjust typography variant as needed
                    padding: '10px 15px', // Adjust padding to accommodate larger text
                  },
                },
              }}
              title='Add keys to start trading!'
            />
            <NavBarItem disabled={!isAuthenticated} itemProps={NAV_BAR_LINKS.API_KEYS} />
            <NavBarItem itemProps={NAV_BAR_LINKS.INFO}>
              <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.DOCUMENTATION} />
              <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.RELEASE_NOTES} />
              <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.TOS} />
              <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.PRIVACY_POLICY} />
            </NavBarItem>
            <NavBarItem itemProps={NAV_BAR_LINKS.DISCORD} />
            <NavBarItem itemProps={NAV_BAR_LINKS.TELEGRAM} />
            {isAuthenticated && (
              <NavBarItem itemProps={NAV_BAR_LINKS.PROFILE}>
                <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.SETTINGS} />
                {user.is_superuser && !isRetail && <NavBarItem isSubItem itemProps={NAV_BAR_LINKS.ADMIN} />}
              </NavBarItem>
            )}
            {isAuthenticated ? (
              <IconButton color='inherit' onClick={handleLogout}>
                <LogoutOutlined sx={{ fontSize: '1.5rem' }} />
              </IconButton>
            ) : (
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  marginLeft: '0.5rem',
                }}
              >
                <Button
                  href={loginUrl}
                  size='small'
                  sx={{ backgroundColor: theme.palette.primary.dark2 }}
                  variant='contained'
                >
                  <Typography color='text'>Login</Typography>
                </Button>
                {isRetail && (
                  <Button color='primary' href={signupUrl} size='small' variant='contained'>
                    <Typography color={theme.palette.text.offBlack}>Sign up</Typography>
                  </Button>
                )}
              </Stack>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
