import { StyledSmallTableCell, StyledTableCell } from '@/shared/orderTable/util';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getUnderlying, numberWithSpaces, smartRound, titleCase } from '@/util';
import { TreadTooltip } from '@/shared/components/LabelTooltip';
import getBaseTokenIcon from '../../../../images/tokens';
import AssetsTableCell from '../AssetsTableCell';

const assetTypeColumns = [
  { id: 'symbol', label: 'Name', minWidth: 70, align: 'left' },
  {
    id: 'notional',
    label: 'Notional',
    minWidth: 120,
    align: 'right',
    number: true,
  },
  {
    id: 'amount',
    label: 'Quantity',
    minWidth: 120,
    align: 'right',
    number: true,
  },
  {
    id: 'unrealized_profit',
    label: 'ROI($)',
    minWidth: 60,
    align: 'right',
    number: true,
  },
  {
    id: 'unrealized_profit_percentage',
    label: 'ROI(%)',
    minWidth: 60,
    align: 'right',
    number: true,
  },
  {
    id: 'funding_fee',
    label: 'Funding PnL',
    minWidth: 150,
    align: 'right',
    number: true,
  },
  {
    id: 'allocation',
    label: 'Allocation',
    minWidth: 100,
    align: 'right',
    number: true,
  },
];

const calculatePnL = (row) => {
  if (row.asset_type === 'token') {
    return 0;
  }

  // % of unrealized PnL over original notional
  return 100 * (row.unrealized_profit / Math.abs(row.notional));
};

export function AccountAssetTable({ exchange, marketType, marketTypeAssets, absMarketTypeTotalValue }) {
  const filterLowNotional = (assets) => assets.filter((asset) => Math.abs(asset.notional) >= 0.01);
  const assetRowCountLookup = marketTypeAssets.reduce((acc, asset) => {
    acc[asset.symbol] = (acc[asset.symbol] || 0) + 1;
    return acc;
  }, {});

  const filteredColumns = assetTypeColumns.filter((column) => {
    if (marketType !== 'perp') {
      return column.id !== 'funding_fee';
    }
    return true;
  });

  const renderHeaderCell = (column) => {
    let label = <Typography variant='body1'>{column.label}</Typography>;

    if (column.id === 'funding_fee') {
      let variant = 'funding_fee';

      if (exchange === 'Bybit') {
        variant = 'funding_fee_7d';
      } else if (exchange === 'Binance' || exchange === 'Hyperliquid') {
        variant = 'funding_fee_30d';
      }

      label = <TreadTooltip variant={variant} />;
    }

    return (
      <StyledSmallTableCell
        align={column.align}
        key={column.id}
        style={{
          minWidth: column.minWidth,
          width: column.width || undefined,
        }}
      >
        {label}
      </StyledSmallTableCell>
    );
  };

  return (
    <TableContainer sx={{ maxHeight: '400px' }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>{filteredColumns.map((column) => renderHeaderCell(column))}</TableRow>
        </TableHead>
        <TableBody>
          {filterLowNotional(marketTypeAssets).map((row) => {
            const getPairIcon = row.symbol ? getBaseTokenIcon(getUnderlying(row.symbol)) : null;
            return (
              <TableRow key={row.symbol + row.notional} style={{ width: '100%' }}>
                {filteredColumns.map((column) => {
                  let value = row[column.id];
                  switch (column.id) {
                    case 'unrealized_profit_percentage':
                      value = calculatePnL(row);
                      break;
                    case 'symbol':
                      return (
                        <StyledTableCell
                          key={column.id}
                          row={row}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width || '20%',
                          }}
                          value={value}
                        >
                          <Stack
                            direction='row'
                            spacing={1}
                            sx={{
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {getPairIcon && (
                              <img alt='Token Icon' src={getPairIcon} style={{ height: '30px', width: '30px' }} />
                            )}
                            <Typography variant='body1'>{value}</Typography>
                            {assetRowCountLookup[value] && assetRowCountLookup[value] > 1 && (
                              <Typography variant='body1'>({titleCase(row.wallet_type)})</Typography>
                            )}
                          </Stack>
                        </StyledTableCell>
                      );
                    case 'allocation':
                      return (
                        <StyledTableCell
                          align={column.align}
                          key={column.id}
                          row={row}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width || '15%',
                          }}
                          value={value}
                        >
                          {`${((Math.abs(row.notional) / absMarketTypeTotalValue) * 100).toFixed(2)}%`}
                        </StyledTableCell>
                      );
                    case 'amount': {
                      const precision = row?.symbol && row.symbol.includes('USD') ? 2 : 4;
                      return (
                        <StyledTableCell
                          align={column.align}
                          key={column.id}
                          row={row}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width || '20%',
                          }}
                          value={value}
                        >
                          <Typography variant='body1'>
                            {`${numberWithSpaces(smartRound(value, precision))} ${getUnderlying(row?.symbol)}`}
                          </Typography>{' '}
                        </StyledTableCell>
                      );
                    }
                    default:
                      break;
                  }
                  return <AssetsTableCell column={column} key={column.id} row={row} value={value} />;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
