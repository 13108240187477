import { Loader } from '@/shared/Loader';
import { Alert, Divider, Paper, Box, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { groupRiskEventsByParameterId } from '../proofUtils/RiskGroupingUtils';
import { useProofsCache } from '../proofUtils/useProofsCache';
import DataEventSection from './DataEventSection';
import RiskEventsSection from './RiskEventsSection';

/**
 * Component that displays consensus information for a specific trader epoch.
 * Shows details like data consensus and risk attestations.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array<string>} props.dataEventTxHashes - Transaction hashes for data events
 * @param {Array<string>} props.riskEventTxHashes - Transaction hashes for risk events
 * @param {Object} props.config - Configuration object for blockchain connection
 * @param {string} props.traderId - Trader ID
 * @param {string} props.epoch - Epoch
 * @returns {React.ReactElement} Consensus information section
 */
export default function ConsensusInfoSection({ dataEventTxHashes, riskEventTxHashes, config, traderId, epoch }) {
  const { proofs } = useProofsCache();
  const [riskEvents, setRiskEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  // Extract risk events from proofs cache based on transaction hashes
  useEffect(() => {
    if (!riskEventTxHashes || riskEventTxHashes.length === 0) {
      setRiskEvents([]);
      setLoading(false);
      return;
    }

    // Find all risk events that match the transaction hashes
    const matchingRiskEvents = [];
    proofs.forEach((proof) => {
      if (proof.riskEvents && proof.riskEvents.length > 0) {
        proof.riskEvents.forEach((event) => {
          if (riskEventTxHashes.includes(event.transactionHash)) {
            matchingRiskEvents.push(event);
          }
        });
      }
    });

    setRiskEvents(matchingRiskEvents);
    setLoading(false);
  }, [riskEventTxHashes, proofs]);

  // Group risk events by parameterId
  const { riskEventsByParameterId } = useMemo(() => {
    if (!riskEvents || riskEvents.length === 0) {
      return { riskEventsByParameterId: {} };
    }

    const groupedEvents = groupRiskEventsByParameterId(riskEvents);

    return {
      riskEventsByParameterId: groupedEvents,
    };
  }, [riskEvents]);

  if (loading) {
    return <Loader />;
  }

  if (riskEvents.length === 0 && riskEventTxHashes?.length > 0) {
    return <Alert severity='error'>Failed to load consensus information. Please try again later.</Alert>;
  }

  return (
    <Paper elevation={1} sx={{ bgcolor: 'background.paper', mb: 4 }}>
      <Box sx={{ px: 8, py: 6 }}>
        <Typography sx={{ mb: 2 }} variant='h5'>
          Consensus Info
        </Typography>

        <Stack spacing={4}>
          {/* Data Consensus - Using DataEventSection */}
          <DataEventSection config={config} epoch={epoch} traderId={traderId} txHashes={dataEventTxHashes} />

          <Divider />

          {/* Risk Events Section */}
          <RiskEventsSection
            config={config}
            epoch={epoch}
            riskEventsByParameterId={riskEventsByParameterId}
            traderId={traderId}
            txHashes={riskEventTxHashes}
          />
        </Stack>
      </Box>
    </Paper>
  );
}
