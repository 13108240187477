import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import useViewport from '@/shared/hooks/useViewport';
import OrderConfirmation from './OrderConfirmation';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '800px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
};

function ModalContainer({ open, setOpen, children }) {
  return (
    <Modal
      closeAfterTransition
      aria-describedby='transition-modal-description'
      aria-labelledby='transition-modal-title'
      open={open}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      slots={{ backdrop: Backdrop }}
      onClose={() => setOpen(false)}
    >
      <Fade in={open}>
        <Box display='flex' flexDirection='column' justifyContent='center' sx={modalStyle}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

function MobileModalContainer({ open, setOpen, children }) {
  return (
    <SwipeableDrawer anchor='bottom' ModalProps={{ keepMounted: false }} open={open} onClose={() => setOpen(false)}>
      {children}
    </SwipeableDrawer>
  );
}
export function OrderConfirmationModal({ props }) {
  const { data, handleConfirm, open, setOpen } = props;
  const { isMobile } = useViewport();

  const Wrapper = isMobile ? MobileModalContainer : ModalContainer;

  return (
    <Wrapper open={open} setOpen={setOpen}>
      <OrderConfirmation order={data} onClose={() => setOpen(false)} onConfirm={handleConfirm} />
    </Wrapper>
  );
}
