/**
 * Reusable consensus table component used for displaying consensus data.
 * It handles common table functionality like pagination, loading states, and empty states.
 *
 * Used by DataConsensusTable and RiskConsensusTable to display consensus information.
 * Consumers provide column definitions, data, and row rendering functions.
 * The component manages its own pagination state and UI.
 * Supports skeleton loading states for better UX during data fetching.
 */

import LabelTooltip from '@/shared/components/LabelTooltip';
import { StyledHeaderTableCellWithLine, StyledTableCell } from '@/shared/orderTable/util';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Alert,
  Box,
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';

/**
 * Reusable consensus table component for both data and risk consensus
 *
 * @param {Object} props - Component props
 * @param {Array} props.columns - Column definitions
 * @param {Array} props.data - Table data
 * @param {Function} props.renderRow - Function to render a table row
 * @param {Function} props.renderSkeletonRow - Function to render a skeleton row
 * @param {boolean} props.loading - Loading state
 * @param {string} props.error - Error message if any
 * @param {string} props.emptyMessage - Message to show when no data
 * @returns {React.ReactElement} Consensus table
 */
export default function ConsensusTable({
  columns,
  data = [],
  renderRow,
  renderSkeletonRow,
  loading = false,
  error = null,
  emptyMessage = 'No consensus data available',
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Render skeleton rows for loading state
  const renderSkeletonRows = () => {
    const ids = Array.from({ length: 3 }, (_, i) => `skeleton-row-${i}`);
    return ids.map((id) => renderSkeletonRow(id));
  };

  // Render column header with optional tooltip
  const renderColumnHeader = (column) => {
    if (!column.tooltip) {
      return column.label;
    }

    if (column.id === 'epoch') {
      return (
        <Tooltip arrow placement='bottom' title={column.tooltip}>
          <Typography sx={{ textDecoration: 'underline dotted' }} variant='subtitle2'>
            {column.label}
          </Typography>
        </Tooltip>
      );
    }

    return (
      <LabelTooltip
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              color: 'text.primary',
              boxShadow: 1,
              fontSize: '0.875rem',
              p: 1,
              zIndex: 9999,
            },
          },
        }}
        label={column.label}
        labelTextVariant='subtitle2'
        placement='bottom'
        title={column.tooltip}
      />
    );
  };

  return (
    <Card
      sx={{
        width: '100%',
        mb: 4,
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          p: 0,
        }}
      >
        {error ? (
          <Box sx={{ p: 4 }}>
            <Alert severity='error'>Error loading consensus information: {error}</Alert>
          </Box>
        ) : (
          <>
            <TableContainer sx={{ flex: 1, overflow: 'auto', position: 'relative' }}>
              <Table
                stickyHeader
                aria-label='consensus table'
                sx={{
                  minWidth: 650,
                  tableLayout: 'fixed',
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <StyledHeaderTableCellWithLine
                        align={column.align}
                        key={column.id}
                        style={{ width: column.width }}
                      >
                        {renderColumnHeader(column)}
                      </StyledHeaderTableCellWithLine>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(() => {
                    if (loading) {
                      return renderSkeletonRows();
                    }

                    if (data.length > 0) {
                      return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(renderRow);
                    }

                    return (
                      <TableRow>
                        <StyledTableCell align='center' colSpan={columns.length}>
                          {emptyMessage}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })()}
                </TableBody>
              </Table>
            </TableContainer>
            {data.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px 16px',
                  flexShrink: 0,
                }}
              >
                <Typography color='text.secondary' variant='body2'>
                  {`${page * rowsPerPage + 1}-${Math.min(
                    (page + 1) * rowsPerPage,
                    data.length
                  )} of ${data.length.toLocaleString()}`}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography color='text.secondary' sx={{ mr: 2 }} variant='body2'>
                    Rows per page:
                  </Typography>
                  <Box
                    component='select'
                    sx={{
                      backgroundColor: 'transparent',
                      border: '1px solid rgba(255, 255, 255, 0.23)',
                      borderRadius: '4px',
                      color: 'white',
                      mr: 2,
                      padding: '4px 8px',
                    }}
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                  </Box>
                  <IconButton
                    disabled={page === 0}
                    sx={{
                      color: page === 0 ? 'rgba(255, 255, 255, 0.3)' : 'white',
                    }}
                    onClick={() => handleChangePage(null, page - 1)}
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                  <IconButton
                    disabled={page >= Math.ceil(data.length / rowsPerPage) - 1}
                    sx={{
                      color: page >= Math.ceil(data.length / rowsPerPage) - 1 ? 'rgba(255, 255, 255, 0.3)' : 'white',
                    }}
                    onClick={() => handleChangePage(null, page + 1)}
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </Box>
              </Box>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}

/**
 * Helper to create a skeleton row component
 *
 * @param {Array} columns - Column definitions
 * @returns {Function} Skeleton row renderer function
 */
export function createSkeletonRowRenderer(columns) {
  const SkeletonRowRenderer = function SkeletonRowRenderer(key) {
    return (
      <TableRow key={key}>
        {columns.map((column) => {
          // Determine width based on column type
          let boxWidth = 100; // Default width
          if (column.id === 'actions') {
            boxWidth = 70;
          } else if (column.id === 'epoch') {
            boxWidth = 40;
          }

          // Determine height based on column type
          let boxHeight = 20;
          if (column.id === 'timestamp') {
            boxHeight = 40;
          } else if (column.id === 'status') {
            boxHeight = 24;
          }

          return (
            <StyledTableCell align={column.align} key={`${key}-cell-${column.id}`} width={column.width}>
              <Box
                sx={{
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  borderRadius: 1,
                  height: boxHeight,
                  width: boxWidth,
                  ...(column.id === 'actions' ? { ml: 'auto' } : {}),
                }}
              />
            </StyledTableCell>
          );
        })}
      </TableRow>
    );
  };

  SkeletonRowRenderer.displayName = 'SkeletonRowRenderer';
  return SkeletonRowRenderer;
}
