import { theme } from '@/theme/theme';
import { Box, Stack, Paper, ThemeProvider, InputLabel } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import React from 'react';
import { smartRound, msAndKs, titleCase } from '@/util';
import { HeaderTypography } from '@/shared/components/MuiComponents';
import { TreadTooltip } from '@/shared/components/LabelTooltip';
import EmptyBar from '@/shared/components/EmptyBar';
import DataComponent from '@/shared/DataComponent';
import { fillRoleColor } from './charts/util';

function BenchmarkCard({ children }) {
  return (
    <Paper
      elevation={1}
      sx={{
        px: '12px',
        py: '8px',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Paper>
  );
}

const moveNegativeToFront = (value) => {
  if (value < 0) {
    return `-$${Math.abs(value)}`;
  }
  return `$${value}`;
};

function ArrivalCost({ arrival_cost, arrival_bps_notional, arrival_price, showArrivalPrice }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip labelTextVariant='small2' placement='left' variant='slippage' />
      </InputLabel>

      <DataComponent
        emptyComponent={
          <Stack direction='column' justifyContent='center' sx={{ height: '24px' }}>
            <EmptyBar />
          </Stack>
        }
        isEmpty={!arrival_cost}
      >
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography color={arrival_cost > 0 ? 'error.main' : 'success.main'} variant='subtitle1'>
            {smartRound(arrival_cost, 2)}
          </Typography>
          <Typography color={arrival_cost > 0 ? 'error.main' : 'success.main'} variant='small1'>
            {'bps\u00A0'}
          </Typography>
          {arrival_bps_notional && (
            <Typography variant='small1'>{`≈ ${moveNegativeToFront(smartRound(arrival_bps_notional, 2))}`}</Typography>
          )}
        </Stack>
      </DataComponent>

      {showArrivalPrice && (
        <Stack alignItems='center' direction='row' spacing={2}>
          <HeaderTypography>Benchmark:</HeaderTypography>
          <DataComponent emptyComponent={<EmptyBar variant='small' />} isEmpty={!arrival_price}>
            <HeaderTypography>${smartRound(arrival_price, 2)}</HeaderTypography>
          </DataComponent>
        </Stack>
      )}
    </Stack>
  );
}

function VWAPCost({ vwap_cost, vwap, showVmap }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip labelTextVariant='small2' placement='left' variant='vwap_slippage' />
      </InputLabel>

      <DataComponent
        emptyComponent={
          <Stack direction='column' justifyContent='center' sx={{ height: '24px' }}>
            <EmptyBar />
          </Stack>
        }
        isEmpty={!vwap_cost}
      >
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography color={vwap_cost > 0 ? 'error.main' : 'success.main'} variant='subtitle1'>
            {smartRound(vwap_cost, 2)}
          </Typography>
          <Typography color={vwap_cost > 0 ? 'error.main' : 'success.main'} variant='small1'>
            bps
          </Typography>
        </Stack>
      </DataComponent>

      {showVmap && (
        <Stack alignItems='center' direction='row' spacing={2}>
          <HeaderTypography>Benchmark:</HeaderTypography>
          <DataComponent emptyComponent={<EmptyBar variant='small' />} isEmpty={!vwap}>
            <HeaderTypography>${smartRound(vwap, 2)}</HeaderTypography>
          </DataComponent>
        </Stack>
      )}
    </Stack>
  );
}

function ExchangeFee({ fee_cost, fee_asset, fee_notional, fillRoleBreakdown, showFillBreakdown }) {
  const renderFee = () => {
    if (fee_asset === 'USD' || !fee_asset) {
      return `$${smartRound(fee_notional)}`;
    }

    return `${smartRound(fee_notional)} ${fee_asset}`;
  };

  const formatRole = (role) => {
    if (['MAKE', 'TAKE'].includes(role)) {
      return titleCase(`${role}r\u00A0`);
    }

    return titleCase(role);
  };

  const renderFillRoleBreakdown = () => {
    if (!fillRoleBreakdown || Object.keys(fillRoleBreakdown).length === 0) {
      return <EmptyBar variant='small' />;
    }

    return (
      <Stack direction='row'>
        {Object.entries(fillRoleBreakdown).map(([role, value], i) => {
          return (
            <Stack direction='row' key={role} spacing={1}>
              {i !== 0 && <Typography variant='small2'>/</Typography>}
              <Typography variant='small2'>{Number(value).toFixed(0)}%</Typography>
              <Typography color={fillRoleColor({ theme, role })} variant='small2'>
                {formatRole(role)}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const renderFeeCost = () => {
    if (!fee_cost && fee_cost !== 0) {
      return 'Not Available';
    }

    return `${smartRound(fee_cost, 1)}bps`;
  };

  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip labelTextVariant='small2' placement='left' variant='exchange_fee' />
      </InputLabel>

      <DataComponent
        emptyComponent={
          <Stack direction='column' justifyContent='center' sx={{ height: '24px' }}>
            <EmptyBar />
          </Stack>
        }
        isEmpty={fee_cost === undefined}
      >
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography variant='subtitle1'>{renderFee()}</Typography>{' '}
          <Typography variant='small1'>{`\u00A0≈ ${renderFeeCost()}`}</Typography>
        </Stack>
      </DataComponent>
      {showFillBreakdown && renderFillRoleBreakdown()}
    </Stack>
  );
}

function ParticipationRate({ pov, interval_volume, showVolume }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip labelTextVariant='small2' placement='left' variant='benchmark_participation_rate' />
      </InputLabel>

      <DataComponent
        emptyComponent={
          <Stack direction='column' justifyContent='center' sx={{ height: '24px' }}>
            <EmptyBar />
          </Stack>
        }
        isEmpty={!pov}
      >
        <Typography variant='subtitle1'>{smartRound(pov, 3)}%</Typography>
      </DataComponent>

      {showVolume && (
        <Stack alignItems='center' direction='row' spacing={2}>
          <HeaderTypography>Volume:</HeaderTypography>
          <DataComponent emptyComponent={<EmptyBar variant='small' />} isEmpty={!interval_volume}>
            <HeaderTypography>${msAndKs(Number(interval_volume))}</HeaderTypography>
          </DataComponent>
        </Stack>
      )}
    </Stack>
  );
}

function NotionalExposure({ notional_exposure }) {
  return (
    <Stack direction='column' spacing={1}>
      <InputLabel
        sx={{
          color: theme.palette.text.subtitle,
        }}
      >
        <TreadTooltip labelTextVariant='small2' placement='left' variant='notional_exposure' />
      </InputLabel>
      <DataComponent
        emptyComponent={
          <Stack direction='column' justifyContent='center' sx={{ height: '24px' }}>
            <EmptyBar />
          </Stack>
        }
        isEmpty={!notional_exposure}
      >
        <Stack alignItems='baseline' direction='row' spacing={0}>
          <Typography variant='subtitle1'>{moveNegativeToFront(smartRound(notional_exposure))}</Typography>
        </Stack>
      </DataComponent>
    </Stack>
  );
}

function OrderBenchmarks({ benchmarkData, fillRoleBreakdown, isSimple = false, isMulti = false, isMobile = false }) {
  const {
    arrival_price,
    arrival_cost,
    arrival_bps_notional,
    vwap,
    vwap_cost,
    fee_notional,
    fee_asset,
    fee_cost,
    interval_volume,
    pov,
    notional_exposure = undefined,
  } = benchmarkData;

  let cardSize;
  if (isSimple) {
    cardSize = 6;
  } else if (isMulti) {
    cardSize = isMobile ? 6 : 12 / 5;
  } else {
    cardSize = isMobile ? 6 : 3;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container direction='row' spacing={1} sx={{ height: '100%' }}>
          {isMulti && (
            <Grid xs={cardSize}>
              <BenchmarkCard>
                <NotionalExposure notional_exposure={notional_exposure} />
              </BenchmarkCard>
            </Grid>
          )}
          <Grid xs={cardSize}>
            <BenchmarkCard>
              <ArrivalCost
                arrival_bps_notional={arrival_bps_notional}
                arrival_cost={arrival_cost}
                arrival_price={arrival_price}
                showArrivalPrice={!isMulti}
              />
            </BenchmarkCard>
          </Grid>
          {!isSimple && (
            <Grid xs={cardSize}>
              <BenchmarkCard>
                <VWAPCost showVmap={!isMulti} vwap={vwap} vwap_cost={vwap_cost} />
              </BenchmarkCard>
            </Grid>
          )}
          <Grid xs={cardSize}>
            <BenchmarkCard>
              <ExchangeFee
                fee_asset={fee_asset}
                fee_cost={fee_cost}
                fee_notional={fee_notional}
                fillRoleBreakdown={fillRoleBreakdown}
                showFillBreakdown={!isMulti}
              />
            </BenchmarkCard>
          </Grid>
          {!isSimple && (
            <Grid xs={cardSize}>
              <BenchmarkCard>
                <ParticipationRate interval_volume={interval_volume} pov={pov} showVolume={!isMulti} />
              </BenchmarkCard>
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export { OrderBenchmarks };
