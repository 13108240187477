/* eslint-disable max-len */
import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { setBetaAgreedAt } from '../../apiServices';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';
import { showAlert } from '../../shared/context/util';

function TermsAndConditions() {
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const { betaAgreedAt, loadUserMetadata } = useUserMetadata();
  const navigate = useNavigate();

  const handleAgree = async () => {
    try {
      await setBetaAgreedAt();
      await loadUserMetadata();
      navigate('/');
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to update preferences: ${error.message}`,
        setHasError,
        setErrorContent,
      });
    }
  };

  return (
    <Box alignItems='center' display='flex' flexDirection='column' height='100%' sx={{ overflow: 'auto' }}>
      <Box height='100%' width='50%'>
        <Typography gutterBottom variant='h4'>
          BETA TESTER TERMS AND CONDITIONS
        </Typography>
        <Typography gutterBottom variant='body1'>
          The undersigned as set out on the signature page below (the “Receiving Party”) hereby agrees to these beta
          tester terms and conditions (this “Agreement”), between Tread Labs Corporation (the “Company”) and the
          Receiving Party as of the date set out on the signature page below (the “Effective Date”). Each of the Company
          and the Receiving Party is a “Party” and together the “Parties.”
        </Typography>
        <Box mb={2} mt={2}>
          <Typography variant='h6'>1. Confidential Information.</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary='1.1. Relationship of the Parties.'
                secondary='Company is making a beta version of its application (the “Beta”) available to the Receiving Party, in exchange, the Receiving party will review the Beta and provide Feedback (as defined below) to the Company.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1.2. Definition of Confidential Information.'
                secondary='For the purposes of this Agreement, “Confidential Information” means all information relating, directly or indirectly, to the Company or the Beta provided by the Company to the Receiving Party that is not generally known to the public, including, information concerning the business affairs, services, products, strategies, or intellectual property of the Company or any the Beta, including any data, know-how, technology, plans, trade secrets, information related to current, future and proposed products and/or services, research, designs, inventions, ideas, concepts, plans and specifications, software programs, licenses and permits, formulas, methods or processes, and such other information normally understood to be confidential or otherwise designated as such in writing by the Company, whether disclosed orally or disclosed or accessed in written, electronic, or other form or media, as well as information discerned from, based on or relating to any of the foregoing which may be prepared by the Receiving Party, all of which the Receiving Party expressly acknowledges and agrees shall be confidential and proprietary information belonging to the Company. Confidential Information shall also include any other information (whether of the Company or other person or entity with whom or which the Company has an agreement concerning the confidentiality of information) which comes into the Receiving Party’s possession as a result of this Agreement.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1.3. No Disclosure of Confidential Information.'
                secondary='The Receiving Party agrees (a) to hold the Confidential Information in confidence and to take reasonable precautions to protect such Confidential Information, (b) not to divulge any such Confidential Information or any information derived therefrom to any third person, (c) not to make any use whatsoever at any time of such Confidential Information except to provide feedback to the Company, and (d) not to modify, reverse engineer, decompile, create other works from, disassemble or copy any such Confidential Information.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1.4. Return or Deletion of Confidential Information.'
                secondary='Upon termination of this Agreement or at the Company’s request for any reason, the Receiving Party shall promptly return to the Company, as applicable, or delete, as applicable, all documents and other matter relating to any Confidential Information, together with any copies thereof.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='1.5. Breach.'
                secondary='The Receiving Party further agrees that it shall (a) immediately notify the Company of any breach of this Agreement; and (b) fully cooperate with the Company to mitigate the effect of such breach.'
              />
            </ListItem>
          </List>
        </Box>
        <Box mb={2} mt={2}>
          <Typography variant='h6'>2. Term.</Typography>
          <Typography variant='body1'>
            This Agreement shall terminate six (6) months after the date hereof. The Receiving Party’s obligations under
            this Agreement with respect to the Confidential Information shall survive termination of the Agreement
            indefinitely.
          </Typography>
        </Box>
        <Box mb={2} mt={2}>
          <Typography variant='h6'>3. Additional Provisions.</Typography>
          <List>
            <ListItem>
              <ListItemText
                primary='3.1. Rights.'
                secondary='The Parties recognize and agree that nothing contained in this Agreement shall be construed as granting any property rights, whether tangible or intangible, by license or otherwise, to any Confidential Information of the Company disclosed pursuant to this Agreement or to any invention or any patent, copyright, trademark, or other intellectual property right that has issued or that may issue based on such Confidential Information. The Receiving Party shall not make, have made, use or sell for any purpose any product or other item using, incorporating or derived from any Confidential Information of the Company.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='3.2. Limitation of Liability.'
                secondary="THE BETA IS PROVIDED “AS IS” WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE NOR ANY REPRESENTATION NOR WARRANTIES RELATED TO THE PRODUCTS AND GOODS THAT THE RECEIVING PARTY MAY EXCHANGE OR RECEIVE PURSUANT TO THE RECEIVING PARTY’S USE OF THE BETA OR THE COMPANY'S SERVICES. IN NO EVENT SHALL THE COMPANY OR ITS AFFILIATES BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING OUT OF THE USE OF OR INABILITY TO USE THE BETA, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='3.3. Feedback.'
                secondary='The Receiving Party shall assign to the Company all right, title and interest in the Feedback provided by or developed by the Receiving Party. Nothing in this Agreement or in the Parties’ dealings arising out of or related to this Agreement will restrict the Company’s right to use, profit from, disclose, publish, keep secret, or otherwise exploit any Feedback, without compensating or crediting the Receiving Party or the individual providing such Feedback. “Feedback” means any suggestion or idea for improving or otherwise modifying Company’s products or services.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='3.4. Remedies.'
                secondary='The Company shall be entitled to seek specific performance and injunctive and other equitable relief as a remedy for any such breach or threatened breach, and the Receiving Party hereby waives any requirement for the securing or posting of any bond or the showing of actual monetary damages in connection with such claim.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='3.5. Governing Law; Venue.'
                secondary='This Agreement shall be governed by and construed in accordance with the laws of New York without reference to conflict of laws principles. Any disputes under this Agreement may be brought in the state courts and the Federal courts located in New York, New York, and the Parties hereby consent to the personal jurisdiction and venue of these courts.'
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary='3.6. Amendments.'
                secondary='This Agreement may not be amended or otherwise modified without the prior written consent of each Party.'
              />
            </ListItem>
          </List>
        </Box>
        <Stack
          alignItems='center'
          direction='column'
          display='flex'
          justifyContent='center'
          paddingBottom='32px'
          spacing={2}
          width='100%'
        >
          <Button disabled={!!betaAgreedAt} sx={{ width: '200px' }} variant='contained' onClick={handleAgree}>
            I Agree
          </Button>
          {!!betaAgreedAt && (
            <Box>
              <Typography variant='body1'>
                {`You have already accepted the beta agreement on: ${new Date(betaAgreedAt).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}`}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

export default TermsAndConditions;
