import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { StyledBorderTableCell, StyledTableCell } from '@/shared/orderTable/util';
import { useUserMetadata } from '@/shared/context/UserMetadataProvider';
import { ErrorContext } from '@/shared/context/ErrorProvider';
import {
  getDicyConnectionMetadata,
  resetPassword,
  updateUserPreferences,
  setup2FA,
  verify2FA,
  reset2FA,
  link_telegram,
  unlink_telegram,
  connectNettingServer,
  getTelegramMetadata,
} from '@/apiServices';
import {
  OPEN_NEW_TAB_ON_SUBMIT,
  NOTIFICATION_ORDER_COMPLETE,
  NOTIFICATION_ORDER_PROGRESS,
  NOTIFICATION_ORDER_FIRST_FILL,
  NOTIFICATION_ORDER_PARTIALLY_FILLED,
  NOTIFICATION_ORDER_OVERFILL,
  NOTIFICATION_ORDER_PAUSE_WORKFLOW,
  NOTIFICATION_ORDER_RESUME_WORKFLOW,
  USER_PREF_RECEIVE_ALL_NOTIFICATIONS,
} from '@/constants';
import { ThinLoader } from '@/shared/Loader';

export default function AccountSettings() {
  const { user, setUser, isRetail, loadUserMetadata, api_token } = useUserMetadata();
  const [dicyMetadata, setDicyMetadata] = useState({});
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [apiTokenVisible, setApiTokenVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [twoFAToken, setTwoFAToken] = useState(['', '', '', '', '', '']);
  const [reset2FAToken, setReset2FAToken] = useState(['', '', '', '', '', '']);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [telegramMetadata, setTelegramMetadata] = useState({});
  const [isTelegramMetadataLoading, setIsTelegramMetadataLoading] = useState(false);
  const [isDicyConnecting, setIsDicyConnecting] = useState(false);
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const inputRefs = useRef([]);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  };

  useEffect(() => {
    if (user && user.user_id) {
      setIs2FAEnabled(user.is_2fa_enabled || false);
    }
  }, [user]);

  const handleInputChange = (e, index, tokenArray, setTokenState) => {
    const newToken = [...tokenArray];
    const value = e.target.value.replace(/\D/g, '');
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      newToken[index] = '';
      setTokenState(newToken);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
      return;
    }

    newToken[index] = value.slice(0, 1);
    setTokenState(newToken);

    if (newToken[index] && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const getTokenString = (tokenArray) => tokenArray.join('');

  const handle2FASetup = async () => {
    try {
      const qrCodeCall = await setup2FA();
      setQrCode(qrCodeCall);
      setQrDialogOpen(true);
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to set up 2FA: ${error.message}`,
      });
    }
  };

  const handle2FAVerification = async () => {
    try {
      const token = getTokenString(twoFAToken);
      const response = await verify2FA(token);
      if (response.success) {
        showAlert({
          severity: 'success',
          message: '2FA verified and enabled successfully!',
        });
        setIs2FAEnabled(true);
        loadUserMetadata();
        setQrDialogOpen(false);
      } else {
        showAlert({
          severity: 'error',
          message: response.message || 'Invalid 2FA token. Please try again.',
        });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: 'Failed to verify 2FA' });
    }
  };

  const handle2FAReset = async () => {
    try {
      const token = getTokenString(reset2FAToken);
      const response = await reset2FA(token);
      if (response.message === '2FA has been reset.') {
        setResetDialogOpen(true);
        loadUserMetadata();
        setResetDialogOpen(false);
        showAlert({ severity: 'success', message: '2FA has been reset.' });
      } else {
        showAlert({
          severity: 'error',
          message: response.message || 'Invalid 2FA token. Please try again.',
        });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: `Failed to reset 2FA` });
    }
  };

  const handleQrDialogClose = () => {
    setQrDialogOpen(false);
  };

  const openResetDialog = () => {
    setResetDialogOpen(true);
  };

  const closeResetDialog = () => {
    setResetDialogOpen(false);
  };

  const toggleApiTokenVisibility = () => {
    setApiTokenVisible(!apiTokenVisible);
  };

  const fetchDicyCreds = async () => {
    try {
      const metadata = await getDicyConnectionMetadata();
      setDicyMetadata(metadata);

      if (metadata.is_queued) {
        setIsDicyConnecting(true);
        setTimeout(() => {
          fetchDicyCreds();
        }, 2000);
      } else {
        setIsDicyConnecting(false);
      }
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Error fetching Dicy credentials: ${error.message}`,
      });
    }
  };

  const fetchTelegramMetadata = async () => {
    setIsTelegramMetadataLoading(true);
    try {
      const data = await getTelegramMetadata();
      setTelegramMetadata(data);
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Error fetching Telegram metadata: ${error.message}`,
      });
    } finally {
      setIsTelegramMetadataLoading(false);
    }
  };

  const updateTelegramMetadata = async (key, value) => {
    setTelegramMetadata({ ...telegramMetadata, [key]: value });
  };

  useEffect(() => {
    fetchDicyCreds();
    fetchTelegramMetadata();
  }, []);

  const handleClick = (event) => {
    setPasswordOpen(true);
  };

  const handleClose = () => {
    setPasswordOpen(false);
  };

  const passwordReset = async (event) => {
    event.preventDefault();
    try {
      await resetPassword(user.user_id, oldPassword, newPassword, confirmPassword);
      showAlert({
        severity: 'success',
        message: 'Password reset successfully!',
      });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      handleClose();
    } catch (error) {
      showAlert({ severity: 'error', message: `Error resetting password` });
    }
  };

  const handlePreferenceSwitchChange = async (name, value) => {
    user.preferences[name] = value;
    setUser(user);
    try {
      await updateUserPreferences({ [name]: value });
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Failed to update preferences: ${error.message}`,
        setHasError,
        setErrorContent,
      });
    }

    loadUserMetadata();
  };

  const getNotificationPreferences = (preferences) => {
    return Object.entries(preferences).filter(([name]) => {
      return name.startsWith('notification');
    });
  };

  const getGeneralPreferences = (preferences) => {
    return Object.entries(preferences).filter(([name]) => {
      return !name.startsWith('notification') && name !== USER_PREF_RECEIVE_ALL_NOTIFICATIONS;
    });
  };

  const authTokenHeader = api_token && `Authorization: Token ${api_token}`;
  const copyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(authTokenHeader);
    } catch (err) {
      // nothing
    }
  };

  const renderPrefName = (name) => {
    switch (name) {
      case OPEN_NEW_TAB_ON_SUBMIT:
        return 'Open new tab on order submit';
      case USER_PREF_RECEIVE_ALL_NOTIFICATIONS:
        return 'Receive notifications from all orders';
      default:
        return '';
    }
  };

  const handleLinkTelegram = async (tgUsername) => {
    try {
      const response = await link_telegram(tgUsername);
      if (response.message) {
        showAlert({ severity: 'success', message: response.message });
        fetchTelegramMetadata();
      } else {
        showAlert({
          severity: 'error',
          message: 'Failed to link Telegram account. Please try again.',
        });
      }
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `An error occurred while linking Telegram: ${error.message}`,
      });
    }
  };

  const handleUnlinkTelegram = async (tgUsername) => {
    try {
      const response = await unlink_telegram(tgUsername);
      if (response.message) {
        showAlert({ severity: 'success', message: response.message });
        fetchTelegramMetadata();
      } else {
        showAlert({
          severity: 'error',
          message: 'Failed to unlink Telegram account. Please try again.',
        });
      }
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `An error occurred while unlinking Telegram: ${error.message}`,
      });
    }
  };
  const renderPrefNameTG = (name) => {
    switch (name) {
      case NOTIFICATION_ORDER_COMPLETE:
        return 'Send message when order finishes';

      case NOTIFICATION_ORDER_PROGRESS:
        return 'Send message for order progress on 25%, 50%, 75%';

      case NOTIFICATION_ORDER_FIRST_FILL:
        return 'Send message for first fill of order';

      case NOTIFICATION_ORDER_PARTIALLY_FILLED:
        return 'Send message when order completes partially filled';

      case NOTIFICATION_ORDER_OVERFILL:
        return 'Send message when order overfills';

      case NOTIFICATION_ORDER_PAUSE_WORKFLOW:
        return 'Send message when order pauses';

      case NOTIFICATION_ORDER_RESUME_WORKFLOW:
        return 'Send message when order resumes';

      default:
        return '';
    }
  };

  const renderValueAsInputField = (name, value) => {
    if (typeof value === 'boolean') {
      return (
        <Switch
          checked={value}
          onChange={(e) => {
            handlePreferenceSwitchChange(name, e.target.checked);
          }}
        />
      );
    }

    // figure out how to render other types of values
    return <Typography>{value}</Typography>;
  };

  const renderNotifValueAsInputField = (name, value) => {
    if (typeof value === 'boolean') {
      const disabled = !telegramMetadata.telegram_chat_id;
      return (
        <Tooltip disableHoverListener={!disabled} title='Link telegram to configure'>
          <Box>
            <Switch
              checked={value}
              disabled={disabled}
              onChange={(e) => {
                handlePreferenceSwitchChange(name, e.target.checked);
              }}
            />
          </Box>
        </Tooltip>
      );
    }

    // figure out how to render other types of values
    return <Typography>{value}</Typography>;
  };

  const connectToDicy = async () => {
    setIsDicyConnecting(true);
    try {
      await connectNettingServer();
    } catch (error) {
      showAlert({
        severity: 'error',
        message: `Error connecting to Dicy: ${error.message}`,
      });
    }

    fetchDicyCreds();
  };

  const connectButtonStyle = {
    width: '120px',
    height: '40px',
  };

  const buttonCellWidth = '200px';

  const renderDicyConnectionState = () => {
    if (!dicyMetadata.cred && !isDicyConnecting) {
      return (
        <Table>
          <TableBody>
            <TableRow>
              <StyledTableCell width={buttonCellWidth}>
                <Button color='secondary' sx={connectButtonStyle} variant='contained' onClick={connectToDicy}>
                  <Typography>Register</Typography>
                </Button>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <Typography>Register with Dicy to enable internal netting</Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }

    if (isDicyConnecting || dicyMetadata.is_queued) {
      return (
        <Table>
          <TableBody>
            <TableRow>
              <StyledTableCell width={buttonCellWidth}>
                <Button color='grey' sx={connectButtonStyle} variant='contained' onClick={connectToDicy}>
                  <Typography>Re-sync</Typography>
                </Button>
              </StyledTableCell>
              <StyledTableCell>
                <ThinLoader />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      );
    }
    let connectionStateMsg = 'Connection state unknown. Please try again.';

    if (dicyMetadata.error_message) {
      connectionStateMsg = `Failed to connect: ${dicyMetadata.error_message}`;
    } else if (dicyMetadata.cred.server) {
      connectionStateMsg = `Connected to ${dicyMetadata.cred.server}`;
    } else if (!dicyMetadata.cred) {
      connectionStateMsg = 'Dicy credentials not found!';
    }

    return (
      <Table>
        <TableBody>
          <TableRow>
            <StyledTableCell width={buttonCellWidth}>
              <Button color='error' sx={connectButtonStyle} variant='contained' onClick={connectToDicy}>
                <Typography>Re-sync</Typography>
              </Button>
            </StyledTableCell>
            <StyledTableCell align='center'>
              <Typography>{connectionStateMsg}</Typography>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  if (Object.keys(user).length === 0) {
    return <div />;
  }

  return (
    <Stack
      height='100%'
      overflow='auto'
      spacing={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
      }}
      width='100%'
    >
      <Paper sx={{ p: 2, mb: 4, width: '75%' }}>
        <Typography gutterBottom variant='h3'>
          Profile
        </Typography>
        {user && Object.keys(user).length > 0 && (
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>{user.username}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Password</StyledTableCell>
                <StyledTableCell>
                  <Button
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    color='secondary'
                    size='small'
                    variant='contained'
                    onClick={handleClick}
                  >
                    <Typography>Reset Password</Typography>
                  </Button>
                  <Dialog id='password-dialog' open={passwordOpen} onClose={handleClose}>
                    <DialogTitle>
                      Reset Password
                      <IconButton
                        aria-label='close'
                        style={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: '#fff',
                        }}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      style={{
                        padding: '2rem',
                        borderRadius: '10px',
                        color: '#fff',
                      }}
                    >
                      <Stack direction='column' spacing={2}>
                        <TextField
                          fullWidth
                          InputProps={{
                            style: {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: 'rgba(255, 255, 255, 0.7)',
                            },
                          }}
                          label='Old Password'
                          type='password'
                          value={oldPassword}
                          variant='outlined'
                          onChange={(e) => setOldPassword(e.target.value)}
                        />
                        <TextField
                          fullWidth
                          InputProps={{
                            style: {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: 'rgba(255, 255, 255, 0.7)',
                            },
                          }}
                          label='New Password'
                          type='password'
                          value={newPassword}
                          variant='outlined'
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <TextField
                          fullWidth
                          InputProps={{
                            style: {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: 'rgba(255, 255, 255, 0.7)',
                            },
                          }}
                          label='Confirm Password'
                          type='password'
                          value={confirmPassword}
                          variant='outlined'
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <Typography
                          sx={{
                            fontSize: 14,
                            marginTop: 2,
                            color: 'rgba(255, 255, 255, 0.7)',
                          }}
                        >
                          To secure your account,
                          <br />
                          your new password must contain at least:
                          <ul>
                            <li>8 characters long</li>
                            <li>1 uppercase letter</li>
                            <li>1 lowercase letter</li>
                            <li>1 number</li>
                            <li>1 special character (e.g., !, @, #, $, etc.)</li>
                          </ul>
                        </Typography>
                        <Box>
                          <Stack direction='row' spacing={2}>
                            <Button
                              style={{
                                backgroundColor: '#FFB56A',
                                color: 'rgba(0, 0, 0, 0.7)',
                                marginTop: '1rem',
                              }}
                              variant='contained'
                              onClick={passwordReset}
                            >
                              Reset Password
                            </Button>
                            <Button
                              style={{
                                backgroundColor: '#FFB56A',
                                color: 'rgba(0, 0, 0, 0.7)',
                                marginTop: '1rem',
                                marginLeft: 'auto',
                              }}
                              variant='contained'
                              onClick={handleClose}
                            >
                              Close
                            </Button>
                          </Stack>
                        </Box>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Account Created</StyledTableCell>
                <StyledTableCell>{user.date_joined}</StyledTableCell>
              </TableRow>
              {isRetail && (
                <TableRow>
                  <StyledTableCell>Referral Code</StyledTableCell>
                  <StyledTableCell>{user.referral_code}</StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Paper>

      {!isRetail && (
        <Paper
          sx={{
            p: 2,
            mb: 4,
            width: '75%',
            position: 'relative',
            minHeight: '85px',
          }}
        >
          <Button
            color='secondary'
            sx={{ top: 16, right: 16, position: 'absolute' }}
            variant='contained'
            onClick={toggleApiTokenVisibility}
          >
            <Typography>{apiTokenVisible ? 'Hide API Token' : 'Show API Token'}</Typography>
          </Button>
          <Stack direction='column' spacing={2}>
            <Typography gutterBottom variant='h3'>
              API Key
            </Typography>
            {apiTokenVisible && api_token && (
              <Stack direction='row' position='relative' width='100%'>
                <Typography
                  component='pre'
                  sx={{
                    fontFamily: 'monospace',
                    color: 'error.main',
                    backgroundColor: 'grey.dark',
                    borderRadius: '4px',
                    padding: '8px',
                    overflowX: 'auto',
                    width: '100%',
                  }}
                >
                  {authTokenHeader}
                </Typography>
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    padding: '8px',
                  }}
                  onClick={copyCodeToClipboard}
                >
                  <ContentCopyRounded />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </Paper>
      )}
      <Paper sx={{ p: 2, width: '75%' }}>
        <Stack direction='column' spacing={2}>
          <Typography gutterBottom variant='h3'>
            Preferences
          </Typography>

          <Table>
            <TableBody>
              {getGeneralPreferences(user.preferences).map(([name, value]) => (
                <TableRow key={name}>
                  <StyledTableCell>{renderValueAsInputField(name, value)}</StyledTableCell>
                  <StyledTableCell>{renderPrefName(name)}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, width: '75%' }}>
        <Stack direction='row' justifyContent='space-between' spacing={2}>
          <Box sx={{ flex: 1 }}>
            <Stack direction='column' spacing={2}>
              <Typography gutterBottom variant='h3'>
                Connect telegram
              </Typography>
              <Box height='1rem'>
                {telegramMetadata.telegram_bot_username ? (
                  <a href={`https://t.me/${telegramMetadata.telegram_bot_username}`}>
                    <Typography color='textSecondary' variant='body1'>
                      {`https://t.me/${telegramMetadata.telegram_bot_username}`}
                    </Typography>
                  </a>
                ) : (
                  <Box width='30%'>
                    <ThinLoader />
                  </Box>
                )}
              </Box>
              <Typography color='textSecondary' variant='body1'>
                Please /start before linking your Telegram account.
              </Typography>
              <Stack alignItems='center' direction='row' spacing={1}>
                <TextField
                  disabled={isTelegramMetadataLoading || telegramMetadata.telegram_chat_id}
                  label='Telegram Username'
                  sx={{ width: '50%' }}
                  value={telegramMetadata.telegram_username ? telegramMetadata.telegram_username : ''}
                  variant='outlined'
                  onChange={(e) => updateTelegramMetadata('telegram_username', e.target.value)}
                />
                <Button
                  disabled={isTelegramMetadataLoading}
                  sx={{
                    width: '85px',
                    height: '36px',
                    backgroundColor: '#62526C',
                    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
                    borderRadius: 1,
                    padding: '6px 16px',
                    color: '#F3E5F5',
                    fontSize: 14,
                    fontFamily: 'IBM Plex Mono',
                    fontWeight: '500',
                    lineHeight: '24px',
                    letterSpacing: '0.40px',
                  }}
                  variant='contained'
                  onClick={
                    telegramMetadata.telegram_chat_id
                      ? () => handleUnlinkTelegram(telegramMetadata.telegram_username)
                      : () => handleLinkTelegram(telegramMetadata.telegram_username)
                  }
                >
                  {telegramMetadata.telegram_chat_id ? 'Unlink' : 'Link'}
                </Button>
              </Stack>
              {user.is_superuser && (
                <Table sx={{ width: '80%' }}>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant='h6'>Superuser Settings</Typography>
                    </StyledTableCell>
                    <StyledTableCell />
                  </TableRow>
                  <TableBody>
                    <TableRow>
                      <StyledBorderTableCell>
                        {renderNotifValueAsInputField(
                          USER_PREF_RECEIVE_ALL_NOTIFICATIONS,
                          user.preferences[USER_PREF_RECEIVE_ALL_NOTIFICATIONS]
                        )}
                      </StyledBorderTableCell>
                      <StyledBorderTableCell>
                        {renderPrefName(USER_PREF_RECEIVE_ALL_NOTIFICATIONS)}
                      </StyledBorderTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Stack>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography gutterBottom variant='h3'>
              Notifications
            </Typography>
            <Table>
              <TableBody>
                {getNotificationPreferences(user.preferences).map(([name, value]) => (
                  <TableRow key={name}>
                    <StyledTableCell>{renderNotifValueAsInputField(name, value)}</StyledTableCell>
                    <StyledTableCell>{renderPrefNameTG(name)}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Stack>
      </Paper>
      <Paper
        sx={{
          p: 2,
          mb: 4,
          width: '75%',
          position: 'relative',
          minHeight: '85px',
        }}
      >
        <Typography
          sx={{
            color: 'white',
            fontSize: 24,
            fontFamily: 'IBM Plex Mono',
            fontWeight: '400',
            lineHeight: '32.02px',
            wordWrap: 'break-word',
          }}
        >
          Two-factor authentication
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 1,
          }}
        >
          <Box>
            <Typography
              sx={{
                color: 'white',
                fontSize: 16,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '400',
                lineHeight: '28px',
                letterSpacing: '0.15px',
                wordWrap: 'break-word',
                mb: 0.5,
              }}
            >
              Authenticator app
            </Typography>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: 12,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '400',
                lineHeight: '21px',
                letterSpacing: '0.15px',
                wordWrap: 'break-word',
              }}
            >
              Secure your account by enabling two-factor authentication with an authenticator app.
            </Typography>
          </Box>
          <Button
            sx={{
              width: '85px',
              height: '36px',
              backgroundColor: '#62526C',
              boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
              borderRadius: 1,
              padding: '6px 16px',
              color: '#F3E5F5',
              fontSize: 14,
              fontFamily: 'IBM Plex Mono',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.40px',
            }}
            variant='contained'
            onClick={is2FAEnabled ? openResetDialog : handle2FASetup}
          >
            {is2FAEnabled ? 'Reset' : 'Enable'}
          </Button>
        </Box>
      </Paper>
      <Dialog open={qrDialogOpen} onClose={handleQrDialogClose}>
        <DialogContent
          sx={{
            width: 'auto',
            maxWidth: '380px',
            padding: '24px',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label='close'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
            onClick={handleQrDialogClose}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%', marginBottom: '16px' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 20,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '500',
                lineHeight: '32px',
                letterSpacing: '0.15px',
              }}
            >
              Enable authenticator app
            </Typography>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: 12,
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '22px',
                marginTop: '8px',
              }}
            >
              Secure your account by enabling two-factor authentication with an authenticator app.
            </Typography>
          </Box>
          <Box
            sx={{
              padding: '10px',
              background: 'white',
              borderRadius: '6px',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '24px',
            }}
          >
            <img alt='QR Code' src={qrCode} style={{ width: '167px', height: '162px', borderRadius: '6px' }} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: '600',
                lineHeight: '17.5px',
                marginBottom: '8px',
              }}
            >
              Authentication App code
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '18px',
                marginBottom: '10px',
              }}
            >
              {twoFAToken.map((digit, index) => (
                <TextField
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: 'center',
                      fontSize: '16px',
                      width: '18px',
                      height: '25px',
                      borderRadius: '4px',
                      border: '1px solid rgba(255, 181, 106, 0.5)',
                    },
                    autoComplete: 'off',
                  }}
                  inputRef={(el) => {
                    inputRefs.current[index] = el;
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`2FAToken_${index}`}
                  value={digit}
                  onChange={(e) => handleInputChange(e, index, twoFAToken, setTwoFAToken)}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.6)',
                fontFamily: 'Inter',
                fontSize: 12,
                lineHeight: '16px',
                marginBottom: '16px',
              }}
            >
              Enter the 6-digit code generated by your authenticator app to complete setup.
            </Typography>
          </Box>
          <Button
            sx={{
              width: '100%',
              backgroundColor: '#FFA345',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: 14,
              fontFamily: 'IBM Plex Mono',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.40px',
            }}
            variant='contained'
            onClick={handle2FAVerification}
          >
            Enable 2FA
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={resetDialogOpen} onClose={closeResetDialog}>
        <DialogContent
          sx={{
            width: 'auto',
            maxWidth: '380px',
            padding: '24px',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label='close'
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
            onClick={closeResetDialog}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%', marginBottom: '16px' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 20,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '500',
                lineHeight: '32px',
                letterSpacing: '0.15px',
              }}
            >
              Reset 2FA
            </Typography>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: 12,
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '22px',
                marginTop: '8px',
              }}
            >
              Write your 2FA code to reset.
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: '600',
                lineHeight: '17.5px',
                marginBottom: '8px',
              }}
            >
              Authentication App code
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '18px',
                marginBottom: '10px',
              }}
            >
              {reset2FAToken.map((digit, index) => (
                <TextField
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: 'center',
                      fontSize: '16px',
                      width: '18px',
                      height: '25px',
                      borderRadius: '4px',
                      border: '1px solid rgba(255, 181, 106, 0.5)',
                    },
                    autoComplete: 'off',
                  }}
                  inputRef={(el) => {
                    inputRefs.current[index] = el;
                  }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`2FA_reset_token_${index}`}
                  value={digit}
                  onChange={(e) => handleInputChange(e, index, reset2FAToken, setReset2FAToken)}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.6)',
                fontFamily: 'Inter',
                fontSize: 12,
                lineHeight: '16px',
                marginBottom: '16px',
              }}
            >
              Enter the 6-digit code generated by your authenticator app to reset 2FA.
            </Typography>
          </Box>
          <Button
            sx={{
              width: '100%',
              backgroundColor: '#FFA345',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: 14,
              fontFamily: 'IBM Plex Mono',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.40px',
            }}
            variant='contained'
            onClick={handle2FAReset}
          >
            Reset 2FA
          </Button>
        </DialogContent>
      </Dialog>
      {dicyMetadata.is_enabled && (
        <Paper sx={{ p: 2, width: '75%' }}>
          <Stack direction='column' spacing={2}>
            <Typography gutterBottom variant='h3'>
              Internal Netting
            </Typography>
            <Box alignItems='left' justifyContent='center'>
              {renderDicyConnectionState()}
            </Box>
          </Stack>
        </Paper>
      )}
    </Stack>
  );
}
