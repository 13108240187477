// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: rgba(0, 0, 0, 1); /* Replace with your desired color */
  margin: 0;
  padding: 0;
}

html {
  position: relative;
  height: 100%;
}

body {
  height: 100%;
  overflow-x: hidden;
  scrollbar-width: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(80, 80, 80);
  border-radius: 10px;
  border: solid 3px transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(10, 10, 10, 1);
  border-radius: 10px;
  border: solid 3px transparent;
  background-clip: content-box;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(20, 20, 20, 0.7);
}

html::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./css/index.css"],"names":[],"mappings":"AAIA;EACE,kCAAkC,EAAE,oCAAoC;EACxE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,yCAAyC;EACzC,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,6BAA6B;EAC7B,4BAA4B;AAC9B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,aAAa;AACf","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap');\n\nbody {\n  background-color: rgba(0, 0, 0, 1); /* Replace with your desired color */\n  margin: 0;\n  padding: 0;\n}\n\nhtml {\n  position: relative;\n  height: 100%;\n}\n\nbody {\n  height: 100%;\n  overflow-x: hidden;\n  scrollbar-width: none;\n}\n\n::-webkit-scrollbar-track {\n  box-shadow: inset 0 0 5px rgb(80, 80, 80);\n  border-radius: 10px;\n  border: solid 3px transparent;\n}\n\n::-webkit-scrollbar-thumb {\n  background: rgba(10, 10, 10, 1);\n  border-radius: 10px;\n  border: solid 3px transparent;\n  background-clip: content-box;\n}\n\n::-webkit-scrollbar {\n  width: 10px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: rgba(20, 20, 20, 0.7);\n}\n\nhtml::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
