import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import { Line } from 'react-chartjs-2';

export default function AssetAreaChart({ balanceData, selectedAccount }) {
  const theme = useTheme();
  const accountId = selectedAccount[0];

  // Move this to backend, filter out duplicates
  const selectedAccountData = selectedAccount && balanceData[accountId] ? balanceData[accountId] : [];

  const buildNetExposureData = () => {
    if (selectedAccountData.length === 0) {
      return [];
    }

    return selectedAccountData
      .map((row) => {
        return { x: new Date(row.date).getTime(), y: row.total_notional };
      })
      .sort((a, b) => a.x - b.x);
  };

  const netExposureData = buildNetExposureData();

  // use net exposure data's x values as it includes data from all symbols
  const allTimestamps = netExposureData.map((x) => x.x);

  const chartDataPointRadius = netExposureData.length === 1 ? 5 : 0;

  const smoothedNetExposureData = netExposureData;

  const data = {
    datasets: [
      {
        label: 'Net Exposure',
        data: smoothedNetExposureData,
        tension: 0.35,
        borderColor: theme.palette.portfolioChart.line,
        borderWidth: 1,
        pointRadius: chartDataPointRadius,
        fill: 'start',
        backgroundColor: (context) => {
          const { chart } = context;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0, theme.palette.portfolioChart.areaGradientBottom);
          gradient.addColorStop(1, theme.palette.portfolioChart.areaGradientTop);

          return gradient;
        },
      },
    ],
    labels: allTimestamps,
  };

  const config = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
        },
        ticks: {
          color: theme.palette.portfolioChart.ticks,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: theme.palette.portfolioChart.ticks,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        mode: 'index',
        callbacks: {
          label(tooltipItem) {
            const value = Number((tooltipItem.formattedValue || '').replace(/,/g, ''));
            if (value === 0) {
              return null;
            }
            return `${tooltipItem.dataset.label}: ${value}`;
          },
        },
        filter(tooltipItem) {
          const value = Number((tooltipItem.formattedValue || '').replace(/,/g, ''));
          return value !== '0';
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
  };

  return (
    <Box sx={{ width: '100%', height: '200px' }}>
      {selectedAccountData !== undefined ? (
        <Line data={data} options={config} />
      ) : (
        <Line data={{ datasets: [] }} options={config} />
      )}
    </Box>
  );
}
