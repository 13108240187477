import ConsensusDetailChip from '@/shared/components/ConsensusDetailChip';
import { CopyableValue } from '@/shared/components/CopyableValue';
import LabelTooltip from '@/shared/components/LabelTooltip';
import PrettyRelativeTimestamp from '@/shared/components/PrettyRelativeTimestamp';
import { StyledHeaderTableCellWithLine, StyledTableCell } from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { insertEllipsis } from '../../insertEllipsis';
import useDataConsensus from '../../proofUtils/useDataConsensus';
import { useProofsCache } from '../../proofUtils/useProofsCache';
import ConsensusTable, { createSkeletonRowRenderer } from '../components/TraderEpochConsensusTable';

// Define columns with tooltips
const columns = [
  {
    id: 'epoch',
    label: 'Epoch',
    tooltip: 'Click on an epoch to view detailed information',
    width: '8%',
    align: 'left',
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'left',
  },
  {
    id: 'attester',
    label: 'Attester',
    width: '12%',
    align: 'left',
  },
  {
    id: 'cid',
    label: 'CID',
    tooltip: 'Content Identifier for the data',
    width: '20%',
    align: 'left',
  },
  {
    id: 'merkleRoot',
    label: 'Merkle Root',
    tooltip: 'Cryptographic hash that represents all data in the epoch',
    width: '25%',
    align: 'left',
  },
  {
    id: 'timestamp',
    label: 'Timestamp',
    width: '15%',
    align: 'left',
  },
  {
    id: 'actions',
    label: 'Actions',
    width: '10%',
    align: 'right',
  },
];

/**
 * Data Consensus Table Row Component
 *
 * @param {Object} props - Component props
 * @param {Object} props.row - Row data
 * @param {string} props.traderId - Trader ID
 * @returns {React.ReactElement} Data consensus table row
 */
function DataConsensusTableRow({ row, traderId }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleEpochClick = (e) => {
    e.preventDefault();
    navigate(`/explorer/trader-epoch/${traderId}/${row.epoch}`);
  };

  return (
    <TableRow
      hover
      key={`row-${row.txHash || `index-${row.index}`}`}
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }}
    >
      <StyledTableCell>
        <Link
          component='a'
          href={`/explorer/trader-epoch/${traderId}/${row.epoch}`}
          sx={{
            cursor: 'pointer',
            textAlign: 'left',
            p: 0,
            display: 'inline-block',
          }}
          underline='hover'
          onClick={handleEpochClick}
        >
          {row.epoch?.toLocaleString()}
        </Link>
      </StyledTableCell>
      <StyledTableCell>
        <ConsensusDetailChip status={row.status} variant='status' />
      </StyledTableCell>
      <StyledTableCell>
        <CopyableValue
          displayValue={row.attester}
          value={row.attester.includes('...') ? row.fullAttester : row.attester}
        />
      </StyledTableCell>
      <StyledTableCell>
        {row.cid !== '-' ? <CopyableValue displayValue={insertEllipsis(row.cid, 6, 4)} value={row.cid} /> : row.cid}
      </StyledTableCell>
      <StyledTableCell>
        {row.merkleRoot !== '-' ? (
          <CopyableValue displayValue={insertEllipsis(row.merkleRoot, 6, 4)} value={row.merkleRoot} />
        ) : (
          row.merkleRoot
        )}
      </StyledTableCell>
      <StyledTableCell>
        <PrettyRelativeTimestamp timestamp={row.timestamp}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PrettyRelativeTimestamp.ISO variant='body2' />
            <PrettyRelativeTimestamp.Relative variant='body2' />
          </Box>
        </PrettyRelativeTimestamp>
      </StyledTableCell>
      <StyledTableCell align='right'>
        <Button
          size='small'
          sx={{
            borderRadius: '4px',
            minWidth: 'auto',
            padding: '4px 8px',
            textTransform: 'none',
          }}
          variant='outlined'
          onClick={() => navigate(`/explorer/proofs/${row.txHash || 'unknown'}`)}
        >
          Details
        </Button>
      </StyledTableCell>
    </TableRow>
  );
}

/**
 * Optimized Data Consensus Table Component with improved loading
 *
 * @param {Object} props - Component props
 * @param {string} props.traderId - Trader ID
 * @param {string|number} props.epoch - Epoch number
 * @param {Object} props.config - Configuration object for blockchain connection
 * @returns {React.ReactElement} Data consensus table
 */
export default function DataConsensusTable({ traderId, epoch, config }) {
  const [consensusData, setConsensusData] = useState([]);
  const { proofs } = useProofsCache();
  const [txHashes, setTxHashes] = useState([]);

  // Find transaction hashes for this trader and epoch
  useEffect(() => {
    const proofDetails = proofs.find((p) => p.traderId === traderId && Number(p.epoch) === Number(epoch));

    if (proofDetails && proofDetails.dataEvents) {
      const hashes = proofDetails.dataEvents.map((e) => e.transactionHash) || [];
      setTxHashes(hashes);
    } else {
      setTxHashes([]);
    }
  }, [traderId, epoch, proofs]);

  // Use the data consensus hook to fetch data
  const { dataEvents, dataConsensus, loading: isLoading, error } = useDataConsensus(txHashes, config);

  // Transform data events into table rows
  useEffect(() => {
    if (!dataEvents || dataEvents.length === 0) {
      setConsensusData([]);
      return;
    }

    // Map data events to table rows
    const tableData = dataEvents.map((event, index) => {
      // Determine status based on event data
      let status = 'Proposed';
      if (event.skipped) {
        status = 'Skipped';
      } else if (dataConsensus?.hasConsensus && event.data?.merkleRoot === dataConsensus.record?.merkleRoot) {
        status = 'Confirmed';
      }

      // Store full attester address for copying
      const fullAttester = event.attester || 'N/A';
      const shortAttester = event.attester ? `${event.attester.slice(0, 6)}...${event.attester.slice(-4)}` : 'N/A';

      return {
        epoch: Number(event.epoch || epoch),
        status,
        attester: shortAttester,
        fullAttester,
        cid: event.data?.cid || '-',
        merkleRoot: event.data?.merkleRoot || '-',
        timestamp: event.timestamp || moment().unix(),
        txHash: event.transactionHash,
        index, // Add index for key generation
      };
    });

    setConsensusData(tableData);
  }, [dataEvents, dataConsensus, epoch]);

  // Create a row renderer function
  const renderRow = (row) => (
    <DataConsensusTableRow key={`row-${row.txHash || `index-${row.index}`}`} row={row} traderId={traderId} />
  );

  // Create a skeleton row renderer
  const renderSkeletonRow = createSkeletonRowRenderer(columns);

  return (
    <ConsensusTable
      columns={columns}
      data={consensusData}
      emptyMessage='No consensus data available for this epoch'
      error={error ? `Error loading data consensus information: ${error}` : null}
      loading={isLoading}
      renderRow={renderRow}
      renderSkeletonRow={renderSkeletonRow}
    />
  );
}
