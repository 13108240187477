import { getStaticChartingLibraryPath, getTradingViewDataFeed } from '@/apiServices';
import { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material';
import { widget } from '@/../public/charting_library/charting_library.esm';
import CustomDatafeed from './CustomDataFeed';

const DISABLED_MOBILE_FEATURES = Object.freeze(['left_toolbar', 'timeframes_toolbar']);

export function TradingViewChart({ pair, relevantExchangeName, isMobile = false }) {
  const chartContainerRef = useRef(null);
  const widgetRef = useRef(null);
  const theme = useTheme();

  const symbolName = pair && `${relevantExchangeName}:${pair}`;

  useEffect(() => {
    // Get or create anonymous user ID
    let userId = localStorage.getItem('tradingview_user_id');
    if (!userId) {
      userId = `anon_${Math.random().toString(36).substr(2, 9)}`;
      localStorage.setItem('tradingview_user_id', userId);
    }

    const createWidget = async (symbol) => {
      if (widgetRef.current || !chartContainerRef.current || !symbol) {
        return;
      }

      let disabled_features = [
        'symbol_search_hot_key',
        'header_symbol_search',
        'header_compare',
        'symbol_search',
        'show_spread_operators',
        'legend_inplace_edit',
        'chart_animation',
        'disable_pulse_animation',
      ];
      if (isMobile) {
        disabled_features = [...disabled_features, ...DISABLED_MOBILE_FEATURES];
      }

      // eslint-disable-next-line new-cap
      widgetRef.current = new widget({
        container: chartContainerRef.current,
        locale: 'en',
        fullscreen: false,
        autosize: true,
        theme: 'dark',
        toolbar_bg: theme.palette.background.paper,
        timezone: 'Etc/UTC',
        symbol,
        interval: '15',
        datafeed: new CustomDatafeed(),
        loading_screen: {
          backgroundColor: theme.palette.background.paper,
          foregroundColor: theme.palette.primary.main,
          enabled: true,
        },
        disabled_features,
        library_path: getStaticChartingLibraryPath(),
        client_id: 'treadfi.com',
        user_id: userId,
        auto_save_delay: 10,
        load_last_chart: true,

        save_load_adapter: {
          getAllCharts: () => {
            try {
              const charts = [];
              for (let i = 0; i < localStorage.length; i += 1) {
                const key = localStorage.key(i);
                if (key.startsWith('chart_')) {
                  const chart = JSON.parse(localStorage.getItem(key));
                  charts.push(chart);
                }
              }
              return Promise.resolve(charts);
            } catch (error) {
              // console.error('Error loading charts:', error);
              return Promise.resolve([]);
            }
          },

          saveChart: async (chartData) => {
            try {
              const key = `chart_${chartData.id}`;
              localStorage.setItem(
                key,
                JSON.stringify({
                  ...chartData,
                  timestamp: new Date().getTime(),
                })
              );
              return Promise.resolve();
            } catch (error) {
              // console.error('Error saving chart:', error);
              return Promise.reject(error);
            }
          },

          removeChart: async (chartId) => {
            try {
              localStorage.removeItem(`chart_${chartId}`);
              return Promise.resolve();
            } catch (error) {
              // console.error('Error removing chart:', error);
              return Promise.reject(error);
            }
          },

          getChartContent: async (chartId) => {
            try {
              const chart = localStorage.getItem(`chart_${chartId}`);
              return Promise.resolve(chart ? JSON.parse(chart).content : null);
            } catch (error) {
              // console.error('Error getting chart:', error);
              return Promise.resolve(null);
            }
          },
        },
      });
    };

    createWidget(symbolName);

    // Cleanup function
    return () => {
      const currentWidget = widgetRef.current;
      if (currentWidget) {
        try {
          // Try to save first
          if (currentWidget.activeChart) {
            const chart = currentWidget.activeChart();
            if (chart) {
              chart.save(() => {
                // Only remove after save completes
                widgetRef.current = null;
                currentWidget.remove();
              });
              return; // Exit early, widget will be removed in callback
            }
          }

          widgetRef.current = null;
          currentWidget.remove();
        } catch (e) {
          try {
            widgetRef.current = null;
            currentWidget.remove();
          } catch (removeError) {
            // nothing
          }
        }
      }
    };
  }, [symbolName, theme, isMobile]);

  return <div id='tv_chart_container' ref={chartContainerRef} style={{ width: '100%', height: '100%' }} />;
}
