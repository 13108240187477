export const OPEN_NEW_TAB_ON_SUBMIT = 'order_submit_new_tab';
export const NOTIFICATION_ORDER_COMPLETE = 'notification_order_complete';
export const NOTIFICATION_ORDER_PARTIALLY_FILLED = 'notification_order_partially_filled';
export const NOTIFICATION_ORDER_FIRST_FILL = 'notification_order_first_fill';
export const NOTIFICATION_ORDER_ENDED = 'notification_order_ended';
export const NOTIFICATION_ORDER_PROGRESS = 'notification_order_progress';
export const NOTIFICATION_ORDER_OVERFILL = 'notification_order_overfill';
export const NOTIFICATION_ORDER_PAUSE_WORKFLOW = 'notification_order_pause_workflow';
export const NOTIFICATION_ORDER_RESUME_WORKFLOW = 'notification_order_resume_workflow';
export const USER_PREF_RECEIVE_ALL_NOTIFICATIONS = 'receive_all_notifications';

export const MARKET_TYPES = ['perp', 'cash', 'spot', 'future', 'option', 'uncatagorized'];

// order here matters, it is used as a priority list for matching pairs
export const CASH_ASSETS = ['USDT', 'USDC', 'USDE', 'DAI', 'FDUSD', 'USDD', 'TUSD', 'BUSD', 'USDK', 'PYUSD', 'USD'];
