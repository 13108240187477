import { Box, CircularProgress, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import BalancePreview from '../../../shared/fields/BalancePreview';
import { NumericFormatCustom } from '../../../shared/fields/NumberFieldFormat';
import { PercentageSlider } from '../../../shared/fields/Sliders';
import { ignoreScrollEvent, noArrowStyle, numberWithCommas } from '../../../util';
import { useAccountBalanceContext } from './AccountBalanceContext';

export function QtyInputField({
  contractQty,
  convertedQtyLoading,
  handleQtyOnChange,
  isBase,
  isBuySide,
  isReadyToPickQty,
  onPercentageChangeCommit,
  oppositeQtyExists,
  percentage,
  qty,
  qtyPlaceholder,
  selectedPair,
  setPercentage,
  totalBaseAsset,
  totalQuoteAsset,
}) {
  const { isBalanceLoading, balances } = useAccountBalanceContext();

  const renderEndAdornment = () => {
    if (convertedQtyLoading && oppositeQtyExists) {
      return (
        <InputAdornment position='end'>
          <CircularProgress size={20} sx={{ color: 'info.main' }} />
        </InputAdornment>
      );
    }

    if (contractQty) {
      return (
        <InputAdornment position='end'>
          <Stack alignItems='left' direction='column'>
            <Typography color='grey.main' variant='body2'>
              {Number(contractQty).toFixed(0)}
            </Typography>
            <Typography color='grey.main' variant='body2'>
              Contracts
            </Typography>
          </Stack>
        </InputAdornment>
      );
    }

    return null;
  };

  const isBalancesLoaded = Object.keys(balances).length > 0;

  return (
    <Tooltip
      disableFocusListener={isReadyToPickQty}
      disableHoverListener={isReadyToPickQty}
      title='Account(s) and trading pair must be selected'
    >
      <Box>
        <TextField
          fullWidth
          autoComplete='off'
          disabled={!isReadyToPickQty}
          InputProps={{
            step: 'any',
            endAdornment: renderEndAdornment(),
            inputComponent: NumericFormatCustom,
          }}
          placeholder={numberWithCommas(qtyPlaceholder)}
          sx={noArrowStyle}
          value={qty}
          onChange={(event) => handleQtyOnChange(event.target.value)}
          onWheel={ignoreScrollEvent}
        />
        <PercentageSlider
          ariaLabel={isBase ? 'Base Percentage' : 'Quote Percentage'}
          disabled={!isReadyToPickQty || (isBase ? isBuySide : !isBuySide) || !isBalancesLoaded}
          percentage={percentage}
          setPercentage={setPercentage}
          onChangeCommitted={onPercentageChangeCommit}
        />
        <BalancePreview
          isBalanceLoading={isBalanceLoading}
          isBase={isBase}
          isReadyToPickQty={isReadyToPickQty}
          selectedPair={selectedPair}
          totalBaseAsset={totalBaseAsset}
          totalQuoteAsset={totalQuoteAsset}
        />
      </Box>
    </Tooltip>
  );
}
