import { getArweaveTransactionRaw } from '@/apiServices';
import { useDecryptTrade } from '@/pages/explorer/tradeUtils/useDecryptTrade';
import PrettyRelativeTimestamp from '@/shared/components/PrettyRelativeTimestamp';
import { AccountsContext } from '@/shared/context/AccountsProvider';
import { StyledHeaderTableCellWithLine, StyledTableCell } from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import { Alert, Button, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { renderTradeId } from '../utils/tradeId';

const columns = [
  { id: 'trade_id', label: 'Trade ID', width: 200, align: 'left' },
  { id: 'symbol', label: 'Symbol', width: 100, align: 'left' },
  { id: 'side', label: 'Side', width: 80, align: 'right' },
  { id: 'price', label: 'Price', width: 120, align: 'right' },
  { id: 'amount', label: 'Amount', width: 120, align: 'right' },
  { id: 'usd_volume', label: 'USD Volume', width: 140, align: 'right' },
  { id: 'timestamp', label: 'Timestamp', width: 180, align: 'left' },
];

async function fetchRawData(tradeId) {
  try {
    const rawResponse = await getArweaveTransactionRaw(tradeId);
    return rawResponse?.raw_data || null;
  } catch (err) {
    console.error('Error loading raw transaction data:', err);
    return null;
  }
}

function DecryptedTradeRow({ trade }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { accounts } = useContext(AccountsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <TableRow hover>
      <StyledTableCell align='left'>{renderTradeId(trade.trade_id)}</StyledTableCell>
      <StyledTableCell align='left'>{trade.symbol || 'N/A'}</StyledTableCell>
      <StyledTableCell
        align='right'
        sx={{
          color: trade.side === 'Buy' ? 'success.main' : 'error.main',
        }}
      >
        {trade.side || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {trade.price?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {Number(trade.amount)?.toLocaleString('en-US', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='right' sx={{ fontFamily: 'monospace' }}>
        {trade.usd_volume?.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) || 'N/A'}
      </StyledTableCell>
      <StyledTableCell align='left'>
        <PrettyRelativeTimestamp timestamp={trade.timestamp}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PrettyRelativeTimestamp.ISO />
            <PrettyRelativeTimestamp.Relative />
          </Box>
        </PrettyRelativeTimestamp>
      </StyledTableCell>
    </TableRow>
  );
}

export default function TradeHistoryTable({ tradeBlob, loading, error, retryFetch, isAuthorized, hideTitle = false }) {
  const theme = useTheme();
  const [rawData, setRawData] = useState(null);
  const [rawLoading, setRawLoading] = useState(false);
  const { accounts } = useContext(AccountsContext);

  // Debug logging
  useEffect(() => {
    console.debug('[TradeHistoryTable] Props:', {
      tradeBlob,
      loading,
      error,
      isAuthorized,
    });
  }, [tradeBlob, loading, error, isAuthorized]);

  useEffect(() => {
    async function fetchData() {
      if (!tradeBlob || !isAuthorized) return;

      setRawLoading(true);
      try {
        // Fetch raw data for the trade
        const result = await fetchRawData(tradeBlob.id);
        setRawData(result);
        console.debug('[TradeHistoryTable] Raw data fetched:', !!result);
      } catch (err) {
        console.error('Error fetching raw data:', err);
      } finally {
        setRawLoading(false);
      }
    }
    fetchData();
  }, [tradeBlob, isAuthorized]);

  // Debug logging for decryption inputs
  useEffect(() => {
    console.debug('[TradeHistoryTable] Decryption inputs:', {
      blobId: tradeBlob?.id,
      traderId: tradeBlob?.trader_id,
      hasRawData: !!rawData,
      hasAccounts: !!accounts,
    });
  }, [tradeBlob, rawData, accounts]);

  const { decryptedData, decryptLoading, decryptError, decrypt } = useDecryptTrade(
    tradeBlob?.id || '',
    tradeBlob?.trader_id || '',
    rawData,
    accounts
  );

  // Debug logging for decryption results
  useEffect(() => {
    console.debug('[TradeHistoryTable] Decryption results:', {
      hasDecryptedData: !!decryptedData,
      decryptLoading,
      decryptError: decryptError ? decryptError.message : null,
      decryptedCount: decryptedData?.length || 0,
    });
  }, [decryptedData, decryptLoading, decryptError]);

  // Call decrypt when raw data is available and user is authorized
  useEffect(() => {
    if (rawData && isAuthorized && decrypt) {
      console.debug('[TradeHistoryTable] Calling decrypt function');
      decrypt();
    }
  }, [rawData, isAuthorized, decrypt]);

  const renderTableBody = () => {
    if (loading) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <ScaleLoader color={theme.palette.primary.main} size={8} />
          </StyledTableCell>
        </TableRow>
      );
    }
    if (error) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Typography color='error'>Error loading trade history</Typography>
            {retryFetch && (
              <Button color='primary' size='small' sx={{ mt: 1 }} variant='outlined' onClick={retryFetch}>
                Retry
              </Button>
            )}
          </StyledTableCell>
        </TableRow>
      );
    }

    if (!isAuthorized) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Alert severity='warning' sx={{ my: 2 }}>
              This data is encrypted. Only the trade owner can access this information.
            </Alert>
          </StyledTableCell>
        </TableRow>
      );
    }

    if (!tradeBlob) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Typography>No trade data available</Typography>
          </StyledTableCell>
        </TableRow>
      );
    }

    if (rawLoading || decryptLoading) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <ScaleLoader color={theme.palette.primary.main} size={8} />
          </StyledTableCell>
        </TableRow>
      );
    }

    if (decryptError) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Typography color='error'>Error decrypting trade data</Typography>
            <Box
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: 1,
                fontFamily: 'monospace',
                p: 1,
                mt: 1,
                maxHeight: '100px',
                overflow: 'auto',
              }}
            >
              {decryptError.message}
            </Box>
          </StyledTableCell>
        </TableRow>
      );
    }

    if (!decryptedData || decryptedData.length === 0) {
      return (
        <TableRow>
          <StyledTableCell align='center' colSpan={columns.length}>
            <Typography>No decrypted trade data available</Typography>
          </StyledTableCell>
        </TableRow>
      );
    }

    return decryptedData.map((trade) => <DecryptedTradeRow key={trade.trade_id} trade={trade} />);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {!hideTitle && (
        <Typography sx={{ mb: 2 }} variant='h5'>
          Trade History
        </Typography>
      )}
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <TableContainer
          sx={{
            mt: 2,
            maxHeight: '400px',
            overflow: 'auto',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Table stickyHeader aria-label='trade history table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledHeaderTableCellWithLine align={column.align} key={column.id} style={{ width: column.width }}>
                    {column.label}
                  </StyledHeaderTableCellWithLine>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
