import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Button, Collapse, Divider, Icon, Typography, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { ValueTypography } from '@/shared/components/MuiComponents';
import ProgressBar from '@/shared/fields/ProgressBar/ProgressBar';
import { numberWithCommas, smartRound, accurateToFixed, titleCase } from '@/util';
import EmptyBar from '@/shared/components/EmptyBar';
import { formatDateTime } from '../orderTable/util';
import { OrderInfo, OrderInfoTypography } from './OrderInfo';

// Duplicated from util.jsx for new theme, refactor once dashboard is converted
const parseStatus = (status) => {
  switch (status) {
    case 'SUBMITTED':
      return <ValueTypography color='primary.main'>Submitted</ValueTypography>;
    case 'CANCELED':
      return <ValueTypography color='error.main'>Canceled</ValueTypography>;
    case 'COMPLETE':
      return <ValueTypography color='success.main'>Finished</ValueTypography>;
    case 'SCHEDULED':
      return <ValueTypography color='secondary.main'>Scheduled</ValueTypography>;
    case 'PAUSED':
      return <ValueTypography color='info.main'>Paused</ValueTypography>;
    default:
      return <ValueTypography color='primary.main'>Active</ValueTypography>;
  }
};

export function OrderSummaryAdditional({
  notes,
  order_condition_normal,
  order_variable_normal,
  order_condition_vars,
  resume_condition_normal,
  max_otc,
  pos_side,
  alpha_tilt,
  engine_passiveness,
  exposure_tolerance,
  pov_limit,
  isPovTarget,
  durationDisplay,
  schedule_discretion,
  strategy_params,
}) {
  const [isAdditionalOpen, setIsAdditionalOpen] = useState(false);
  const { max_clip_size } = strategy_params;
  const strategyParamsKeys = Object.keys(strategy_params);

  return (
    <Stack direction='column'>
      <Collapse in={isAdditionalOpen}>
        <Stack direction='column' spacing={3}>
          <Divider />
          <Grid container spacing={3}>
            {notes && (
              <Grid xs={12}>
                <OrderInfoTypography header='Notes' value={notes} />
              </Grid>
            )}
            {order_condition_normal && (
              <Grid xs={12}>
                <OrderInfo header='Order Condition'>
                  <ValueTypography>{order_condition_normal}</ValueTypography>
                  {Object.entries(order_variable_normal).map(([k, v]) => {
                    return (
                      <li key={`clause ${k}`}>
                        <Typography variant='small2'>
                          {k} = <span style={{ fontWeight: 'bold' }}>{smartRound(order_condition_vars[k][1])}</span> :{' '}
                          {v}
                        </Typography>
                      </li>
                    );
                  })}
                </OrderInfo>
              </Grid>
            )}
            {resume_condition_normal && (
              <Grid xs={12}>
                <OrderInfoTypography header='Resume Condition' value={resume_condition_normal} />
              </Grid>
            )}
            {max_otc && max_otc !== 0 ? (
              <Grid xs={6}>
                <OrderInfoTypography header='Max OTC Percentage' value={`${(Number(max_otc) * 100).toFixed(0)}%`} />
              </Grid>
            ) : null}
            {pos_side && (
              <Grid xs={6}>
                <OrderInfoTypography header='Position Side' value={pos_side} />
              </Grid>
            )}
            <Grid xs={3}>
              <OrderInfoTypography header='Alpha Tilt' value={parseFloat(alpha_tilt).toFixed(2)} />
            </Grid>
            <Grid xs={3}>
              <OrderInfoTypography header='Passiveness' value={parseFloat(engine_passiveness).toFixed(2)} />
            </Grid>
            <Grid xs={3}>
              <OrderInfoTypography header='Discretion' value={parseFloat(schedule_discretion).toFixed(2)} />
            </Grid>
            {max_clip_size && (
              <Grid xs={3}>
                <OrderInfoTypography header='Max Clip Size' value={parseFloat(max_clip_size)} />
              </Grid>
            )}
            {exposure_tolerance && (
              <Grid xs={3}>
                <OrderInfoTypography header='Exposure Tolerance' value={parseFloat(exposure_tolerance).toFixed(2)} />
              </Grid>
            )}
            {pov_limit && pov_limit > 0 && (
              <Grid xs={3}>
                <OrderInfoTypography header='POV Limit' value={`${accurateToFixed(parseFloat(pov_limit) * 100, 2)}%`} />
              </Grid>
            )}
            {isPovTarget && (
              <Grid xs={3}>
                <OrderInfoTypography header='Duration' value={`~${durationDisplay}`} />
              </Grid>
            )}
            {strategyParamsKeys.length !== 0 &&
              Object.values(strategy_params).includes(true) &&
              strategyParamsKeys
                .filter((x) => x !== 'max_clip_size' && strategy_params[x])
                .map((key) => (
                  <Grid key={key} xs={3}>
                    <OrderInfoTypography header={titleCase(key)} value='Yes' />
                  </Grid>
                ))}
          </Grid>
        </Stack>
      </Collapse>
      <Button
        sx={{
          color: 'text.dark',
        }}
        onClick={() => setIsAdditionalOpen(!isAdditionalOpen)}
      >
        <Typography color='secondary' variant='small2'>
          {isAdditionalOpen ? 'Hide Details' : 'Show Details'}
        </Typography>
        <Icon>{isAdditionalOpen ? <ExpandLess /> : <ExpandMore />}</Icon>
      </Button>
    </Stack>
  );
}

export function OrderSummaryBottomSection({
  time_start,
  status,
  market_type,
  executed_notional,
  executed_price,
  executed_token,
  target_base_token,
  pct_filled,
  pov_limit,
  pov_target,
  points_earned,
  isMultiView = false,
}) {
  return (
    <Grid container spacing={3}>
      <Grid xs={6}>
        <OrderInfoTypography header='Time Start' value={time_start ? formatDateTime(time_start) : '-'} />
      </Grid>
      <Grid xs={6}>
        <OrderInfo header='Status'>{parseStatus(status)}</OrderInfo>
      </Grid>
      {!isMultiView && (
        <>
          <Grid xs={6}>
            <OrderInfoTypography
              header='Executed Notional'
              value={
                executed_notional ? `$${numberWithCommas(smartRound(Number(executed_notional), 2))}` : <EmptyBar />
              }
            />
          </Grid>
          <Grid xs={6}>
            <OrderInfoTypography
              header='Avg Execution Price'
              value={executed_price ? `$${numberWithCommas(smartRound(Number(executed_price), 2))}` : <EmptyBar />}
            />
          </Grid>
          <Grid xs={6}>
            <OrderInfoTypography
              header='Executed Quantity'
              value={
                executed_token ? (
                  `${numberWithCommas(smartRound(Number(executed_token), 2))} ${target_base_token}`
                ) : (
                  <EmptyBar />
                )
              }
            />
          </Grid>
        </>
      )}
      {points_earned > 0 && (
        <Grid xs={6}>
          <OrderInfoTypography header='Points Earned' value={smartRound(points_earned)} />
        </Grid>
      )}
      <Grid xs={12}>
        <OrderInfo header='Fill Percentage'>
          <ProgressBar
            fullWidth
            barStyleOverride={{ height: '18px' }}
            containerStyleOverride={{
              width: '100%',
              height: '18px',
              marginTop: '8px',
            }}
            isPov={pov_limit || pov_target}
            orderStatus={status}
            progress={Math.round(Number(pct_filled))}
          />
        </OrderInfo>
      </Grid>
    </Grid>
  );
}
