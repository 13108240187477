import { Close, KeyboardArrowDown, KeyboardArrowUp, Search, Star, StarBorder } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Popper,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Tabs,
  Tab,
  ThemeProvider,
  SwipeableDrawer,
} from '@mui/material';
import { theme } from '@/theme/theme';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import debounce from 'lodash.debounce';
import { matchSorter } from 'match-sorter';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import useViewport from '@/shared/hooks/useViewport';
import { addUserFavouritePairs, deleteUserFavouritePairs } from '../../../apiServices';
import { prettyDollars, prettyPrice, smartRound, shortenNumber } from '../../../util';
import { useUserMetadata } from '../../../shared/context/UserMetadataProvider';
import { ExchangeIcons } from '../../../shared/iconUtil';
import getBaseTokenIcon from '../../../../images/tokens';
import useGetExchangeTickerData from './hooks/useGetExchangeTickerData';

const MARKET_TYPE_TABS = [
  { label: 'All', value: 'all' },
  { label: 'Favorites', value: 'favorites' },
  { label: 'Spot', value: 'spot' },
  { label: 'Perps', value: 'perp' },
  { label: 'Futures', value: 'futures' },
];

const Cell = styled(Box)(() => ({
  fontSize: '0.85rem',
  padding: 1,
  justifySelf: 'center',
  alignSelf: 'center',
}));

function Row({ index, style, data }) {
  const {
    pairs,
    favourites,
    toggleFavorite,
    balanceEnabled,
    selectedItemIndex,
    onSelectPair,
    isMobile,
    pairColumnWidth,
    exchangeColumnWidth,
    balanceColumnWidth,
    dailyChangeColumnWidth,
    volumeColumnWidth,
  } = data;
  const pair = pairs[index];
  if (pair.header) {
    return (
      <Button
        fullWidth
        endIcon={pair.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{
          ...style,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: selectedItemIndex === index ? 'text.offBlack' : '',
        }}
        variant='secondary'
        onClick={pair.onClick}
      >
        <Typography color='grey' variant='body1'>
          {pair.header}
        </Typography>
      </Button>
    );
  }

  const pairDisplayIcon = getBaseTokenIcon(pair.base);

  const renderBalance = () => {
    return (
      <Stack direction='row' gap={1}>
        <Typography variant='body3'>{pair.balance === 0 ? '-' : prettyDollars(pair.balance)}</Typography>
      </Stack>
    );
  };

  const favouriteOnClick = (event) => {
    event.stopPropagation();
    toggleFavorite(pair.id);
  };

  const isFavourited = favourites[pair.id];

  const volume = pair.ticker?.volume24h;
  const priceChange = pair.ticker?.priceChange24h;
  const pricePctChange = pair.ticker?.pricePctChange24h;

  let priceChangeColor;
  if (priceChange) {
    priceChangeColor = priceChange > 0 ? 'side.buy' : 'side.sell';
  }

  return (
    <Stack
      direction='row'
      spacing={1}
      style={{ ...style, width: '100%' }}
      sx={{
        backgroundColor: selectedItemIndex === index ? 'text.offBlack' : '',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'text.offBlack',
        },
      }}
      onClick={() => onSelectPair(pair)}
    >
      <Cell width={pairColumnWidth}>
        <Stack alignItems='center' direction='row' spacing={1}>
          <IconButton sx={{ color: isFavourited ? 'primary.main' : 'grey.main' }} onClick={favouriteOnClick}>
            {isFavourited ? <Star sx={{ fontSize: '1rem' }} /> : <StarBorder sx={{ fontSize: '1rem' }} />}
          </IconButton>
          <Box
            sx={{
              height: '1.4rem',
              width: '1.4rem',
              minHeight: '1.4rem',
              minWidth: '1.4rem',
            }}
          >
            {pairDisplayIcon && (
              <img alt='Token Icon' src={pairDisplayIcon} style={{ height: 'inherit', width: 'inherit' }} />
            )}
          </Box>
          <Typography
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
            variant='body3'
          >
            {pair.id}
          </Typography>
        </Stack>
      </Cell>
      {!isMobile && <Cell width={balanceColumnWidth}>{balanceEnabled && renderBalance()}</Cell>}
      {!isMobile && (
        <Cell width={dailyChangeColumnWidth}>
          <Typography color={priceChangeColor} variant='body3'>
            {pricePctChange ? `${smartRound(pricePctChange, 2)}%` : '-'}
          </Typography>
        </Cell>
      )}
      <Cell width={volumeColumnWidth}>
        <Typography variant='body3'>{volume ? `$${shortenNumber(volume)}` : '-'}</Typography>
      </Cell>
      <Cell width={exchangeColumnWidth}>
        <ExchangeIcons exchanges={pair.exchanges} pairId={pair.id} />
      </Cell>
    </Stack>
  );
}

function TableHeader({ text, width, onSort = null, asc = null, textStyle = {} }) {
  const [hover, setHover] = useState(false);
  const sortProps = onSort
    ? {
        sx: { cursor: 'pointer' },
        onClick: onSort,
        onMouseEnter: () => setHover(true),
        onMouseLeave: () => setHover(false),
      }
    : {};

  let SortIcon = null;
  if (asc !== null) {
    SortIcon = asc ? ExpandLessIcon : ExpandMoreIcon;
  }

  return (
    <Cell align='left' width={width} {...sortProps}>
      <Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1}>
        <Typography variant='small1' {...textStyle}>
          {text}
        </Typography>
        {hover && SortIcon === null ? (
          <ExpandMoreIcon fontSize='small' sx={{ color: 'info' }} />
        ) : (
          SortIcon !== null && <SortIcon fontSize='small' sx={{ color: 'info' }} />
        )}
      </Stack>
    </Cell>
  );
}

function PairSelector({
  accounts,
  balances,
  favourites,
  multiOrder = false,
  ChainedOrders = false,
  pairs,
  selectedAccounts,
  selectedPairName,
  setFavourites,
  setSelectedPair,
  showAlert,
  handlePairSelect = undefined,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [confirmedSearchQuery, setConfirmedSearchQuery] = useState('');
  const [selectedMarketType, setSelectedMarketType] = useState('perp');
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const listRef = useRef(null);
  const searchRef = useRef(null);
  const popperRef = useRef(null);
  const pairDisplayRef = useRef(null);
  const { isMobile } = useViewport();

  const pairColumnWidth = isMobile ? '50%' : '30%';
  const exchangeColumnWidth = isMobile ? '25%' : '15%';
  const balanceColumnWidth = '20%';
  const dailyChangeColumnWidth = '15%';
  const volumeColumnWidth = isMobile ? '25%' : '20%';

  const [expandedMarkets, setExpandedMarkets] = useState({
    perp: true,
    spot: true,
    future: true,
  });
  const [columnSort, setColumnSort] = useState({
    column: 'volume',
    asc: false,
  });
  const [hideZeroBalances, setHideZeroBalances] = useState(false);

  const isMac = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);

  const { user } = useUserMetadata();

  const toggleExpandedMarket = (market) => {
    setExpandedMarkets((prev) => ({ ...prev, [market]: !prev[market] }));
  };

  const toggleColumnSort = (toggledColumn) => {
    const { column, asc } = columnSort;
    setColumnSort({
      column: toggledColumn,
      asc: toggledColumn === column ? !asc : true,
    });
  };

  const toggleHideZeroBalances = (event) => {
    setHideZeroBalances((prev) => !prev);
  };
  const saveToBackend = (pair, isFavorited) => {
    // Replace with your actual backend call
    if (isFavorited) {
      try {
        addUserFavouritePairs([pair]);
      } catch (error) {
        showAlert({
          severity: 'error',
          message: `Failed to add pair to favorites: ${error.message}`,
        });
        return false;
      }
    } else {
      try {
        deleteUserFavouritePairs([pair]);
      } catch (error) {
        showAlert({
          severity: 'error',
          message: `Failed to remove pair from favorites: ${error.message}`,
        });
        return false;
      }
    }

    return true;
  };

  const toggleFavorite = (pair) => {
    setFavourites((prevFavorites) => {
      const newFavorites = { ...prevFavorites, [pair]: !prevFavorites[pair] };
      const saveSuccess = saveToBackend(pair, newFavorites[pair]);

      if (!saveSuccess) {
        return prevFavorites;
      }

      return newFavorites;
    });
  };

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }

    setAnchorEl(event?.currentTarget || pairDisplayRef.current);
    if (pairDisplayRef.current) {
      pairDisplayRef.current.focus();
    }
  };

  const handleMarketTypeButtonClick = (buttonType) => {
    setSelectedMarketType(buttonType);
  };

  const debouncedHandleSearchChange = useCallback(
    debounce((event) => {
      setConfirmedSearchQuery(event.target.value);

      if (event.target.value !== '') {
        setAnchorEl(pairDisplayRef.current);
      }
    }, 300),
    []
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    debouncedHandleSearchChange(event);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Focus on search bar if ref is rendered
   */
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef.current]);

  /**
   * Short ctrl/cmd + k to open pair selector
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [anchorEl]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target) &&
        !pairDisplayRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popperRef]);

  let qualifiedAccounts = [];

  if (accounts !== undefined) {
    qualifiedAccounts =
      selectedAccounts && selectedAccounts.length === 0
        ? Object.values(accounts)
        : selectedAccounts.map((accountId) => accounts[accountId]);
  }

  const selectedExchangeName = selectedAccounts.length > 0 ? accounts[selectedAccounts[0]].exchangeName : 'Binance';

  const { tickerData } = useGetExchangeTickerData(selectedExchangeName);

  const calculateNotionalBalance = useCallback(
    (symbol, exchanges) => {
      let totalAmount = 0;

      qualifiedAccounts
        .filter((account) => exchanges.includes(account.exchangeName))
        .forEach((account) => {
          if (!balances[account.id]) {
            return;
          }

          balances[account.id].assets.forEach((asset) => {
            if (asset.symbol === symbol) {
              totalAmount += asset.notional;
            }
          });
        });

      return totalAmount;
    },
    [balances, qualifiedAccounts]
  );

  const filteredPairs = useMemo(() => {
    let tradingPairs = pairs;

    /**
     * Augment balance to pair
     */
    tradingPairs = tradingPairs.map((pair) => {
      const balance = calculateNotionalBalance(pair.is_contract ? pair.id : pair.base, pair.exchanges);

      return {
        ...pair,
        balance,
      };
    });

    /**
     * Augment ticker data to pair
     */
    tradingPairs = tradingPairs.map((pair) => {
      const ticker = tickerData[pair.id];
      return {
        ...pair,
        ticker,
      };
    });

    /**
     * Filter based on tab selection of [...market type, all, favourite]
     */
    if (selectedMarketType) {
      if (selectedMarketType === 'favorites') {
        tradingPairs = tradingPairs.filter((pair) => favourites[pair.id]);
      } else if (selectedMarketType !== 'all') {
        tradingPairs = tradingPairs.filter((pair) => pair.market_type === selectedMarketType);
      }
    }

    /**
     * Filter for account exchanges if account is selected
     */
    if (selectedAccounts && selectedAccounts.length > 0) {
      tradingPairs = tradingPairs.filter((pair) => {
        const accountExchanges = selectedAccounts.map((accountId) => accounts[accountId].exchangeName);
        return accountExchanges.every((exchange) => pair.exchanges.includes(exchange));
      });
    }

    /**
     * Filter out 0 balances if selected
     */
    if (hideZeroBalances) {
      tradingPairs = tradingPairs.filter((pair) => pair.balance !== 0);
    }

    /**
     * Filter via search query
     */
    if (confirmedSearchQuery) {
      tradingPairs = matchSorter(tradingPairs, confirmedSearchQuery, {
        keys: ['id'],
        threshold: matchSorter.rankings.CONTAINS,
      });
    }

    /**
     * Sort pairs based on column sort selection
     */
    const { column, asc } = columnSort;
    if (column === 'label') {
      tradingPairs.sort((a, b) => (asc ? a.label.localeCompare(b.label) : b.label.localeCompare(a.label)));
    }
    if (column === 'balance') {
      tradingPairs.sort((a, b) => (asc ? a.balance - b.balance : b.balance - a.balance));
    }
    if (column === 'volume') {
      tradingPairs.sort((a, b) => {
        const volumeA = a.ticker?.volume24h;
        const volumeB = b.ticker?.volume24h;
        if (volumeA === undefined) return asc ? -1 : 1;
        if (volumeB === undefined) return asc ? 1 : -1;
        return asc ? volumeA - volumeB : volumeB - volumeA;
      });
    }

    /**
     * Sort pairs to put favourites at beginning
     */
    const favouritePairs = tradingPairs.filter((pair) => favourites[pair.id]);
    const nonFavouritePairs = tradingPairs.filter((pair) => !favourites[pair.id]);
    tradingPairs = [...favouritePairs, ...nonFavouritePairs];

    /**
     * Add category groupings by market type
     * Sort pairs to group for category
     * Inject expandable button for each market type
     */
    if (selectedMarketType === 'all' && confirmedSearchQuery) {
      const perps = tradingPairs.filter((p) => p.market_type === 'perp');
      const spots = tradingPairs.filter((p) => p.market_type === 'spot');
      const futures = tradingPairs.filter((p) => p.market_type === 'future');

      tradingPairs = [
        {
          header: `Perpetual(${perps.length})`,
          expanded: expandedMarkets.perp,
          onClick: () => toggleExpandedMarket('perp'),
        },
        ...(expandedMarkets.perp ? perps : []),
        {
          header: `Spot(${spots.length})`,
          expanded: expandedMarkets.spot,
          onClick: () => toggleExpandedMarket('spot'),
        },
        ...(expandedMarkets.spot ? spots : []),
        {
          header: `Future(${futures.length})`,
          expanded: expandedMarkets.future,
          onClick: () => toggleExpandedMarket('future'),
        },
        ...(expandedMarkets.future ? futures : []),
      ];
    }
    return tradingPairs;
  }, [
    pairs,
    selectedMarketType,
    confirmedSearchQuery,
    selectedAccounts,
    expandedMarkets,
    favourites,
    balances,
    columnSort,
    hideZeroBalances,
    tickerData,
  ]);

  const onSelectPair = (pair) => {
    if (handlePairSelect) {
      handlePairSelect(pair);
    } else {
      setSelectedPair(pair);
    }
    setSearchQuery('');
    setConfirmedSearchQuery('');
    setAnchorEl(null);
  };

  /**
   * Hot keys to navigate pair selector
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        // Navigate tabs left
        event.preventDefault();
        const index = MARKET_TYPE_TABS.findIndex((mt) => mt.value === selectedMarketType);

        setSelectedMarketType(MARKET_TYPE_TABS[Math.max(0, index - 1)].value);
      } else if (event.key === 'ArrowRight') {
        // Navigate tabs right
        event.preventDefault();
        const index = MARKET_TYPE_TABS.findIndex((mt) => mt.value === selectedMarketType);

        setSelectedMarketType(MARKET_TYPE_TABS[Math.min(MARKET_TYPE_TABS.length - 1, index + 1)].value);
      } else if (event.key === 'ArrowUp') {
        // Navigate items up
        event.preventDefault();
        setSelectedItemIndex((prev) => Math.max(prev - 1, 0));
      } else if (event.key === 'ArrowDown') {
        // Navigate items down
        event.preventDefault();
        setSelectedItemIndex((prev) => Math.min(prev + 1, filteredPairs.length - 1));
      } else if (event.key === 'Enter') {
        // Select pair. Collapse toggle on headers.
        event.preventDefault();

        const pair = filteredPairs[selectedItemIndex];
        if (pair.header) {
          pair.onClick();
        } else {
          onSelectPair(pair);
        }
      } else if (event.code === 'Space') {
        // Favourites pair. Collapse toggle on headers.
        event.preventDefault();

        const pair = filteredPairs[selectedItemIndex];
        if (pair.header) {
          pair.onClick();
        } else {
          toggleFavorite(pair.id);
        }
      }
    };

    if (anchorEl) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [anchorEl, filteredPairs, selectedItemIndex]);

  const getItemSize = () => 30; // Row height

  /**
   * Scroll with item navigation
   */
  useEffect(() => {
    if (listRef.current) {
      const list = listRef.current;
      const itemSize = getItemSize();
      const visibleStart = list.state.scrollOffset;
      const visibleEnd = visibleStart + list.props.height;

      const itemTop = selectedItemIndex * itemSize;
      const itemBottom = itemTop + itemSize;

      if (itemTop < visibleStart + itemSize) {
        list.scrollTo(visibleStart - itemSize);
      } else if (itemBottom > visibleEnd - itemSize) {
        list.scrollTo(visibleStart + itemSize);
      }
    }
  }, [selectedItemIndex]);

  const renderPairDisplay = () => {
    if (multiOrder || ChainedOrders) {
      return (
        <Stack
          alignItems='center'
          direction='row'
          height='100%'
          justifyContent='space-between'
          marginX='8px'
          ref={pairDisplayRef}
          onClick={handleClick}
        >
          <Typography variant='body1'>{selectedPairName || 'Select Pair'}</Typography>
          <Box alignItems='center' display='flex' flexDirection='row'>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Box>
        </Stack>
      );
    }
    return (
      <Stack
        alignItems='center'
        direction='row'
        gap={1}
        height='100%'
        justifyContent='center'
        ref={pairDisplayRef}
        onClick={handleClick}
      >
        <Typography variant='subtitle1'>{selectedPairName}</Typography>
        <Box alignItems='center' display='flex' flexDirection='row'>
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Box>
        {!isMobile && (
          <Typography
            color='grey'
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid',
              borderRadius: '4px',
              padding: '1px',
            }}
            variant='small1'
          >
            {isMac ? <KeyboardCommandKeyIcon sx={{ fontSize: 'inherit' }} /> : 'Ctrl+'}K
          </Typography>
        )}
      </Stack>
    );
  };

  const selectorProps = isMobile ? {} : { sx: { width: 850, height: 650 } };
  const Content = (
    <Paper elevation={isMobile ? 0 : 1}>
      <Stack direction='column' {...selectorProps}>
        <Stack direction='row' justifyContent='space-between' spacing={1} sx={{ p: 1 }}>
          <TextField
            autoComplete='off'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search sx={{ color: 'grey' }} />
                </InputAdornment>
              ),
              sx: { padding: '4px 8px' },
            }}
            inputRef={searchRef}
            placeholder='Search pair'
            size='small'
            sx={{ width: '75%', p: 0 }}
            value={searchQuery}
            variant='outlined'
            onChange={handleSearchChange}
          />
          {isMobile ? (
            <IconButton onClick={handleClick}>
              <Close fontSize='small' />
            </IconButton>
          ) : (
            <FormControlLabel
              control={<Checkbox checked={hideZeroBalances} onClick={toggleHideZeroBalances} />}
              label={<Typography variant='small1'>Hide zero balances</Typography>}
              sx={{ width: '25%', justifyContent: 'flex-start' }}
            />
          )}
        </Stack>
        <Box sx={{ px: 2 }}>
          <Tabs
            sx={{ minHeight: '40px', height: '40px' }}
            value={selectedMarketType}
            variant='scrollable'
            onChange={(_, newValue) => {
              handleMarketTypeButtonClick(newValue);
            }}
          >
            {MARKET_TYPE_TABS.map((mt) => (
              <Tab key={mt.value} label={mt.label} sx={{ fontSize: '0.75rem' }} value={mt.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Stack direction='column' flexGrow={1}>
          <Stack direction='row' height='32px' spacing={1} sx={{ paddingRight: '10px' }}>
            <TableHeader
              asc={columnSort.column === 'label' ? columnSort.asc : null}
              text='Trading Pair'
              textStyle={{ paddingLeft: '10px' }}
              width={pairColumnWidth}
              onSort={() => {
                toggleColumnSort('label');
              }}
            />
            {!isMobile && (
              <TableHeader
                asc={columnSort.column === 'balance' ? columnSort.asc : null}
                text='Position'
                width={balanceColumnWidth}
                onSort={() => {
                  toggleColumnSort('balance');
                }}
              />
            )}
            {!isMobile && <TableHeader text='24h Change' width={dailyChangeColumnWidth} />}
            <TableHeader
              asc={columnSort.column === 'volume' ? columnSort.asc : null}
              text='24h Volume'
              width={volumeColumnWidth}
              onSort={() => {
                toggleColumnSort('volume');
              }}
            />
            <TableHeader text='Exchange' width={exchangeColumnWidth} />
          </Stack>
          <Stack direction='column' height='520px' width='100%'>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  itemCount={filteredPairs.length}
                  itemData={{
                    pairs: filteredPairs,
                    favourites,
                    toggleFavorite,
                    balanceEnabled: user && user.is_authenticated,
                    selectedItemIndex,
                    onSelectPair,
                    isMobile,
                    pairColumnWidth,
                    exchangeColumnWidth,
                    balanceColumnWidth,
                    dailyChangeColumnWidth,
                    volumeColumnWidth,
                  }}
                  itemSize={getItemSize}
                  ref={listRef}
                  style={{ scrollbarGutter: 'stable' }}
                  width={width}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
  return (
    <ThemeProvider theme={theme}>
      <Box alignItems='center' direction='row' height='100%' width='100%'>
        {renderPairDisplay()}
        {isMobile ? (
          <SwipeableDrawer anchor='top' anchorEl={anchorEl} open={open} onClose={handleClick}>
            {Content}
          </SwipeableDrawer>
        ) : (
          <Popper
            anchorEl={anchorEl}
            open={open}
            placement='bottom-start'
            ref={popperRef}
            sx={{
              zIndex: 9999,
              overflow: 'hidden',
            }}
          >
            {Content}
          </Popper>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default PairSelector;
