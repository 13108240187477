import { CopyableValue } from '@/shared/components/CopyableValue';
import LabelTooltip from '@/shared/components/LabelTooltip';
import PrettyRelativeTimestamp from '@/shared/components/PrettyRelativeTimestamp';
import { AccountsContext } from '@/shared/context/AccountsProvider';
import { matchesTraderId } from '@/shared/cryptoUtil';
import { StyledHeaderTableCellWithLine, StyledTableCell } from '@/shared/orderTable/util';
import { useTheme } from '@emotion/react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ScaleLoader from 'react-spinners/ScaleLoader';
import ProofLogoImg from './proofUtils/ProofLogoImg';
import { insertEllipsis } from './insertEllipsis';
import { useProofsPagination } from './proofUtils/useProofsPagination';
import { TraderIdAutocomplete } from './TraderIdAutocomplete';
import { useTradeBlobsPagination } from './tradeUtils/useTradesPagination';
import { PAGINATION_CONFIG } from './utils/config';
import { getEpochStartAndEnd } from './utils/epoch';

const columns = [
  {
    id: 'epoch',
    label: 'Epoch',
    tooltip: 'Click on an epoch to view detailed information',
    width: 100,
    align: 'left',
  },
  {
    id: 'traderId',
    label: 'Trader ID',
    width: 150,
    align: 'left',
  },
  {
    id: 'blobCount',
    label: 'Storage Entries',
    tooltip: 'Number of Arweave storage entries in this epoch',
    width: 120,
    align: 'left',
  },
  {
    id: 'canDecrypt',
    label: 'Can Decrypt?',
    tooltip: 'Whether you have permission to decode the blob',
    width: 120,
    align: 'left',
  },
  {
    id: 'timestamp',
    label: 'Timestamp',
    width: 200,
    align: 'left',
  },
];

/**
 * Row component that displays proof event data
 */
function ProofRow({ proof }) {
  const navigate = useNavigate();
  const { dataEvents, riskEvents, traderId, epoch } = proof;
  const { accounts } = useContext(AccountsContext);

  // Get trade blob count using the useTradeBlobsPagination hook
  const {
    totalItems: blobCount,
    loading: blobCountLoading,
    error: blobCountError,
  } = useTradeBlobsPagination({
    traderId,
    epoch: epoch?.toString(),
    pageSize: 1, // We only need the count, not the actual trades
  });

  // Get timestamp from epoch
  const [epochStart] = getEpochStartAndEnd(Number(epoch));

  // Check if user has permission to decode the blob
  const canDecrypt = useMemo(() => {
    if (!accounts || !traderId) return false;

    // Check if any of the user's accounts match the trader ID
    return accounts.some((account) => account.hashed_api_key && matchesTraderId(account.hashed_api_key, traderId));
  }, [accounts, traderId]);

  // Navigate to details page
  const handleEpochClick = (e) => {
    e.preventDefault();
    navigate(`/explorer/trader-epoch/${traderId}/${epoch}`);
  };

  const renderTradeCount = () => {
    if (blobCountLoading) {
      return <CircularProgress size={16} />;
    }

    if (blobCountError) {
      return (
        <Typography color='error' title={blobCountError.message} variant='body2'>
          Error
        </Typography>
      );
    }

    return blobCount?.toLocaleString() || '0';
  };

  const renderPermissionStatus = () => {
    return (
      <Typography color={canDecrypt ? 'success.main' : 'text.secondary'} variant='body2'>
        {canDecrypt ? 'True' : 'False'}
      </Typography>
    );
  };

  return (
    <TableRow hover>
      <StyledTableCell>
        <Link
          component='a'
          href={`/explorer/trader-epoch/${traderId}/${epoch}`}
          sx={{
            cursor: 'pointer',
            textAlign: 'left',
            p: 0,
            display: 'inline-block',
          }}
          underline='hover'
          onClick={handleEpochClick}
        >
          {epoch?.toLocaleString()}
        </Link>
      </StyledTableCell>

      <StyledTableCell>
        <CopyableValue displayValue={insertEllipsis(traderId)} value={traderId} />
      </StyledTableCell>

      <StyledTableCell>{renderTradeCount()}</StyledTableCell>

      <StyledTableCell>{renderPermissionStatus()}</StyledTableCell>

      <StyledTableCell>
        <PrettyRelativeTimestamp sx={{ minWidth: '140px' }} timestamp={epochStart}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PrettyRelativeTimestamp.ISO variant='body2' />
            <PrettyRelativeTimestamp.Relative variant='body2' />
          </Box>
        </PrettyRelativeTimestamp>
      </StyledTableCell>
    </TableRow>
  );
}

/**
 * Component that displays a table of proof events from the blockchain
 * @param {boolean} isPreviewOnly - Whether to display a preview of the proofs
 */
export default function ExplorerProofsTable({ isPreviewOnly }) {
  const pageSize = isPreviewOnly ? PAGINATION_CONFIG.PREVIEW_ROWS : PAGINATION_CONFIG.DEFAULT_ROWS;
  const [traderIdFilter, setTraderIdFilter] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const { accounts } = useContext(AccountsContext);

  const { proofs, page, loading, handlePageChange, totalItems } = useProofsPagination({ pageSize });

  // Filter proofs by trader ID if filter is set
  const filteredProofs = traderIdFilter
    ? proofs.filter((proof) => matchesTraderId(proof.traderId, traderIdFilter))
    : proofs;

  return (
    <Card
      sx={{
        height: '95%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isPreviewOnly && (
        <CardHeader
          subheader={
            <Typography align='left' sx={{ mt: 1 }} variant='subtitle2'>
              Proofs are securely stored on Monad for permanent and transparent record-keeping.
            </Typography>
          }
          title={
            <Stack alignItems='flex-start' direction='row' spacing={2} style={{ flexShrink: 0 }}>
              <ProofLogoImg height='1.8rem' />
              <Typography align='left' variant='h4'>
                Oracle
              </Typography>
            </Stack>
          }
        />
      )}
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ mb: 2, flexShrink: 0 }}>
          <TraderIdAutocomplete
            accounts={accounts}
            value={traderIdFilter}
            onChange={(event, newValue) => {
              setTraderIdFilter(newValue || '');
            }}
            onInputChange={(event, newInputValue) => {
              setTraderIdFilter(newInputValue);
            }}
          />
        </Box>

        <TableContainer sx={{ flex: 1, overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledHeaderTableCellWithLine align={column.align} key={column.id} style={{ width: column.width }}>
                    {column.tooltip ? (
                      <LabelTooltip label={column.label} labelTextVariant='subtitle2' title={column.tooltip} />
                    ) : (
                      column.label
                    )}
                  </StyledHeaderTableCellWithLine>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: 'auto' }}>
              {loading && (
                <TableRow>
                  <StyledTableCell align='center' colSpan={columns.length}>
                    <Box display='flex' justifyContent='center' width='100%'>
                      <ScaleLoader color={theme.palette.primary.main} />
                    </Box>
                  </StyledTableCell>
                </TableRow>
              )}
              {!loading && filteredProofs.length === 0 && (
                <TableRow>
                  <StyledTableCell align='center' colSpan={columns.length}>
                    No proofs found
                  </StyledTableCell>
                </TableRow>
              )}
              {!loading &&
                filteredProofs.length > 0 &&
                filteredProofs.map((proof) => <ProofRow key={`${proof.traderId}-${proof.epoch}`} proof={proof} />)}
            </TableBody>
          </Table>
        </TableContainer>

        {isPreviewOnly ? (
          <Box sx={{ flexShrink: 0, mt: 2 }}>
            <Button
              sx={{
                width: '100%',
                height: '50px',
                border: 0,
                color: theme.palette.text.offWhite,
                borderColor: theme.palette.text.offWhite,
              }}
              variant='outlined'
              onClick={() => navigate('/explorer/proofs')}
            >
              View all proofs
            </Button>
          </Box>
        ) : (
          <TablePagination
            component='div'
            count={-1}
            page={page}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[PAGINATION_CONFIG.PREVIEW_ROWS]}
            sx={{ flexShrink: 0 }}
            onPageChange={handlePageChange}
          />
        )}
      </CardContent>
    </Card>
  );
}
