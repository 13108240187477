import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import BorderedStack from '../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/BorderedStack';
import DurationField from '../../../pages/dashboard/orderEntry/AlgoOrderFieldsComponents/DurationField';
import { PovTargetField } from '../ParticipationRate';
import { AlphaTiltSlider, DiscretionSlider, PassivenessSlider } from '../Sliders';

export function TargetParticipationRateStrategyView({
  baseAssetQty,
  calculateDuration,
  duration,
  isCalculatingDuration,
  isPovLoading,
  povTarget,
  setPovTarget,
  setSelectedDuration,
  sliderProps,
  FormAtoms,
}) {
  const { passiveness, setPassiveness, discretion, setDiscretion, alphaTilt, setAlphaTilt } = sliderProps;
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <PovTargetField
          baseAssetQty={baseAssetQty}
          calculateDuration={calculateDuration}
          isPovLoading={isPovLoading}
          povTarget={povTarget}
          setPovTarget={setPovTarget}
        />
      </Grid>
      <Grid xs={12}>
        <DurationField
          disabled={!!povTarget}
          FormAtoms={FormAtoms}
          isCalculatingDuration={isCalculatingDuration}
          selectedDuration={duration}
          setSelectedDuration={setSelectedDuration}
        />
      </Grid>
    </Grid>
  );
}
