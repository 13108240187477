import {
  Box,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  Link,
  Button,
  ThemeProvider,
  Divider,
  TablePagination,
  Paper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import {
  StyledHeaderTableCell,
  StyledSmallTableCell,
  StyledTableCell,
} from '@/shared/orderTable/util';
import { useNavigate } from 'react-router-dom';
import { theme as themeFile } from '@/theme/theme';
import { fetchGraphQLEvents } from '../explorer/proofUtils/ProofGraphQL';
import SparkChart from './graphs/SparkChart';

// IDs need to be mapped to corred graphQL fields
const columns = [
  { id: 'name', name: 'Field Name', width: 200 },
  { id: 'tvl', name: 'TVL', width: 200 },
  { id: 'borrowed', name: 'Borrowed', width: 200 },
  { id: 'apy', name: 'Net APY', width: 200 },
  { id: 'curator', name: 'Curator', width: 200 },
  { id: 'deposit', name: 'Your Deposit', width: 200 },
  { id: 'actions', name: 'Actions', width: 200 },
];

const hardcoded_rows = [
  {
    name: 'AlphaVault',
    address: '0x12352342',
    tvl: '$1,000,000',
    borrowed: '$500,000',
    apy: '5%',
    curator: 'John Doe',
    deposit: '$10,000',
    actions: [10, 15, 8, 12, 18],
  },
  {
    name: 'CryptoNest',
    address: '0x345363453',
    tvl: '$2,000,000',
    borrowed: '$1,000,000',
    apy: '7%',
    curator: 'Jane Smith',
    deposit: '$20,000',
    actions: [5, 10, 6, 14, 9],
  },
  {
    name: 'TitanStore',
    address: '0x897164319',
    tvl: '$3,000,000',
    borrowed: '$1,500,000',
    apy: '6%',
    curator: 'Alice Johnson',
    deposit: '$30,000',
    actions: [20, 12, 15, 25, 18],
  },
];

function VaultTable({ searchQuery }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const page = 0;
  const totalPages = 1;

  const filteredRows = hardcoded_rows.filter(
    (row) =>
      row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.address.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Paper elevation={0} sx={{ p: 4 }}>
      <TableContainer>
        <Table columns={columns} rows={filteredRows}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledHeaderTableCell align='center' key={column.id}>
                  {column.name}
                </StyledHeaderTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, index) => (
              <TableRow key={row.name}>
                {columns.map((column) => {
                  if (column.id === 'name') {
                    return (
                      <StyledTableCell
                        align='center'
                        key={column.id}
                        sx={{
                          borderBottom: index === filteredRows.length - 1 && 0,
                        }}
                      >
                        <Button
                          color='primary'
                          component='button'
                          underline='hover'
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/vault/${row[column.id]}`);
                          }}
                        >
                          {row[column.id]}
                        </Button>
                      </StyledTableCell>
                    );
                  }
                  if (column.id === 'actions') {
                    return (
                      <StyledTableCell
                        align='center'
                        key={column.id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderBottom: index === filteredRows.length - 1 && 0,
                        }}
                      >
                        <SparkChart
                          data={row[column.id]}
                          key={`${row.id}spark_graph`}
                        />
                      </StyledTableCell>
                    );
                  }

                  return (
                    <StyledTableCell
                      align='center'
                      key={column.id}
                      sx={{
                        borderBottom: index === filteredRows.length - 1 && 0,
                      }}
                    >
                      {row[column.id]}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component='div'
        count={totalPages * rowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[]}
        sx={{
          height: '60px',
        }}
        onPageChange={() => {}}
      />
    </Paper>
  );
}

export default function VaultPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [toggleVaultType, setToggleVaultType] = useState('active');

  return (
    <ThemeProvider theme={themeFile}>
      <Box
        spacing={2}
        sx={{
          height: '90%',
          width: '90%',
          margin: '2% auto',
        }}
      >
        <Stack dirction='column' spacing={2}>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            sx={{ height: 'auto', width: '100%' }}
          >
            <Stack direction='row' spacing={2}>
              <Stack direction='column' spacing={2}>
                <Box>
                  <Typography variant='h2'>Portfolio</Typography>
                </Box>
                <Stack direction='row' spacing={8}>
                  <Stack direction='column' spacing={2}>
                    <Box>
                      <Typography variant='body2'>Deposited</Typography>
                    </Box>
                    <Box>
                      <Typography variant='h3'>$0</Typography>
                    </Box>
                  </Stack>
                  <Stack direction='column' spacing={2}>
                    <Box>
                      <Typography variant='body2'>Total Earnings</Typography>
                    </Box>
                    <Box>
                      <Typography variant='h3'>$0</Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction='column' spacing={2}>
              <Box>
                <Typography variant='h2'>Platform</Typography>
              </Box>
              <Typography variant='body2'>Total Value Locked</Typography>
              <Typography variant='h3'>$100,000,000</Typography>
            </Stack>
          </Stack>
          <Divider fullWidth sx={{ paddingTop: 4 }} />
          <Stack
            direction='row'
            justifyContent='space-between'
            spacing={2}
            sx={{ py: 6 }}
          >
            <Typography fontSize={30} fontWeight={400} variant='h1'>
              Vaults
            </Typography>
            <Stack direction='row' spacing={2}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                placeholder='Search by vault name or owner address...'
                size='small'
                sx={{ width: '400px' }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <ToggleButtonGroup
                color='primary'
                size='small'
                value={toggleVaultType}
              >
                <ToggleButton
                  value='active'
                  onClick={(e) => setToggleVaultType('active')}
                >
                  Active
                </ToggleButton>
                <ToggleButton
                  value='deposited'
                  onClick={(e) => setToggleVaultType('deposited')}
                >
                  Deposited
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>

          <VaultTable searchQuery={searchQuery} />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
