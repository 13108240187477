import React from 'react';
import { theme } from '@/theme/theme';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  ThemeProvider,
  IconButton,
  Tooltip,
} from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Stack } from '@mui/system';
import { OrderInfo, OrderInfoTypography } from '@/shared/orderDetail/OrderInfo';
import OrderAccounts from '@/shared/orderDetail/OrderAccounts';
import { useOrderForm } from '@/shared/context/OrderFormProvider';
import getBaseTokenIcon from '@images/tokens';
import { capitalize, prettyPrice, numberWithCommas } from '@/util';
import { timezoneAtom } from '@/shared/hooks/useGlobalFormReducer';
import { useAtom } from 'jotai';
import { timeZoneNoOffset } from '@/shared/TimezoneUtil';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import usePreTradeAnayltics from './hooks/usePreTradeAnalytics';

function Summary({
  isSimple,
  pairDisplayIcon,
  selectedPair,
  base,
  quote,
  selectedSide,
  accounts,
  selectedAccounts,
  targetQuantity,
  targetNotional,
  strategy,
  limitPrice,
  isReverseLimitPrice,
  selectedDuration,
  orderSlices,
  isAuto,
  urgency,
  isPov = false,
}) {
  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Stack alignItems='center' direction='row' spacing={1}>
          <img alt='Token Icon' src={pairDisplayIcon} style={{ height: '20px', width: '20px' }} />
          <Typography variant='h6'>{selectedPair}</Typography>
          <Typography variant='h6'>-</Typography>
          <Typography color={`side.${selectedSide}`} variant='h6'>
            {capitalize(selectedSide)}
          </Typography>
        </Stack>
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography
          header='Target Quantity'
          value={`${prettyPrice(targetQuantity)} ${base}`}
          valueVariant='subtitle1'
        />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography
          header='Target Notional'
          value={`${prettyPrice(targetNotional, 2)} ${quote}`}
          valueVariant='subtitle1'
        />
      </Grid>
      <Grid xs={6}>
        <OrderInfo header='Accounts'>
          <OrderAccounts accounts={accounts} selectedAccounts={selectedAccounts} />
        </OrderInfo>
      </Grid>
      {!isAuto && (
        <>
          <Grid xs={3}>
            <OrderInfoTypography header='Strategy' value={strategy.name} />
          </Grid>
          {limitPrice && (
            <Grid xs={3}>
              <OrderInfoTypography
                header={
                  <Stack alignItems='center' direction='row'>
                    Limit Price
                    {isReverseLimitPrice && (
                      <Tooltip title='Reverse Limit Price'>
                        <SwapVertIcon fontSize='small' />
                      </Tooltip>
                    )}
                  </Stack>
                }
                value={limitPrice}
              />
            </Grid>
          )}
          <Grid xs={3}>
            <OrderInfoTypography
              header={isSimple ? 'Expiry' : 'Duration'}
              value={
                isPov
                  ? `~${prettyPrice(selectedDuration / 60, 2)} min(s)`
                  : `${prettyPrice(selectedDuration / 60, 2)} min(s)`
              }
            />
          </Grid>
          {strategy.name === 'Iceberg' && (
            <Grid xs={3}>
              <OrderInfoTypography header='Slices' value={orderSlices} />
            </Grid>
          )}
        </>
      )}
      {isAuto && (
        <Grid xs={3}>
          <OrderInfoTypography header='Auto Strategy' value={urgency} valueColor={`orderUrgency.${urgency}`} />
        </Grid>
      )}
    </Grid>
  );
}

function SimpleStrategyConfiguration({ isLimit, isReduceOnly, stopPrice, quote, updatePairLeverage }) {
  return (
    <>
      <Grid xs={3}>
        <OrderInfoTypography header='Active Limit' value={isLimit ? 'Yes' : 'No'} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Reduce Only' value={isReduceOnly ? 'Yes' : 'No'} />
      </Grid>
      {stopPrice && (
        <Grid xs={3}>
          <OrderInfoTypography header='Stop Price' value={`${prettyPrice(stopPrice, 2)} ${quote}`} />
        </Grid>
      )}
      {updatePairLeverage && (
        <Grid xs={3}>
          <OrderInfoTypography header='Pair Leverage' value={updatePairLeverage} />
        </Grid>
      )}
    </>
  );
}

function AlgoStrategyConfiguration({
  trajectory,
  passiveness,
  discretion,
  maxClipSize,
  alphaTilt,
  maxOtcPercentage,
  isPassiveOnly,
  isLimit,
  isReduceOnly,
  isStrictDuration,
  updatePairLeverage,
  povLimit,
  povTarget,
}) {
  return (
    <>
      <Grid xs={3}>
        <OrderInfoTypography header='Trajectory' value={trajectory} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Passiveness' value={passiveness} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Discretion' value={discretion} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Alpha Tilt' value={alphaTilt} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Max Clip Size' value={maxClipSize ? numberWithCommas(maxClipSize) : 'Adaptive'} />
      </Grid>

      {maxOtcPercentage > 0 && (
        <Grid xs={3}>
          <OrderInfoTypography header='Max OTC Percentage' value={maxOtcPercentage} />
        </Grid>
      )}
      <Grid xs={3}>
        <OrderInfoTypography header='Passive Only' value={isPassiveOnly ? 'Yes' : 'No'} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Active Limit' value={isLimit ? 'Yes' : 'No'} />
      </Grid>
      <Grid xs={3}>
        <OrderInfoTypography header='Reduce Only' value={isReduceOnly ? 'Yes' : 'No'} />
      </Grid>
      {isPassiveOnly && (
        <Grid xs={3}>
          <OrderInfoTypography header='Strict Duration' value={isStrictDuration ? 'Yes' : 'No'} />
        </Grid>
      )}
      {updatePairLeverage && (
        <Grid xs={3}>
          <OrderInfoTypography header='Pair Leverage' value={updatePairLeverage} />
        </Grid>
      )}
      {povTarget && (
        <Grid xs={3}>
          <OrderInfoTypography header='Participation Rate Target' value={`${povTarget * 100}%`} />
        </Grid>
      )}
      {povLimit && (
        <Grid xs={3}>
          <OrderInfoTypography header='Participation Rate Limit' value={`${povLimit * 100}%`} />
        </Grid>
      )}
    </>
  );
}

function AutoStrategyConfiguration({ strategy, duration, passiveness, alphaTilt }) {
  const isSimple = ['Market', 'IOC'].includes(strategy);
  return (
    <>
      <Grid xs={3}>
        <OrderInfoTypography header='Strategy' value={strategy} />
      </Grid>
      {!isSimple && (
        <>
          <Grid xs={3}>
            <OrderInfoTypography header='Duration' value={`${prettyPrice(duration / 60, 2)} min(s)`} />
          </Grid>
          <Grid xs={3}>
            <OrderInfoTypography header='Passiveness' value={passiveness} />
          </Grid>
          <Grid xs={3}>
            <OrderInfoTypography header='Alpha Tilt' value={alphaTilt} />
          </Grid>
        </>
      )}
    </>
  );
}

function Scheduled({ startDatetime, duration }) {
  const [timeZone] = useAtom(timezoneAtom);
  const startDt = DateTime.fromISO(startDatetime, { zone: 'utc' }).setZone(timeZoneNoOffset(timeZone));
  const endDt = startDt.plus({ seconds: duration });

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography color='secondary' variant='small1'>
          Scheduled
        </Typography>
      </Grid>
      <Grid xs={6}>
        <OrderInfoTypography header='Time start' value={startDt.toFormat('yyyy-MM-dd HH:mm')} />
      </Grid>
      <Grid xs={6}>
        <OrderInfoTypography header='Time end' value={endDt.toFormat('yyyy-MM-dd HH:mm')} />
      </Grid>
      <Grid xs={6}>
        <OrderInfoTypography header='Timezone' value={timeZone} />
      </Grid>
    </Grid>
  );
}

function PreTradeAnalytics({ preTradeEstimationData }) {
  const { displayPov, displayPovColor, displayVolatility, warning } = usePreTradeAnayltics(preTradeEstimationData);
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Typography variant='small1'>Pre-Trade Analytics</Typography>
      </Grid>
      <Grid xs={6}>
        <OrderInfoTypography header='Participation Rate' value={displayPov} valueColor={displayPovColor} />
      </Grid>
      <Grid xs={6}>
        <OrderInfoTypography header='Expected Volatility' value={displayVolatility} />
      </Grid>
      {warning && (
        <Grid xs={12}>
          <OrderInfoTypography value={warning} valueColor='primary' />
        </Grid>
      )}
    </Grid>
  );
}

function OrderConfirmation({ order, onClose, onConfirm }) {
  const {
    accounts: selectedAccounts,
    alpha_tilt: alphaTilt,
    side: selectedSide,
    duration: selectedDuration,
    engine_passiveness: passiveness,
    schedule_discretion: discretion,
    strategy_params: selectedStrategyParams,
    super_strategy: selectedStrategy,
    strategy: trajectory,
    updated_leverage: updatePairLeverage,
    stop_price: stopPrice,
    max_otc: maxOtcPercentage,
    limit_price: limitPrice,
    is_reverse_limit_price: isReverseLimitPrice,
    quote_asset_qty: quoteQty,
    base_asset_qty: baseQty,
    order_slices: orderSlices,
    pre_trade_data: preTradeEstimationData,
    pair: selectedPair,
    auto_order_metadata: autoOrderMetadata,
    start_datetime: startDatetime,
    pov_limit: povLimit,
    pov_target: povTarget,
  } = order;

  const { initialLoadValue, convertedQty } = useOrderForm();

  const { accounts, trajectories, strategies } = initialLoadValue;
  // Pair
  const [base, quote] = selectedPair.split('-');
  const [token, _] = base.split(':');
  const pairDisplayIcon = getBaseTokenIcon(token);

  // Strategy/Trajectory
  let strategy = strategies[selectedStrategy];
  const isSimple = !strategy?.is_super_strategy;
  let traj = trajectories[trajectory];

  // If simple order, strategy is listed as the trajectory
  if (isSimple) {
    strategy = traj;
    traj = null;
  }

  const isPov = !!povLimit || !!povTarget;

  const isAuto = Object.keys(autoOrderMetadata).length > 0;

  let StrategyConfiguration;
  if (isAuto) {
    StrategyConfiguration = (
      <AutoStrategyConfiguration
        alphaTilt={alphaTilt}
        duration={selectedDuration}
        passiveness={passiveness}
        strategy={strategy.name}
      />
    );
  } else if (isSimple) {
    StrategyConfiguration = (
      <SimpleStrategyConfiguration
        isLimit={!!limitPrice}
        isReduceOnly={selectedStrategyParams?.reduce_only}
        pairLeverage={updatePairLeverage}
        quote={quote}
        stopPrice={stopPrice}
      />
    );
  } else {
    StrategyConfiguration = (
      <AlgoStrategyConfiguration
        alphaTilt={alphaTilt}
        discretion={discretion}
        isLimit={!!limitPrice}
        isPassiveOnly={selectedStrategyParams?.passive_only}
        isReduceOnly={selectedStrategyParams?.reduce_only}
        isStrictDuration={selectedStrategyParams?.strict_duration}
        maxClipSize={selectedStrategyParams?.max_clip_size}
        maxOtcPercentage={maxOtcPercentage}
        passiveness={passiveness}
        povLimit={povLimit}
        povTarget={povTarget}
        trajectory={traj.name}
        updatePairLeverage={updatePairLeverage}
      />
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Card>
        <CardHeader
          title={
            <Stack alignItems='center' direction='row' justifyContent='space-between'>
              <Typography variant='h6'>Order Confirmation</Typography>
              <IconButton aria-label='Close' onClick={onClose}>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Stack>
          }
        />
        <Divider />
        <CardContent>
          <Stack direction='column' spacing={2}>
            <Summary
              accounts={accounts}
              base={base}
              isAuto={isAuto}
              isPov={isPov}
              isReverseLimitPrice={isReverseLimitPrice}
              isSimple={isSimple}
              limitPrice={limitPrice}
              orderSlices={orderSlices}
              pairDisplayIcon={pairDisplayIcon}
              quote={quote}
              selectedAccounts={selectedAccounts}
              selectedDuration={selectedDuration}
              selectedPair={selectedPair}
              selectedSide={selectedSide}
              strategy={strategy}
              targetNotional={quoteQty || convertedQty}
              targetQuantity={baseQty || convertedQty}
              urgency={autoOrderMetadata?.urgency}
            />
            <Divider />
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Typography color='secondary' variant='small1'>
                  Strategy Configuration
                </Typography>
              </Grid>
              {StrategyConfiguration}
            </Grid>

            {startDatetime && !isSimple && !isAuto && (
              <>
                <Divider />
                <Scheduled duration={selectedDuration} startDatetime={startDatetime} />
              </>
            )}
            <Divider />
            <PreTradeAnalytics preTradeEstimationData={preTradeEstimationData} />
            <Button color={selectedSide === 'buy' ? 'success' : 'error'} variant='contained' onClick={onConfirm}>
              <Typography color='text.offBlack' style={{ whiteSpace: 'nowrap' }}>
                Place {capitalize(selectedSide)} Order
              </Typography>
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}

export default OrderConfirmation;
